import { Grid, Box, Typography, FormControl, RadioGroup, FormControlLabel, Button, Radio, Container } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import { toTitleCase } from '../utils';
import theme from '../utils/ThemeConfig';

const SetUserAddress = ({ userLoggedIn }) => {
  const [addressIdSelected, setAddressIdSelected] = useState(0);
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAddressIdSelected(event.target.value);
  };

  const handlerConfirmAddress = (addressIdSelected) => {
    console.log('aqui handlerConfirmAddress: ', addressIdSelected);
    userLoggedIn.addressId = addressIdSelected;
    userLoggedIn.officeId = userLoggedIn.deliveryAddresses.filter((item) => item.code === userLoggedIn.addressId)[0].officeId;
    userLoggedIn.storageId = userLoggedIn.deliveryAddresses.filter((item) => item.code === userLoggedIn.addressId)[0].storageId;
    userLoggedIn.priceListCode = userLoggedIn.deliveryAddresses.filter((item) => item.code === userLoggedIn.addressId)[0].priceListCode;
    userLoggedIn.routes = userLoggedIn.deliveryAddresses.filter((item) => item.code === userLoggedIn.addressId)[0].routes;
    userLoggedIn.schedule = userLoggedIn.deliveryAddresses.filter((item) => item.code === userLoggedIn.addressId)[0].schedule;
    logIn(userLoggedIn);
    navigate('/tienda');
  };

  return (
    <div>
      <Container maxWidth="md">
        <Grid container display="flex" justifyContent="center" alignContent="center">
          <Box my={2}>
            <Grid item xs={12}>
              <Typography component="div" color="primary">
                <Box py={2} pt={4} display="flex" justifyContent="center" width="100%" textAlign="center" fontSize="24px" fontWeight="500">
                  Selecciona la direccion de envío:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={1} display="flex" justifyContent="center">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender1" value={addressIdSelected} onChange={handleChange}>
                    {userLoggedIn.deliveryAddresses.map((item) => (
                      <Box my={1} key={item.code} color="primary">
                        <FormControlLabel
                          key={item.code}
                          value={item.code}
                          control={<Radio />}
                          label={toTitleCase(item.address)}
                          sx={{
                            '& span': {
                              color: '#2D85C5',
                              fontSize: '20px',
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{
                    margin: theme.spacing(1, 1, 0, 0),
                  }}
                  onClick={() => handlerConfirmAddress(addressIdSelected)}
                >
                  Confirmar
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default SetUserAddress;
