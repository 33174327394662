import { useNavigate, Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VedoraPlusIco from '../assets/vplus-ico.png';
import { useUserAuth } from '../context/UserAuthContext';

import MainLayout from '../layout/MainLayout';

const UserHomePage = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER || '+51908824814'; // Valor por defecto
  const vedoraEnvironment = process.env.REACT_APP_VEDORA_ENVIRONMENT || ''; // Valor por defecto

  //console.log('Check user in Private USERHOME: ', user);
  if (Object.keys(user).length !== 0) 
    {
      if ( !user.UpdateDate || (Date.now() - new Date(user.UpdateDate).getTime()) > 2 * 60 * 60 * 1000) // verifica si han pasado más de 2 horas
      {
        console.log('Check user lenth USERHOME>0 y fecha: ', user);
        return <Navigate replace to="/tienda" />;
      }
    }

  return (
    <MainLayout access="auth">
      <Box
        style={{ marginTop: '-60px' }}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <Paper elevation={3} sx={{ p: '50px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', height:'50px' }}>
            <img alt="logo" src={VedoraPlusIco} />
          </Box>
          <Typography variant="h4" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)' }}>
            ¡Hola! Soy VedoraPlus. 
          </Typography>
          <Typography variant="h6" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)' }}>
            ¿Cómo puedo ayudarte?
          </Typography>

          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={12} align="center" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '300px' }}>
              <Typography variant="p" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)', p: '10px' }}>
                Para realizar tus compras en el catálogo debes indentificarte primero en el WhatsApp
              </Typography>
              <Typography variant="p" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)', p: '10px' }}>
                ¿Quieres acceder a WhatsApp para comprar o registrarte?
              </Typography>
              <Button variant="contained" sx={{ background: 'var(--primary-color)', '&:hover': {backgroundColor: '#ffaa73', }  }}  endIcon={<WhatsAppIcon />} onClick={() => window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`Hola VedoraPlus ${vedoraEnvironment}`)}`, '_blank')}>
                Ir al WhatsApp
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </MainLayout>
  );
};

export default UserHomePage;
