import axios from 'axios';
import { useState, useEffect } from 'react';
import { Typography, Box, Grid, Container, Paper, CircularProgress, Divider, Dialog, DialogTitle, DialogContent, Tabs, Tab, Input, Button, DialogActions, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { MdEdit } from 'react-icons/md';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import { v4 as uuidv4 } from 'uuid';
import { api } from '../services/axiosConfig';
import { uploadNewImage } from '../services/storageF';
import toast from 'react-hot-toast';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// {
//   "productCode": "000000",
//   "imgUrl": "imgUrl",
//   "isBestSeller": true,
//   "isInteresting": false,
//   "margin":70,
//   "promotionRule":"2",
//   "sponsored": false,
//   "sponsoredVip":false,
//   "video": "videoURL"
// }

export const AdminProductsContainer = () => {
  // const user = useUserAuth();
  const [datos, setDatos] = useState([]);
  const [productoEditar, setProductoEditar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const columns = [
    {
      field: 'code',
      headerName: 'Código',
      flex: 1,
      // width: 200,
      description: 'Código del Producto',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Nombre largo',
      flex: 1,
      description: 'Nombre del Producto',
      sortable: false,
    },
    {
      field: 'categoria',
      headerName: 'Categoría',
      flex: 1,
      // width: 200,
      description: 'Categoría del Producto',
      sortable: false,
    },
    {
      field: 'subcategoria',
      headerName: 'Subcategoría',
      flex: 1,
      description: 'Subcategoría del Producto',
      sortable: false,
    },
    {
      field: 'proveedor',
      headerName: 'Proveedor',
      flex: 1,
      // width: 200,
      description: 'Proveedor del Producto',
      sortable: false,
    },
    {
      field: 'marca',
      headerName: 'Marca',
      flex: 1,
      description: 'Marca del Producto',
      sortable: false,
    },
    {
      field: 'stockAmount',
      headerName: 'Stock',
      flex: 1,
      description: 'Stock del Producto',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Acciones',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="w-full flex text-2xl text-gray-500" style={{ cursor: 'pointer' }}>
            <MdEdit
              className="ml-4"
              onClick={() => {
                setProductoEditar(datos.find((producto) => producto.id === params.row.id));
                setShowModal(true);
              }}
            />
            {/* <Button variant="text" onClick={(e) => console.log(e)} sx={{ textTransform: 'none', fontWeight: '400' }} endIcon={<PowerOffIcon />}>
              Desconectar
        </Button> */}
          </div>
        );
      },
    },
  ];

  const getData = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_GET_PRODUCT_URL,
        {
          officeId: '37',
          storageId: '1',
          productId: 'adminProducts',
          priceListCode: '60',
        },
        { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } },
      );
      let datos = data.products.map((dato) => ({ ...dato, id: uuidv4() }));
      setDatos(datos);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => {
    setProductoEditar({});
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleUpdateProduct = async () => {
    try {
      if (selectedImage) {
        const url = await uploadNewImage(selectedImage);
        const { data } = await api.post(process.env.REACT_APP_UPDATE_PRODUCT_INFO_URL, {
          productCode: productoEditar.code,
          imgUrl: url,
          isBestSeller: productoEditar.isBestSeller,
          isInteresting: productoEditar.isInteresting,
          margin: productoEditar.margin,
          promotionRule: productoEditar.promotionRule,
          sponsored: productoEditar.sponsored,
          sponsoredVip: productoEditar.sponsoredVip,
        });
        data.success && toast.success('Producto actualizado con éxito');
        setDatos([]);
        handleClose();
        getData();
      } else {
        const { data } = await api.post(process.env.REACT_APP_UPDATE_PRODUCT_INFO_URL, {
          productCode: productoEditar.code,
          imgUrl: productoEditar.imgUrl,
          isBestSeller: productoEditar.isBestSeller,
          isInteresting: productoEditar.isInteresting,
          margin: productoEditar.margin,
          promotionRule: productoEditar.promotionRule,
          sponsored: productoEditar.sponsored,
          sponsoredVip: productoEditar.sponsoredVip,
        });
        data.success && toast.success('Producto actualizado con éxito');
        setDatos([]);
        handleClose();
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography component="div" flexGrow="1">
                <Box fontSize="18px" fontWeight="500">
                  Productos
                </Box>
              </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {datos.length === 0 ? (
            <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid rows={datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
          )}
        </Paper>
      </Grid>
      {/* Dialog that allows us to edit products */}
      <Dialog open={showModal} onClose={handleClose} maxWidth="xl">
        {/* Product title */}
        <DialogTitle>
          {productoEditar.code} - {productoEditar.name}
        </DialogTitle>
        {/* Dialog Content */}
        <DialogContent>
          <Tabs value={selectedTab} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example" centered variant="scrollable" scrollButtons={true} allowScrollButtonsMobile>
            <Tab value={1} label="Imagen" />
            <Tab value={2} label="Más Vendido" />
            <Tab value={3} label="Más Interesante" />
            <Tab value={4} label="Patrocinado" />
            <Tab value={5} label="Patrocinado VIP" />
            <Tab value={6} label="Margen" />
          </Tabs>
          <TabPanel value={selectedTab} index={1}>
            <Box display="flex" justifyContent="center" border={1} borderColor="#aaa" borderRadius="10px" p={1}>
              <img src={selectedImage ? URL.createObjectURL(selectedImage) : productoEditar.imgUrl} alt="no disponible" />
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} onChange={(e) => setSelectedImage(e.target.files[0])} />
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '16px',
                  }}
                  endIcon={<UploadIcon />}
                >
                  Actualizar imagen
                </Button>
              </label>
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography component="div">
                <Box mr={2} color="rgba(0, 0, 0, 0.6)">
                  Producto más vendido
                </Box>
              </Typography>
              <Switch
                checked={productoEditar.isBestSeller}
                onChange={() => {
                  setProductoEditar((st) => ({ ...st, isBestSeller: !st.isBestSeller }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography component="div">
                <Box mr={2} color="rgba(0, 0, 0, 0.6)">
                  Producto interesante
                </Box>
              </Typography>
              <Switch
                checked={productoEditar.isInteresting}
                onChange={() => {
                  setProductoEditar((st) => ({ ...st, isInteresting: !st.isInteresting }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography component="div">
                <Box mr={2} color="rgba(0, 0, 0, 0.6)">
                  Producto patrocinado
                </Box>
              </Typography>
              <Switch
                checked={productoEditar.sponsored}
                onChange={() => {
                  setProductoEditar((st) => ({ ...st, sponsored: !st.sponsored }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography component="div">
                <Box mr={2} color="rgba(0, 0, 0, 0.6)">
                  Producto patrocinado VIP
                </Box>
              </Typography>
              <Switch
                checked={productoEditar.sponsoredVip}
                onChange={() => {
                  setProductoEditar((st) => ({ ...st, sponsoredVip: !st.sponsoredVip }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={6}>
            <div className="mx-auto w-[60%]">
              <label className="block text-gray-900 w-[60%] text-[14px] mb-1" htmlFor="cantidad">
                Comprando:
              </label>
              <input
                type="text"
                className="block border outline-none rounded w-full py-2 px-4"
                id="cantidad"
                value={productoEditar.promotionRule}
                onChange={({ target }) => {
                  setProductoEditar((st) => ({ ...st, promotionRule: target.value }));
                }}
              />
              <label className="block text-gray-900 w-[60%] text-[14px] my-1" htmlFor="margen">
                Margen:
              </label>
              <input
                type="number"
                min={0}
                max={100}
                className="block border outline-none rounded w-full py-2 px-4"
                id="margen"
                value={productoEditar.margin}
                onChange={({ target }) => {
                  setProductoEditar((st) => ({ ...st, margin: target.value }));
                }}
              />
            </div>
          </TabPanel>
        </DialogContent>
        {/* Dialog actions (Save, Cancel) */}
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} sx={{ textTransform: 'none' }}>
            Cancelar
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button variant="contained" onClick={handleUpdateProduct} sx={{ textTransform: 'none' }} endIcon={<SaveIcon />}>
              Guardar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
