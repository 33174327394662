import React from 'react';
import { CartList } from '../components/CartList';
import { SummaryInfo } from '../components/SummaryInfo';
import cartLoading from '../animations/Carrito_Cargando.json';
import Lottie from 'lottie-react';
import '../styles/OrderSummary.css';
import { useCart } from '../context/CartContext';
export const OrderSummary = () => {

  const { loadingOrder } = useCart();

  return (
    <div className={`l-container ${loadingOrder ? 'order-container' : ''}`}>
      {
        loadingOrder ? 
        (
          <div className="order-loading">
            <Lottie animationData={cartLoading} loop={true} />
          </div>
        )
        :
        (
          <div className="summary-container">
            <SummaryInfo />

            <div className="items-container">
              <h4 className='items-title'>Recibirás los siguientes productos:</h4>
              <CartList editable={false} />
            </div>
          </div>
        )
      }
    </div>
  );
};
