import React from 'react'
import MainLayout from '../layout/MainLayout'
import '../styles/UserExperience.css'
import Teresa from '../assets/Ayuda_teresa.png'
import FormExperience from '../components/FormExperience'

export default function UserExperience() {
  return (
    <MainLayout access='user'>
      <div className='l-container'>
        <div className='experience-container'>
          <div className='experience-header'>
            <p className='experience-title'>¡Ayúdanos a ser mejores para ti!</p>
            <img className='experience-image' src={Teresa} alt="Teresa" />
          </div>
          <FormExperience/>
        </div>

      </div>
    </MainLayout>
  )
}
