import React from 'react';
import { Fragment } from 'react';
import { useCart } from '../context/CartContext';
import '../styles/CartList.css';
import { CartItem } from './CartItem';

export const CartList = ({ editable = false }) => {
  const { chart } = useCart();

  return (
    <Fragment>
      {chart.products.length === 0 ? (
        <h4 className="title-noitems">No hay Productos</h4>
      ) : (
        <div className='cart-items'>
          {chart.products.map((item) => (
            <CartItem editable={editable} key={item.product.code} item={item} />
          ))}
        </div>
      )}
    </Fragment>
  );
};
