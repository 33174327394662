import { useState, useEffect } from 'react';

import { FaClock } from 'react-icons/fa';
import '../styles/CountDown.css';

const moment = require('moment-timezone');

const calculateTimeLeft = (dateEnd) => {
  dateEnd = moment(dateEnd).tz('America/Lima').locale('es');
  let now = moment().tz('America/Lima').locale('es');
  let timeLeft = {};

  if (dateEnd.diff(now) > 0) {
    timeLeft = {
      days: dateEnd.diff(now, 'days'),
      hours: Math.floor(dateEnd.diff(now, 'days') % 24),
      minutes: Math.floor(dateEnd.diff(now, 'minutes') % 60),
      seconds: Math.floor(dateEnd.diff(now, 'seconds') % 60),
    };
  }
  return timeLeft;
};

const CountDown = (props) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.dateEnd));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(props.dateEnd));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="">
      {timeLeft.days === undefined ? (
        <div></div>
        // <div className="time__spinner">
        //   <div className="spinneraApi"></div>
        // </div>
      ) : (
        <>
          {/* <div className="time__title">
            <FaClock />
            <p>Finaliza en:</p>
          </div> */}
          {/* <div className="time">
            <div className="time__item">
              <p className="time__item--hour">{timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}</p>
              <p className="time__item--text">DIA</p>
            </div>
            <div className="time__item">
              <p className="time__item--hour">{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}</p>
              <p className="time__item--text">HRS</p>
            </div>
            <div className="time__item">
              <p className="time__item--hour"> {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}</p>
              <p className="time__item--text">MIN</p>
            </div>
            <div className="time__item">
              <p className="time__item--hour"> {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}</p>
              <p className="time__item--text">SEG</p>
            </div>
          </div> */}

          
          <div className={`timer timer-instance`}>
            <div className={`div timer-2`}>
              <div className="div-2">
                <div className={`value timer-3`}><p>{timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}</p></div>
                <div className={`label timer-4`}>DIA</div>
              </div>
              <div className={`text-wrapper timer-3`}>:</div>
              <div className="div-2">
                <div className={`value timer-3`}><p>{timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}</p></div>
                <div className={`label timer-4`}>HRS</div>
              </div>
              <div className={`text-wrapper timer-3`}>:</div>
              <div className="div-2">
                <div className={`value timer-3`}><p> {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}</p></div>
                <div className={`label timer-4`}>MIN</div>
              </div>
              <div className={`text-wrapper timer-3`}>:</div>
              <div className="div-2">
                <div className={`value timer-3`}><p> {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}</p></div>
                <div className={`label timer-4`}>SEG</div>
              </div>
            </div>
          </div>



        </>
      )}
    </div>
  );
};

export default CountDown;
