/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Home = ({ className }) => {
  return (
    <svg
      className={`home ${className}`}
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.4206 24.5794H15.5897V18.9517H20.6163V24.5794H23.7854V16.0558L18.103 11.7758L12.4206 16.0558V24.5794ZM11 26V15.3455L18.103 10L25.206 15.3455V26H19.1957V20.3723H17.0103V26H11Z"
        fill="#999999"
      />
    </svg>
  );
};
