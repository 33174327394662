import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import VedoraPlusFooter from '../assets/VedoraPlusIco2.png';
import InstagramFooter from '../assets/Instagram_footer.png';
import FacebookFooter from '../assets/Facebook_footer.png';
import LinkhubLogo from '../assets/Linkhub_Logo.png';
import { Link } from 'react-router-dom';
// import theme from '../../themes/themeConfig';

const Footer = () => {
  return (
    <>
      <CssBaseline />
      <Box
        // style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}
        component="footer"
        sx={{
          mt: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',


          backgroundColor: 'var(--primary-color)',
          color: 'white',
        }}
      >
        <Box display="flex" sx={{ flexDirection:{xs:'column', md:'row'}, paddingY: { xs:'30px', md:'84px'}}} justifyContent={'space-around'} >
          <Box display="flex" flexDirection="column" sx={{ flexBasis: { xs: 'auto', md:'291px'}, gap: {xs:'20px', md:'40px'} }}>
            <Box display="flex" flexDirection="column" gap={{ xs:2, md:1}} sx={{ alignItems: { xs:'center', md:'start'} }}>
              <Box component={'img'} src={VedoraPlusFooter} sx={{ width:{xs:'302.91px', md:'auto'} }}/>
              <Typography sx={{ fontSize: { xs:'13px', md:'18px'}, fontWeight: 700, letterSpacing: 0 }} color="white">
                Comprometidos con tu desarrollo
              </Typography>
            </Box>
            <Typography sx={{  textAlign: {xs:'center', md:'left'}, margin:{xs:'0 auto', md:0}, width:{ xs:'270px', md:'auto'}, fontSize: {xs:'13px', md:'18px'}, color: 'white', letterSpacing: 0 }}>Somos una empresa Somos una empresa especializada en servicios de marketing y comercio digital B2B</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems={{ xs:'center', md:'start'}} gap={'26px'} sx={{ fontWeight:100, fontSize: {xs:'15px', md:'20px'}, marginTop: {xs: '50px', md:0}, color:{ xs:'white', md:'white'} }}>
            <li>Políticas de privacidad</li>
            <li>Libro de reclamaciones</li>
            <Link to="/faq">
              <li>Preguntas frecuentes</li>
            </Link>
            <Link to="/experiencia">
              <li>Ayúdanos a mejorar</li>
            </Link>
            <li>Tutoriales</li>
          </Box>
          <Box display="flex" flexDirection="column" gap={{ xs:'40px', md:'55px'}} alignItems="center" justifyContent="end" sx={{ marginTop:{xs:'62px', md:0}}}>
            <Box display="flex" justifyContent="center" gap={{ xs:'28px', md:'75px'}}>
              <a href="https://www.facebook.com" target="_blank" >
                <Box component="img" src={FacebookFooter} sx={{ width:{xs:'9px', md:'auto'}, height:{xs:'20px', md:'auto'}}} />

              </a>
              <a href="https://www.instagram.com" target="_blank">
                <Box component="img" src={InstagramFooter} sx={{ width:{xs:'21px', md:'auto'}, height:{xs:'21px', md:'auto'}}} />
              </a>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box component="p" sx={{ fontSize:{xs:'13px', md:'18px'}}}>
                Proyecto creado por VedoraPlus
              </Box>
              {/* <a href='https://linkhub.ai/' target="_blank">
                <Box component={'img'} src={LinkhubLogo} sx={{ width:{xs:'64px', md:'74px'}}}/>
              </a> */}
            </Box>
          </Box>
        </Box>

        <Typography sx={{ marginBottom:'30px', fontSize:{xs:'11px',md:'20px'}}}>© VedoraPlus 2024 Todos los derechos son reservados</Typography>

        {/* <Typography component="div" color="primary">
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              style={{
                width: '30px',
              }}
              src={'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/icons/whatsapp.png'}
              alt=""
            />
            <Box display="flex" justifyContent="center" color="white" fontWeight="600" fontSize="14px" ml={1}>
              +51 977 774 804
            </Box>
            <Box display="flex" justifyContent="center" color="white" ml={1} p={1}>
              <Box display="flex" justifyContent="center" px={1}>
                <FacebookIcon fontSize="large" />
              </Box>
              <Box display="flex" justifyContent="center" px={1}>
                <InstagramIcon fontSize="large" />
              </Box>
            </Box>
          </Box>
        </Typography>

        <Typography component="div" color="primary">
          <Box display="flex" justifyContent="center" color="white" bgcolor="#192638" fontWeight="400" fontSize="11px" p={1}>
            © Powered by
            <Box display="flex" justifyContent="center" color="#FFF" fontWeight="600" fontSize="11px" ml="4px">
              linkhub.ai
            </Box>
          </Box>
        </Typography> */}
      </Box>
    </>
  );
};

export default Footer;
