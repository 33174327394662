import axios from 'axios';
import { api } from './axiosConfig';

export const VALIDAR_TOKEN_SERVICE = async (token) => {
  return await api.post(process.env.REACT_APP_CHECK_SIGN_UP_TOKEN_URL, JSON.stringify(token));
};

export const VALIDAR_DOCUMENTO_ECONOMYSA_SERVICE = async (parameter) => {
  return await api.post(process.env.REACT_APP_GET_CLIENT_BY_DOC_URL, JSON.stringify(parameter));
};

export const VALIDAR_DNI_SUNAT_SERVICE = async (parameter) => {
  return await api.post(process.env.REACT_APP_CHECK_DNI_URL, JSON.stringify(parameter));
};

export const VALIDAR_RUC_SUNAT_SERVICE = async (parameter) => {
  return await api.post(process.env.REACT_APP_CHECK_RUC_URL, JSON.stringify(parameter));
};

export const VALIDAR_DIRECCION_GOOGLE_MAP_SERVICE = async (parameter) => {
  return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${parameter}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
};

export const CREATE_USER_ECONOMYSA_SERVICE = async (parameter) => {
  return await api.post(process.env.REACT_APP_CREATE_CLIENT_URL, JSON.stringify(parameter));
};

export const GET_USER_CODE_ECONOMYSA_SERVICE = async (parameter) => {
  console.log(process.env.REACT_APP_GET_CLIENT_URL, JSON.stringify(parameter));
  return await api.get(process.env.REACT_APP_GET_CLIENT_URL + '/' + parameter.clientCode);
};

export const GET_CLIENT_SERVICE = async (user) => {
  const parameter = {
    clientCode: user.clientCode,
    clientMobilePhone: user.mobilePhone
  }
  return await api.get(process.env.REACT_APP_GET_CLIENT_URL + '/' + parameter.clientCode)
}