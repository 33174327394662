import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as Sentry from "@sentry/react";
// import LogRocket from 'logrocket';
// import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';

// Sentry.init({
//   dsn: "https://0f41dc24a08a4fe686c6c5df92a65a27@o1384743.ingest.sentry.io/6703488",
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   release: process.env.REACT_APP_RELEASE,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
// LogRocket.init('eqr6pu/economysa', {
//   release: process.env.REACT_APP_RELEASE
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Sentry.ErrorBoundary> */}
      <Toaster position="top-center" />
      <App />
    {/* </Sentry.ErrorBoundary> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
