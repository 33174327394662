import { Snackbar, Alert, Typography, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { toTitleCase } from '../utils';
import '../styles/ProductPromo.css';
import { FaMinus, FaPlus, FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../context/CartContext';
import { getPriceWithDiscount, getGanancia } from '../utils/getPriceWithDiscount';
import { useProducts } from '../context/ProductsContext';

const ProductPromo = (props) => {
  // const chartProducts = useSelector((store) => store.chart.products);
  const { chart, addProductToChartAction, deleteProductFromChartAction, substractProductToChartAction, updateProductToChartAction } = useCart();
  const [productAmount, setProductAmount] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [noStock, setNoStock] = useState(false);
  const [openStockAlert, setOpenStockAlert] = useState(false);
  const { products, promotions, getProducts, getPromotions, setPromotions } = useProducts()

  const selectProduct = (product, newProductAmount) => {
    // // console.log('selectProduct INIT')
    // // console.log('productAmount: ', productAmount)
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    if (chartProduct.length > 0) {
      setProductAmount(chartProduct[0].amount);
    } else {
      setProductAmount(1);
      const discountPrice = getPriceWithDiscount(product, 1, props.promo.priceDiscount)
      updateProductToChartAction(props.product, 1, discountPrice);

      if(props.promo?.gifts?.length > 0)
        {
          setProductAmount(1);
          //const discountPrice = getPriceWithDiscount(product, 1, props.promo.priceDiscount)
          var rr = products.filter((item) => item.code === props.promo?.gifts[0].codeProduct)[0]
          rr.dinamicDiscounts[0] = {priceDiscount: 100}
          const discountPriceGift = getPriceWithDiscount(rr, 1)
          updateProductToChartAction(rr, 1, discountPriceGift);
        }
    }
    setIsSelected(true);
  };

  const addProductToChart = (product, newProductAmount) => {
    // // console.log("addProductToChart INIT");
    // // console.log("newProductAmount: ", newProductAmount);
    // // console.log("productCode: ", product.code);
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    // console.log("chartProduct: ", chartProduct);
    if (newProductAmount === chartProduct[0].amount) {
      newProductAmount = newProductAmount + 1;
      if (newProductAmount === product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        // console.log("productAmount equal limitStock: ", newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      } else if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(product.stockAmount);
        // console.log("productAmount equal limitStock: ", product.stockAmount)
        const discountPrice = getPriceWithDiscount(product, product.stockAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, product.stockAmount, discountPrice);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        // console.log("productAmount equal: ", newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      }
    } else {
      if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        // console.log("productAmount  NOT equal: ", newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        // console.log("productAmount  NOT equal: ", newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      }
    }
  };

  const substractProductToChart = (product, newProductAmount) => {
    // // console.log("substractProductToChart INIT");
    // // console.log("newProductAmount: ", newProductAmount);
    // // console.log("productCode: ", product.code);
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    // console.log("chartProduct: ", chartProduct);
    if (newProductAmount === chartProduct[0].amount) {
      if (newProductAmount - 1 < 1) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(props.product);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        newProductAmount = newProductAmount - 1;
        setProductAmount(newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      }
    } else {
      if (newProductAmount - 1 < 0) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(props.product);
      } else {
        setProductAmount(newProductAmount);
        const discountPrice = getPriceWithDiscount(product, newProductAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, newProductAmount, discountPrice);
      }
    }
    // // console.log('productAmount a: ', productAmount)
  };

  const handleChangeInput = (product, e) => {
    // // console.log("e.target.value: ", e.target.value);
    // // console.log("e.target.value: ", typeof e.target.value);
    // // console.log("handleChangeInput ");
    if (e.target.value !== '' && Number(e.target.value) > 0) {
      if (Number(e.target.value) >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(product.stockAmount);
        const discountPrice = getPriceWithDiscount(product, product.stockAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, product.stockAmount, discountPrice);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(Number(e.target.value));
        const discountPrice = getPriceWithDiscount(product, product.stockAmount, props.promo.priceDiscount)
        updateProductToChartAction(props.product, product.stockAmount, discountPrice);

      }
    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductAmount(0);
      addProductToChart(product, 0);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas pequeñas
  const productName = isSmallScreen ? toTitleCase(props.product.shortName) : toTitleCase(props.product.name);

  const handleChangeInputBlur = (product, e) => {
    // // console.log("handleChangeInputBlur ");
    // // console.log("e.target.value: ", e.target.value);
    // // console.log("e.target.value: ", typeof e.target.value);
    if (Number(e.target.value) <= 0) {
      setIsSelected(false);
      setProductAmount(0);
      deleteProductFromChartAction(props.product);
    }
  };

  const handleClose = () => {
    setOpenStockAlert(false);
  };

  // console.log(props.product);

  return (
    <>
      <div className="productoCard">
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '12px', md: '15px' },
              color: { xs: '#2D85C5', md: '#31AEE0' },
              textAlign: 'center',
            }}
          >
            Precio Sugerido: S/.
            {props.product.sugPrice.toFixed(1)}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '12px', md: '15px' },
              color: { xs: '#2D85C5', md: '#31AEE0' },
              textAlign: 'center',
            }}
          >
            Ganancia : %
            {(((props.product.sugPrice * 100) / (props.product.salePrice * ((100 - props.promo.priceDiscount) / 100))) - 100).toFixed(1)}
          </Typography>
        </div> */}
        <div className="productoCard__header" style={{alignItems: 'flex-start' }}> 
        {
            props.promo.promoType === "D" || props.promo.promoType === "BD" ?
              (<div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                <div style={{ padding: 8, background: 'red', borderBottomRightRadius: 8, borderTopRightRadius: 8, justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                  <div style={{ textAlign: 'center', color: '#FDFDFD', fontSize: 11,  fontWeight: '700', lineHeight: '16px', wordWrap: 'break-word' }}>
                    {toTitleCase(props.promo.mechanicDiscount)}
                  </div>
                </div>
              </div>) :
              <div style={{ visibility: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                <div style={{ padding: 8, background: 'red', borderBottomRightRadius: 8, borderTopRightRadius: 8, justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                  <div style={{ textAlign: 'center', color: '#FDFDFD', fontSize: 11,  fontWeight: '700', lineHeight: '16px', wordWrap: 'break-word' }}>
                    {toTitleCase(props.promo.mechanicDiscount)}
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="productoCard__header">
          
          <img
            src={props.product !== undefined ? 'https://images-dev.vedoraplus.com/komilon/' + props.product.imageUrl?.[0]?.imgUrl + '.webp' : 'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/images/png/' + props.product.code + '-302x300.png'}
            alt={'Imagen no disponible'}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/assets/images/NoDisponible.png';
            }}
            className="productoCard__img"
          />

        </div>
        {/* <div className="productoCard__body">
          <div className="productoCard__price__withDescount strike">
            <p>S/. {props.product.salePrice.toFixed(1)}</p>
          </div>
          <div className="productoCard__price">
            <p>S/. {(props.product.salePrice * ((100 - props.promo.priceDiscount) / 100)).toFixed(1)}</p>
          </div>
          <p className="productoCard__product">{toTitleCase(productName)}</p>
        </div> */}
        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'inline-flex' }}>
          <div style={{ width: 149.50, height: 40, color: 'black', fontSize: 13,  fontWeight: '700', lineHeight: '20px', wordWrap: 'break-word' }}>
            {toTitleCase(productName)}
          </div>
          <div style={{ width: 61, height: 48, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', display: 'inline-flex' }}>
            <div style={{ textAlign: 'center', color: 'var(--primary-color)', fontSize: 13,  fontWeight: '700', lineHeight: '15px', wordWrap: 'break-word' }}>
              S/. { props.promo.promoType === "D" || props.promo.promoType === "BD" ? 
                      (props.product.salePrice * ((100 - props.promo.priceDiscount) / 100)).toFixed(1) : 
                      props.product.salePrice.toFixed(1)
                  }
            </div>
            {props.promo.promoType === "D" || props.promo.promoType === "BD" ?
              (<div style={{ textAlign: 'center', color: '#999999', fontSize: 12,  fontWeight: '400', textDecoration: 'line-through', lineHeight: '15px', wordWrap: 'break-word' }}>
                S/. {props.product.salePrice.toFixed(1)}
              </div>) :              
                (<div style={{visibility: 'hidden', textAlign: 'center', color: '#999999', fontSize: 12,  fontWeight: '400', textDecoration: 'line-through', lineHeight: '15px', wordWrap: 'break-word' }}>
                  S/. {props.product.salePrice.toFixed(1)}
                </div>)
            }
          </div>
          <div style={{ marginTop: 5, marginBottom: 5, padding: 8, background: 'var(--primary-50-color)', borderRadius: 8, justifyContent: 'flex-start', gap: 4, display: 'inline-flex' }}>
            <div style={{ flex: '1 1 0', height: 32, justifyContent: 'flex-start', gap: 8, display: 'flex' }}>
              <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', display: 'inline-flex' }}>
                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                  <div style={{ width: 16, height: 16, position: 'relative' }}>
                    <div style={{ width: 14, height: 14, left: 1, top: 1, position: 'absolute' }}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.44737 11.4999H8.55263V7.2631H7.44737V11.4999ZM8 6.00199C8.16861 6.00199 8.30996 5.94495 8.42405 5.83086C8.53814 5.71677 8.59518 5.57542 8.59518 5.40681C8.59518 5.23819 8.53814 5.09684 8.42405 4.98275C8.30996 4.86879 8.16861 4.81181 8 4.81181C7.83139 4.81181 7.69003 4.86879 7.57595 4.98275C7.46186 5.09684 7.40482 5.23819 7.40482 5.40681C7.40482 5.57542 7.46186 5.71677 7.57595 5.83086C7.69003 5.94495 7.83139 6.00199 8 6.00199ZM8.00129 14.9999C7.03308 14.9999 6.12302 14.8162 5.2711 14.4488C4.41919 14.0813 3.67818 13.5827 3.04805 12.9528C2.41793 12.3229 1.91903 11.5822 1.55134 10.7307C1.18378 9.87913 1 8.96932 1 8.00123C1 7.03302 1.18372 6.12296 1.55116 5.27104C1.9186 4.41913 2.41725 3.67811 3.04713 3.04799C3.67701 2.41787 4.41772 1.91897 5.26926 1.55128C6.12081 1.18372 7.03062 0.999939 7.99871 0.999939C8.96692 0.999939 9.87698 1.18366 10.7289 1.5511C11.5808 1.91854 12.3218 2.41719 12.9519 3.04707C13.5821 3.67695 14.081 4.41766 14.4487 5.2692C14.8162 6.12075 15 7.03056 15 7.99865C15 8.96686 14.8163 9.87692 14.4488 10.7288C14.0814 11.5807 13.5827 12.3218 12.9529 12.9519C12.323 13.582 11.5823 14.0809 10.7307 14.4486C9.87919 14.8162 8.96938 14.9999 8.00129 14.9999ZM8 13.8947C9.64561 13.8947 11.0395 13.3236 12.1816 12.1815C13.3237 11.0394 13.8947 9.64555 13.8947 7.99994C13.8947 6.35432 13.3237 4.96047 12.1816 3.81836C11.0395 2.67625 9.64561 2.1052 8 2.1052C6.35439 2.1052 4.96053 2.67625 3.81842 3.81836C2.67632 4.96047 2.10526 6.35432 2.10526 7.99994C2.10526 9.64555 2.67632 11.0394 3.81842 12.1815C4.96053 13.3236 6.35439 13.8947 8 13.8947Z" fill="var(--primary-color)" />
                      </svg>

                    </div>
                  </div>
                  <div style={{ textAlign: 'center', color: 'var(--primary-color)', fontSize: 11,  fontWeight: '700', lineHeight: '16px', wordWrap: 'break-word' }}>
                    Ganancia: {getGanancia(props.product, 0, props.promo.priceDiscount)}%
                  </div>
                </div>
                <div style={{ textAlign: 'left', alignSelf: 'stretch', color: '#6A6A6A', fontSize: 11,  fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word' }}>
                  Pregio sugerido: S/. {props.product.sugPrice.toFixed(1)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          props.promo.promoType === "B" || props.promo.promoType === "BD" ?
            (<div style={{ marginTop: 5, marginBottom: 5, width: 149.50, height: 96, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
              <img style={{ width: 48, height: 48, boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.06)', borderRadius: 9999 }}
                src={props.promo.promoImage !== undefined ? props.promo.promoImage : props.product.imageUrl}
              />
              <div style={{ alignSelf: 'stretch', height: 48, background: '#FDFDFD', borderRadius: 16, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                <div style={{ textAlign: 'justify', alignSelf: 'stretch', color: '#212121', fontSize: 11,  fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word' }}>
                  {toTitleCase(props.promo.mechanicBonus)}
                </div>
              </div>
            </div>) :
            <div style={{ visibility: 'hidden', marginTop: 5, marginBottom: 5, width: 149.50, height: 96, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
              <img style={{ width: 48, height: 48, boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.06)', borderRadius: 9999 }}
                src={props.promo.promoImage !== undefined ? props.promo.promoImage : props.product.imageUrl}
              />
              <div style={{ alignSelf: 'stretch', height: 48, background: '#FDFDFD', borderRadius: 16, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                <div style={{ textAlign: 'justify', alignSelf: 'stretch', color: '#212121', fontSize: 11,  fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word' }}>
                  {toTitleCase(props.promo.mechanicBonus)}
                </div>
              </div>
            </div>
        }

        <div className="productoCard__footer">
          {isSelected === true ? (
            <div className="productoCard__add">
              <button id={`btn-promo-substract-${props.product?.code}`} className="productoCard__add--minus" onClick={() => substractProductToChart(props.product, productAmount)}>
                <FaMinus />
              </button>
              <div className="productoCard__add--input">
                <input type="number" value={productAmount} onChange={(e) => handleChangeInput(props.product, e)} onBlur={(e) => handleChangeInputBlur(props.product, e)} />
              </div>
              <button id={`btn-promo-add-${props.product?.code}`} className="productoCard__add--plus" onClick={() => addProductToChart(props.product, productAmount)} disabled={noStock}>
                <FaPlus />
              </button>
            </div>
          ) : (
            <div id={`btn-addcart-${props.product?.code}`} className="productoCard__footer--button" onClick={() => selectProduct(props.product, productAmount)}>
              Añadir al carrito
            </div>
          )}
        </div>
      </div>
      <Snackbar open={openStockAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          {'¡Solo ' + (props.product.stockAmount === 1 ? 'queda ' + props.product.stockAmount + ' unidad' : 'quedan ' + props.product.stockAmount + ' unidades') + ' en stock!'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductPromo;
