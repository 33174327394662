import { api } from "./axiosConfig";

export const GET_BANNER_SERVICE = async () => {
	return await api.get(process.env.REACT_APP_GET_BANNER_URL);
};

export const GET_BANNER_ID_SERVICE = async () => {
	return await api.post(process.env.REACT_APP_GET_BANNER_ID_URL);
};

export const CREATE_UPDATE_BANNER_SERVICE = async (parameter) => {
	return await api.post(
		process.env.REACT_APP_CREATE_BANNER_URL,
		JSON.stringify(parameter)
	);
};

export const DELETE_BANNER_ID_SERVICE = async (id) => {
	return await api.post(
		process.env.REACT_APP_DELETE_BANNER_URL,
		JSON.stringify(id)
	);
};

export const DELETE_IMAGEN_ID_BANNER_SERVICE = async (parameter) => {
	return await api.post(
		process.env.REACT_APP_DELETE_IMAGEN_BANNER_ID_URL,
		JSON.stringify(parameter)
	);
};
