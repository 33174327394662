import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Typography, Box, Grid, Container, Paper, CircularProgress, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const AdminBestProfitContainer = () => {
  const [datos, setDatos] = useState([]);
  const columns = [
    {
      field: 'code',
      headerName: 'Código',
      width: 105,
      description: 'Código de producto en el ERP.',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Nombre largo',
      flex: 1,
      description: 'Denominación larga del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'categoria',
      headerName: 'Categoría',
      width: 130,
      description: 'Categoría del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'subcategoria',
      headerName: 'Subcategoría',
      width: 143,
      description: 'Subategoría del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'proveedor',
      headerName: 'Proveedor',
      width: 140,
      description: 'Proveedor del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'marca',
      headerName: 'Marca',
      width: 110,
      description: 'Marca del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'rentabilitiPercetage',
      headerName: 'Rentabilidad',
      description: 'Rentabilidad del producto en el ERP.',
      width: 140,
      sortable: false,
    },
  ];

  const getData = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_GET_PRODUCT_URL,
        {
          officeId: '37',
          storageId: '1',
          productId: 'adminProducts',
          priceListCode: '60',
        },
        { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } },
      );
      let datos = data.products.map((dato) => ({ ...dato, id: uuidv4() }));
      orderData(datos);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const orderData = (datos) => {
    if (datos.length > 0) {
      let moreProfitable = rentabilityProd(datos);
      for (var i = 0; i < moreProfitable.length; i++) {
        moreProfitable[i].rentabilitiPercetage = moreProfitable[i].margin;
        if (moreProfitable[i].rentabilitiPercetage === ' ' || moreProfitable[i].rentabilitiPercetage === '%' || moreProfitable[i].rentabilitiPercetage === 0) {
          moreProfitable[i].rentabilitiPercetage = '-';
        } else {
          moreProfitable[i].rentabilitiPercetage = moreProfitable[i].rentabilitiPercetage + '%';
        }
      }
      setDatos(moreProfitable);
    }
  };

  function rentabilityProd(products) {
    let rentabilityProducts = products.slice();
    rentabilityProducts.sort((a, b) => (a.margin > b.margin ? -1 : 1));
    return rentabilityProducts;
  }

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography component="div" flexGrow="1">
                <Box fontSize="18px" fontWeight="500">
                  Más rentables
                </Box>
              </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {datos.length === 0 ? (
            <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid rows={datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
          )}
        </Paper>
      </Grid>
    </div>
  );
};
