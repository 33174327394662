import React from 'react';
import { useCart } from '../context/CartContext';
import { useUserAuth } from '../context/UserAuthContext';

import '../styles/SummaryInfo.css';

export const SummaryInfo = () => {

  const { chart, backStepper, nextStepper, order, handleCloseOrder } = useCart();
  const { user } = useUserAuth()
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER || '+51908824814'; // Valor por defecto
  const vedoraEnvironment = process.env.REACT_APP_VEDORA_ENVIRONMENT || ''; // Valor por defecto
 
  const handleFinshOrder = async () => {
    await nextStepper();
    await handleCloseOrder(user);
    await new Promise(resolve => setTimeout(resolve, 3000));
    abrirWhatsApp(); // Llama a abrirWhatsApp después de completar el pedido
};

const abrirWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`. ${vedoraEnvironment}`)}`;
    window.location.href = url; // Redirige a WhatsApp en la misma página
};

  return (
    <div className="summaryInfo-container">
      <h4 className="summaryInfo-title">¡Resumen de tu pedido!</h4>
      <div className="summaryInfo-body">
        <div className="summaryInfo-detail">
          <p className="summaryInfo-subtitle">Dirección de envío:</p>
          <p className="summaryInfo-content">{user?.address}</p>
        </div>
        <div className="summaryInfo-detail">
          <p className="summaryInfo-subtitle">Día de entrega:</p>
          <p className="summaryInfo-content">{order?.deliveryDay}</p>
        </div>
        <div className="summaryInfo-detail">
          <p className="summaryInfo-subtitle">Horario de Entrega:</p>
          <p className="summaryInfo-content">{order?.schedule}</p>
        </div>
      </div>
      <div className="summaryInfo-footer">
        <p className="summaryInfo-quantity">Tienes ({chart.products.length}) items en el carrito</p>
        <div className="summaryInfo-price-container">
          <p className="summaryInfo-price-text">Subtotal</p>
          <p className="summaryInfo-price-text">S/{order?.update?.totalPrice.toFixed(1)}</p>
        </div>
        <div className="summaryInfo-buttons-container">
          <button className="summaryInfo-btn btn-backCart" id='btn-backCart' data-btn-id="btn-backCart" onClick={() => backStepper()}>REGRESAR AL CARRITO</button>
          <button className="summaryInfo-btn btn-finish" id='btn-cart-finish-order' data-btn-id="btn-cart-finish-order" onClick={handleFinshOrder}>FINALIZAR</button>
        </div>
      </div>
      <button className="summaryInfo-btn-mobile summaryInfo-btn btn-finish" id='btn-mobile-cart-finish-order' onClick={handleFinshOrder}>FINALIZAR</button>
    </div>
  );
};
