import { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';

import axios from 'axios';
import MainLayout from '../layout/MainLayout';
import SetUserAddress from '../components/SetUserAddress';
import SpinnerTeresa from '../components/SpinnerTeresa';

// import * as Sentry from "@sentry/react";
// import LogRocket from 'logrocket';

const checkTokenAccion = async (token) => {
  try {
    let data = JSON.stringify({
      token: token,
    });
    let config = {
      method: 'get',
      url: process.env.REACT_APP_CHECK_TOKEN_URL + '/' + token,
      headers: {
        api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        'Content-Type': 'application/json',
      }
    };
    let apiResponse;
    // let access = false;
    let infoUser = {};
    let infoClient = {};
    await axios(config)
      .then((response) => (apiResponse = response.data))
      .catch((error) => console.error(error));
    // console.log('data from firebase: ', apiResponse);
    const isValid = apiResponse ? true : false;
    //console.log(apiResponse)
    // access = apiResponse ? apiResponse.access : false;
    if (isValid) {
      //si es primera vez o la 2da vez (es decir, si es válido el token)
      infoUser = apiResponse.clientInfo;
      //console.log('aaaaaa: ', infoUser);
      let getClientbody = JSON.stringify({
        clientCode: infoUser.clientCode,
        clientMobilePhone: infoUser.mobilePhone
      });
      config = {
        method: 'get',
        url: process.env.REACT_APP_GET_CLIENT_URL + '/' + infoUser.clientCode,
        headers: {
          api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
          'Content-Type': 'application/json',
        }        
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            infoClient = response.data;
          } else {
            //Error
          }
        })
        .catch(function (error) {
          console.error(error);
        });

      // console.log('data from ERP: ', infoClient);
      infoClient.mobilePhone = apiResponse.clientInfo.mobilePhone;
      infoClient.access = apiResponse.access;

      // Sentry.setTag('user.phone',infoClient.mobilePhone);
      // Sentry.setTag('user.code',infoUser.clientCode);
      // LogRocket.identify(infoUser.clientCode, {
      //   phone: infoClient.mobilePhone
      // });
      // if (infoClient.deliveryAddresses.length === 1) {
      //   infoUser.addressId = infoClient.deliveryAddresses[0].code;
      // } else {
      //   infoUser.addressId = '';
      // }

      // firebaseApp.analytics().setUserId(infoUser.clientCode);
      // firebaseApp.analytics().setUserProperties({
      // clientCode: infoUser.clientCode,
      // mobilePhone: infoUser.mobilePhone,
      // });
      // if (
      // 	infoUser.clientCode !== "097597" &&
      // 	infoUser.clientCode !== "103604"
      // ) {
      // 	analyticsEvent("user_logged", {
      // 		clientCode: infoUser.clientCode,
      // 	});
      // }

      // localStorage.setItem("infoUser", JSON.stringify(infoUser));
      // localStorage.setItem("infoClient", JSON.stringify(infoClient));
    } else {
      // si es tercera vez (o si el token no es válido)
      infoClient = { denied: true };
    }

    return infoClient;
  } catch (error) {
    console.error(error);
  }
};

const UserAuthPage = () => {
  const { token } = useParams();
  const { logIn } = useUserAuth();
  const [userLoggedIn, setUserLoggedIn] = useState({});

  useEffect(() => {

    //Sentry.setTag('user.token',token);

    checkTokenAccion(token)
      .then((result) => {

        setUserLoggedIn(result)
      })
      // .then((result) => console.log('El useEffect devolvió: ', result))
      .catch((error) => console.error(error));
  }, []);

  if (Object.keys(userLoggedIn).length === 0)
    return (
      <SpinnerTeresa/>
    );
  if (userLoggedIn.hasOwnProperty('denied')) return <Navigate replace to="/" />;
  if (userLoggedIn.access === false) {
    userLoggedIn.addressId = userLoggedIn.deliveryAddresses[0].code;
    userLoggedIn.officeId = userLoggedIn.deliveryAddresses[0].officeId;
    userLoggedIn.storageId = userLoggedIn.deliveryAddresses[0].storageId;
    userLoggedIn.priceListCode = userLoggedIn.deliveryAddresses[0].priceListCode;
    userLoggedIn.routes = userLoggedIn.deliveryAddresses[0].routes;
    userLoggedIn.schedule = userLoggedIn.deliveryAddresses[0].schedule;
    logIn(userLoggedIn);
    return <Navigate replace to="/tienda" />;
  }
  if (userLoggedIn?.deliveryAddresses?.length > 1)
    return (
      <MainLayout access="auth">
        <SetUserAddress userLoggedIn={userLoggedIn} />
      </MainLayout>
    );
  if (userLoggedIn?.deliveryAddresses?.length === 1) {
    userLoggedIn.addressId = userLoggedIn.deliveryAddresses[0].code;
    userLoggedIn.officeId = userLoggedIn.deliveryAddresses[0].officeId;
    userLoggedIn.storageId = userLoggedIn.deliveryAddresses[0].storageId;
    userLoggedIn.priceListCode = userLoggedIn.deliveryAddresses[0].priceListCode;
    userLoggedIn.routes = userLoggedIn.deliveryAddresses[0].routes;
    userLoggedIn.schedule = userLoggedIn.deliveryAddresses[0].schedule;
    logIn(userLoggedIn);
    return <Navigate replace to="/tienda" />;
  } 
};

export default UserAuthPage;
