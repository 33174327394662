import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, AppBar, Tabs, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Product from '../components/Product';
import SpinnerTeresa from '../components/SpinnerTeresa';
// import Filtro from './Filtro';
import { toTitleCase, a11yProps } from '../utils';
import theme from '../utils/ThemeConfig';
import MainLayout from '../layout/MainLayout';
import ScrollerMenu from '../components/ScrollerMenu';
import { useUserAuth } from '../context/UserAuthContext';
import { useProducts } from '../context/ProductsContext';

const UserBrandPage = () => {
  const navigate = useNavigate();
  const urlParamsBrand = useParams().brand;
  const urlParamsSubcategory = useParams().subcategory;
  const [brand, setBrand] = useState(useParams().brand);
  const [subcategory, setSubcategory] = useState(useParams().subcategory);
  const [limitItems, setLimitItems] = useState(12);
  const [productsCollection, setProductsCollection] = useState({});
  const { user } = useUserAuth();
  const { products, categories, brands, getProducts, getCategories, getBrands, filterProductsByBrand, filteredProducts } = useProducts();

  // console.log('urlParamsSubcategory', urlParamsSubcategory);

  useEffect(() => {
    (async () => {
      if (products.length === 0) {
        let products = await getProducts(user);
        getCategories(products);
      }
    })();
  }, [products]);

  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    }

    if (products.length !== 0) {
      let respuesta;
      if (brand !== urlParamsBrand) {
        setBrand(urlParamsBrand);
        setSubcategory(urlParamsSubcategory);
        setLimitItems(12);
        respuesta = filterProductsByBrand(products, categories, urlParamsBrand, urlParamsSubcategory);
        setProductsCollection(respuesta);
      } else {
        // console.log('Category: ', category);
        setSubcategory(urlParamsSubcategory)
        respuesta = filterProductsByBrand(products, categories, brand, urlParamsSubcategory);
        setProductsCollection(respuesta);
      }
    }
  }, [categories, brand, urlParamsBrand, urlParamsSubcategory]);

  const showMoreItems = () => {
    setLimitItems(limitItems + 12);
  };

  return (
    <>
      {products.length === 0 && <SpinnerTeresa />}
      {categories.length === 0 && <SpinnerTeresa />}
      {filteredProducts === undefined && <SpinnerTeresa />}
      {!productsCollection.hasOwnProperty('subCategoryIndex') ? (
        <SpinnerTeresa />
      ) : (
        <MainLayout access="user">
          <div className="l-container">
            <Typography component="div" color="secondary">
              <Box component="div" maxHeight="100px" minHeight="80px" textOverflow="ellipsis" overflow="hidden" textAlign="center" display="flex" justifyContent="center" mt={3} mb={1}>
                <img
                  src={'https://images-dev.vedoraplus.com/brands/' + productsCollection.brand.toLowerCase()+ '.png'}
                  alt={productsCollection.brand}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/assets/images/NoDisponible.png';
                  }}
                  style={{
                    height: 'auto',
                    width: 'auto',
                    maxWidth: '120px',
                    maxHeight: '100px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Typography>
            {productsCollection.subCategory === '' ? null : (
              <AppBar
                sx={{
                  boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
                  '& span': {
                    height: '0px',
                    fontSize: '12px',
                    color: '#000000',
                  },
                  '& svg': {
                    color: '#2D85C5',
                  },
                  marginTop: '8px',
                }}
                position="static"
                color="default"
              >
                <Tabs value={productsCollection.subCategoryIndex + 1} variant="scrollable" scrollButtons={true} aria-label="scrollable force tabs example">
                  <Tab
                    onClick={() => navigate('/tienda/marca/' + brand + '/home')}
                    label={toTitleCase('ver todo')}
                    {...a11yProps(0)}
                    sx={{
                      '& span': {
                        textTransform: 'none',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {productsCollection.subCategories.map((item, index) => (
                    <Tab
                      onClick={() => navigate('/tienda/marca/' + brand + '/' + item.name.toLowerCase().split(' ').join('-'))}
                      key={index}
                      label={toTitleCase(item.name)}
                      {...a11yProps(index)}
                      sx={{
                        '& span': {
                          textTransform: 'none',
                          fontSize: '14px',
                        },
                      }}
                    />
                  ))}
                </Tabs>
              </AppBar>
            )}
            <div
              style={{
                flexGrow: '1',
                backgroundColor: 'white',
                paddingTop: theme.spacing(2),
                margin:'0 20px',
                minHeight: 'calc(100vh - 96px - 146px - 144px)',
              }}
            >
              <Grid container direction="row" display="flex" alignItems="stretch" alignContent="center">
                {/* {productsCollection.minPrice === 0 || productsCollection.maxPrice === 0 || productsCollection.categories.length === 0 ? null : (
              <Grid item xs={12}>
                <Box>
                  <Filtro priceFilter={false} />
                </Box>
              </Grid>
            )} */}
                {filteredProducts.length <= limitItems
                  ? filteredProducts
                      .filter((el) => el.stockAmount !== 0)
                      .map((item, index) => (
                        <Grid item xs={6} sm={4} md={3} key={item.code}>
                          <Box my={1}>
                            <Product product={item} />
                          </Box>
                        </Grid>
                      ))
                  : filteredProducts
                      .slice(0, limitItems)
                      .filter((el) => el.stockAmount !== 0)
                      .map((item, index) => (
                        <Grid item xs={6} sm={4} md={3} key={item.code}>
                          <Box>
                            <Product key={item.code} product={item} />
                          </Box>
                        </Grid>
                      ))}
              </Grid>
              {limitItems >= filteredProducts.length ? null : (
                <Grid container direction="row" justify="center" alignItems="center" alignContent="center">
                  <Grid item xs={12}>
                    <Box width="100%" my={2}display="flex" justifyContent="center" >
                      <button className='buttonMore' 
                          onClick={showMoreItems} 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}>
                            Ver Más
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default UserBrandPage;
