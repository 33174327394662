import { useEffect, useState } from 'react'
import '../styles/FormExperience.css'
import Experience01 from '../assets/Experiencia_01.png'
import Experience02 from '../assets/Experiencia_02.png'
import Experience03 from '../assets/Experiencia_03.png'
import Experience04 from '../assets/Experiencia_04.png'
import Experience05 from '../assets/Experiencia_05.png'
import { GET_SURVEY_INFORMATION, SAVE_SURVEY } from '../services/experienceService'
import { useUserAuth } from '../context/UserAuthContext'
import { useNavigate } from 'react-router-dom'

export default function FormExperience() {
  const [form, setForm] = useState({
    experienceRating: '',
    recommendationType: '',
    recommendation: '',
    recommendationProbability: '',
  })

  const { user } = useUserAuth()

  const [options, setOptions] = useState([])

  const { recommendationType, experienceRating, recommendation, recommendationProbability } = form

  const navigate = useNavigate()

  const handleClickEmoji = (rating) => {
    setForm({
      ...form,
      experienceRating: rating,
    })
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleClickProbability = (probability) => {
    setForm({
      ...form,
      recommendationProbability: probability,
    })
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      
      if(experienceRating === '' || recommendationProbability === '') return;
  
      const surveyResponse = {
        ...form,
        experienceRating: Number(form.experienceRating),
        recommendationProbability: Number(form.recommendationProbability),
      }
  
      const response = await SAVE_SURVEY(surveyResponse, user.mobilePhone)

      if(response.status === 200){
        navigate('/tienda')
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  const getOptions = async () => {
    try {
      const { data } = await GET_SURVEY_INFORMATION()

      setOptions(data.surveyOptions)

    } catch (error) {
      console.error(error);
    }
  }
  

  useEffect(() => {
    getOptions()
  }, [])

  return (
    <div className='formExperience-container'>
      <form onSubmit={handleSubmit}>
        <div className='formExperience-question'>
          <p className='formExperience-title'>¿Cómo calificarlas la experiencia de compra en nuestra web?</p>
          <div className='formExperience-emojis'>
            <button
              type='button'
              onClick={() => handleClickEmoji('1')}
              className={`btn-emoji ${experienceRating === '1' ? 'active' : ''}`}>
              <img src={Experience01} alt='Experience01-image' />
            </button>
            <button
              type='button'
              onClick={() => handleClickEmoji('2')}
              className={`btn-emoji ${experienceRating === '2' ? 'active' : ''}`}>
              <img src={Experience02} alt='Experience02-image' />
            </button>
            <button
              type='button'
              onClick={() => handleClickEmoji('3')}
              className={`btn-emoji ${experienceRating === '3' ? 'active' : ''}`}>
              <img src={Experience03} alt='Experience03-image' />
            </button>
            <button
              type='button'
              onClick={() => handleClickEmoji('4')}
              className={`btn-emoji ${experienceRating === '4' ? 'active' : ''}`}>
              <img src={Experience04} alt='Experience04-image' />
            </button>
            <button
              type='button'
              onClick={() => handleClickEmoji('5')}
              className={`btn-emoji ${experienceRating === '5' ? 'active' : ''}`}>
              <img src={Experience05} alt='Experience05-image' />
            </button>
          </div>
        </div>
        <div className='formExperience-question'>
          <p className='formExperience-title'>¿Qué tipo de recomendación tienes?</p>
          <select
            className='formExperience-select'
            name='recommendationType'
            value={recommendationType}
            required
            onChange={handleChange}>
            <option value=''>Elige tu opción</option>
            {
              options.map(item => (
                <option key={item} value={item} >{item}</option>
              ))
            }
          </select>
        </div>
        <div className='formExperience-question'>
          <p className='formExperience-title'>¿Qué te gustaría compartir con nosotros?</p>
          <textarea
            required
            name='recommendation'
            cols='30'
            rows='5'
            className='formExperience-recommendation'
            placeholder='Escribe aquí…'
            onChange={handleChange}
            value={recommendation}></textarea>
        </div>
        <div className='formExperience-question'>
          <p className='formExperience-title'>¿Qué tan probable es que nos recomiendes?</p>
          <div className='formExperience-probability'>
            <button
              className={`btn-probability ${recommendationProbability === '1' ? 'active' : ''}`}
              onClick={() => handleClickProbability('1')}
              type='button'>
              1
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '2' ? 'active' : ''}`}
              onClick={() => handleClickProbability('2')}
              type='button'>
              2
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '3' ? 'active' : ''}`}
              onClick={() => handleClickProbability('3')}
              type='button'>
              3
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '4' ? 'active' : ''}`}
              onClick={() => handleClickProbability('4')}
              type='button'>
              4
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '5' ? 'active' : ''}`}
              onClick={() => handleClickProbability('5')}
              type='button'>
              5
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '6' ? 'active' : ''}`}
              onClick={() => handleClickProbability('6')}
              type='button'>
              6
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '7' ? 'active' : ''}`}
              onClick={() => handleClickProbability('7')}
              type='button'>
              7
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '8' ? 'active' : ''}`}
              onClick={() => handleClickProbability('8')}
              type='button'>
              8
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '9' ? 'active' : ''}`}
              onClick={() => handleClickProbability('9')}
              type='button'>
              9
            </button>
            <button
              className={`btn-probability ${recommendationProbability === '10' ? 'active' : ''}`}
              onClick={() => handleClickProbability('10')}
              type='button'>
              10
            </button>
          </div>
        </div>

        <button className='formExperience-btn-submit' type='submit'>
          Enviar Experiencia
        </button>
      </form>
    </div>
  )
}
