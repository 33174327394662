import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Container, Paper, Button, CircularProgress, Divider, Input } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid } from '@mui/x-data-grid';
import { DataObjectSharp } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { api } from '../services/axiosConfig';
import toast from 'react-hot-toast';

export const AdminMoreInterestingContainer = () => {
  const [datos, setDatos] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_GET_PRODUCT_URL,
        {
          officeId: '37',
          storageId: '1',
          productId: 'adminProducts',
          priceListCode: '60',
        },
        { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } },
      );
      let datos = data.products.map((dato) => ({ ...dato, id: uuidv4() }));
      let moreInteresting = datos.filter((dato) => dato.isInteresting).sort((a, b) => {
        return a.numberMoreInteresting > b.numberMoreInteresting || !a.numberMoreInteresting ? 1 : -1
      });;
      setDatos(moreInteresting);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: 'code',
      headerName: 'Código',
      width: 105,
      description: 'Código de producto en el ERP.',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Nombre largo',
      flex: 1,
      description: 'Denominación larga del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'categoria',
      headerName: 'Categoría',
      width: 130,
      description: 'Categoría del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'subcategoria',
      headerName: 'Subcategoría',
      width: 143,
      description: 'Subategoría del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'proveedor',
      headerName: 'Proveedor',
      width: 140,
      description: 'Proveedor del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'marca',
      headerName: 'Marca',
      width: 110,
      description: 'Marca del producto en el ERP.',
      sortable: false,
    },
    {
      field: 'positionMoreInteresting',
      headerName: 'Orden',
      sortable: false,
      width: 70,
      sortable: false,
      type: 'number',
      renderCell: (params) => {
        return (
          <div>
            <MatEdit index={params.row.positionMoreInteresting} code={params.row.code} oldIndex={params.row.numberMoreInteresting} />
          </div>
        );
      },
    }
  ];

  const MatEdit = ({ index, code, oldIndex }) => {
    return (
      <div style={{ width: '100%' }}>
        <Input
          type="number"
          value={index}
          onChange={({ target }) => {
            console.log("code", code);
            setDatos((st) => st.map((dato) => (dato.code === code ? { ...dato, positionMoreInteresting: parseInt(target.value) } : (dato.numberMoreInteresting >= parseInt(target.value) && (!oldIndex || dato.numberMoreInteresting < oldIndex) ? { ...dato, positionMoreInteresting: dato.numberMoreInteresting + 1 } : dato))));
          }}
          fullWidth={true}
          inputProps={{ min: 1, max: DataObjectSharp.length }} />
      </div>
    );
  };

  const handleSavePosition = async () => {
    console.log("datos1", datos);
    const params = datos.filter((dato) => dato.positionMoreInteresting).map((dato) => ({ productCode: dato.code, positionMoreInteresting: dato.positionMoreInteresting }));
    console.log("params", params);

    try {
      const { data } = await api.post(process.env.REACT_APP_UPDATE_ORDER_MOREINTERESTING_URL, params);
      if (data.success) {
        toast.success('El orden fue actualizado en la base de datos');
        setDatos([]);
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography component="div" flexGrow="1">
                <Box fontSize="18px" fontWeight="500">
                  Más interesantes
                </Box>
              </Typography>
              <Button
                variant="contained"
                sx={{ textTransform: 'none', color: 'white' }}
                onClick={() => {
                  handleSavePosition();
                }}
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {datos.length === 0 ? (
            <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid rows={datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
          )}
        </Paper>
      </Grid>
    </div>
  );
};
