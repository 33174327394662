import React, { useEffect, useState } from 'react'
import { Typography, Box, Grid, Container, Paper, CircularProgress, Button, Divider } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import TimerIcon from '@mui/icons-material/Timer'
import LoopIcon from '@mui/icons-material/Loop'
import { green, red } from '@mui/material/colors'
import { api } from '../services/axiosConfig'
import ApiCard from '../components/ApiCard'

export const AdminApisContainer = () => {
  const [loadingServices, setLoadingServices] = useState(false)

  const [serviceStatus, setServiceStatus] = useState({
    getClient: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
    getProduct: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
    getPromotions: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
    startOrder: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
    updateOrder: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
    closeOrder: {
      status: '',
      averageTime: '',
      averageTime95: '',
      maxTime: '',
    },
  })

  const getServiceStatus = async () => {
    setLoadingServices(true)

    try {
      const { status, data } = await api.post(process.env.REACT_APP_GET_API_STATUS_URL);
      
      if(status === 200)[
        setServiceStatus({...data.apisStatus})
      ]
      
      setLoadingServices(false)
    } catch (err) {
      setLoadingServices(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getServiceStatus()
  }, [])

  console.log(serviceStatus);

  return (
    <div>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display='flex' justifyContent='center' alignItems='center' width='100%'>
              <Typography component='div' flexGrow='1'>
                <Box fontSize='18px' fontWeight='500'>
                  Diagnóstico de servicios
                </Box>
              </Typography>
              <Button variant='contained' sx={{ textTransform: 'none', color: 'white' }} onClick={getServiceStatus}>
                Ejecutar diagnóstico completo
              </Button>
            </Box>
            <Divider />
          </Grid>

          <>
            <Grid item xs={6}>
             <ApiCard title="Get Client" api={serviceStatus.getClient} loadingServices={loadingServices} />
            </Grid>
            <Grid item xs={6}>
             <ApiCard title="Get Product" api={serviceStatus.getProduct} loadingServices={loadingServices} />
            </Grid>
            <Grid item xs={6}>
             <ApiCard title="Get Promotions" api={serviceStatus.getPromotions} loadingServices={loadingServices} />
            </Grid>
            <Grid item xs={6}>
             <ApiCard title="Start Order" api={serviceStatus.startOrder} loadingServices={loadingServices} />
            </Grid>
            <Grid item xs={6}>
             <ApiCard title="Update Order" api={serviceStatus.updateOrder} loadingServices={loadingServices} />
            </Grid>
            <Grid item xs={6}>
             <ApiCard title="Close Order" api={serviceStatus.closeOrder} loadingServices={loadingServices} />
            </Grid>
           
          </>
        </Grid>
      </Container>
    </div>
  )
}
