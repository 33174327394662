import { api } from './axiosConfig';

export const POST_START_ORDER = (parameters) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('Se llamó a RegisterOrder: ' + process.env.REACT_APP_START_ORDER_URL);
      console.log(JSON.stringify(parameters));
      const { data } = await api.post(process.env.REACT_APP_START_ORDER_URL, JSON.stringify(parameters));
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const POST_UPDATE_ORDER = (parameters) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('Se llamó a UpdateOrder: ' + process.env.REACT_APP_UPDATE_ORDER_URL);
      console.log(JSON.stringify(parameters));
      const { data } = await api.post(process.env.REACT_APP_UPDATE_ORDER_URL, JSON.stringify(parameters));

      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};


export const POST_CLOSE_ORDER = (parameters) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('Se llamó a ConfirmOrder: ' + process.env.REACT_APP_CLOSE_ORDER_URL);
      console.log(JSON.stringify(parameters));
      const { data } = await api.post(process.env.REACT_APP_CLOSE_ORDER_URL, JSON.stringify(parameters));

      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const POST_ADD_SOLD_ITEMS = async (parameters) => {

    try {
      const { data } = await api.post(process.env.REACT_APP_ADD_SOLD_ITEMS_URL, JSON.stringify(parameters));

      return data;
    } catch (error) {
      console.error(error);
    }
 
};

export const POST_SEND_MESSAGE = async (parameters) => {

    try {
      console.log('Se llamó a Sendmesage: ' + process.env.REACT_APP_SEND_MESSAGE_URL);
      console.log(JSON.stringify(parameters));
      const { data } = await api.post(process.env.REACT_APP_SEND_MESSAGE_URL, JSON.stringify(parameters));

      return data
    } catch (error) {
      console.error(error);
     
    }
  
};
