import firebaseApp from './firebase';

export const GET_STORAGE = (key, type = 'object') => {
  if (type === 'object') {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }
  return localStorage.getItem(key);
};

export const SET_STORAGE = (key, value, type = 'object') => {
  if (type === 'object') {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  return localStorage.setItem(key, value);
};

export const DELETE_STORAGE = (key) => {
  return localStorage.removeItem(key);
};

export const uploadNewImage = async (file) => {
  return new Promise((resolve, reject) => {
    // console.log("file: ", file.name);
    firebaseApp
      .storage()
      .refFromURL('gs://' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/images/adminImages/' + file.name)
      .put(file)
      .then(function (snapshot) {
        // console.log("Uploaded a blob or file!", snapshot.ref);
        resolve('https://storage.googleapis.com/' + snapshot.ref.bucket + '/' + snapshot.ref.fullPath);
      });
  });
};

export const uploadNewImageBanner = async (file) => {
  return new Promise((resolve, reject) => {
    // console.log("file: ", file.name);
    firebaseApp
      .storage()
      .refFromURL('gs://' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/banners/' + file.name)
      .put(file)
      .then(function (snapshot) {
        // console.log("Uploaded a blob or file!", snapshot.ref);
        resolve('https://storage.googleapis.com/' + snapshot.ref.bucket + '/' + snapshot.ref.fullPath);
      });
  });
};
