import '../styles/UserHeader.css';
import style from '../styles/UserHeader.module.css';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { FaSearch, FaUser, FaTimes, FaMapMarkerAlt, FaClipboardList, FaAngleDown, FaBullhorn, FaMedal, FaFacebookF, FaInstagram } from 'react-icons/fa';

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useRef, useEffect } from 'react';
import { toTitleCase } from '../utils';
import { useProducts } from '../context/ProductsContext';
import { useUserAuth } from '../context/UserAuthContext';
import { useCart } from '../context/CartContext';
import { IoMdHome, IoMdMenu } from 'react-icons/io';
import { IoLogoWhatsapp } from 'react-icons/io5';

import Ubicacion from '../assets/Ubicacion.png';
import BuscadorLista from '../assets/Buscador_lista.png';
import UserIcon from '../assets/User.png';
import CarritoIcon from '../assets/Camion.png';
import DireccionIcon from '../assets/Tienda_Blanco.png';
import DireccionActivaIcon from '../assets/Tienda_Azul.png';
import HomeIcon from '../assets/Home.svg';
import HomeIconMovil from '../assets/HomeMovil.svg';
import BuscadorIcon from '../assets/Buscador.svg';
// import FondoBusqueda from '../assets/fondoBusqueda.png';
import FondoBusqueda from '../assets/Buscador_por_listado-removebg-preview.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/ShareLocation';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const SearchItem = ({ el, handleClickSuggestion }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas pequeñas
  const productName = isSmallScreen ? toTitleCase(el.shortName) : toTitleCase(el.name);

  return (
    <div className="headerL__prediction--item" onClick={() => handleClickSuggestion(el.code)}>
      <div className="headerL__prediction--item__info">
        {/* <img
          src={el.imageUrl}
          alt={el.name}
          style={{ width: 40, display: imageLoading ? 'none' : 'block' }}
          onLoad={() => {
            setImageLoading(false);
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/assets/images/NoDisponible.png';
          }}
        />
        {imageLoading && <CircularProgress style={{ width: 40 }} />} */}

        <p>{productName}</p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="headerL__prediction--item__price">
          <p>S/. {el.salePrice.toFixed(1)}</p>
        </div>
      </div>
    </div>
  );
};

const UserHeader = () => {
  const menuRef = useRef(null);
  const [tabSearch, setTabSearch] = useState('normal-search');
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [predictionProducts, setPredictionProducts] = useState([]);
  const [totalItemsChart, setTotalItemsChart] = useState(0);
  const [subMenu, setSubMenu] = useState(null);
  const [tags, setTags] = useState([]);
  const [searchList, setSearchList] = useState('');

  const [showMenu, setShowMenu] = useState(false);
  const [showNavMenu, setShowNavMenu] = useState(false);
  const { user, changeAddress } = useUserAuth();
  const { products, categories, getProducts, getCategories, search, setSearch, filteredProducts, setFilteredProducts, setSearchProducts } = useProducts();
  const { chart } = useCart();
  const navigate = useNavigate();
  const [addressActive, setAddressActive] = useState(user.addressId);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const timer = useRef(null)

  const searchProductsByName = async (searchValue) => {
    try {
      // dispatch({
      // 	type: SET_SEARCH,
      // 	payload: {
      // 		search: searchValue,
      // 	},
      // });
      console.log('busqueda Opcion Busqueda: ', searchValue);
      setSearch(searchValue);
      // let products = getState().productsCollection.products.slice();
      // if (products.length === 0) {
      // 	products = JSON.parse(localStorage.getItem("products")) || "[]";
      // }

      // const sort = getState().productsCollection.sort;
      const sort = 'bestsellers';

        // Función para eliminar tildes de una cadena
        const normalizeSearchValue = searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
        // Lista de artículos comunes en español
        const articles = ['de', 'el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas', 'al', 'del', 'lo', 'le', 'a'];
        
        // Función para eliminar artículos de un array de palabras
        const filterArticles = (words) => {
          return words.filter(word => !articles.includes(word.toLowerCase()));
        };

        // Dividir el término de búsqueda, eliminar tildes y filtrar artículos
        const searchTerms = filterArticles(
          (normalizeSearchValue)
              .split(' ')
              .map(term => term.trim().toLowerCase())
              .filter(term => term !== '') // Excluir términos vacíos
        );
        console.log('texto a buscar: ', searchTerms);
        
  const keywordMatches = products.filter(el =>
    searchTerms.every(term =>
      //el.palabrasclave.toLowerCase().includes(term.trim()) ||
      (el.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "") + ' ' + el.palabrasclave).toLowerCase().includes(term.trim()) 
    )
    && el.stockAmount !== 0
  );

  // Filtrar por otros campos, excluyendo los que ya están en keywordMatches
  const otherMatches = products.filter(el =>
    !keywordMatches.includes(el) && // Evita duplicados ya presentes en keywordMatches
    searchTerms.every(term =>
      (el.marca.toLowerCase() + ' ' +  el.departamento.toLowerCase() + ' ' +  el.categoria.toLowerCase() + ' ' + el.subcategoria.toLowerCase()).includes(term.trim())
    )
    && el.stockAmount !== 0
  );
  keywordMatches.sort((a, b) => a.code.localeCompare(b.name));
  otherMatches.sort((a, b) => a.code.localeCompare(b.name));

  // Combinar resultados, dando prioridad a las coincidencias por palabras clave
  const result = [
    ...keywordMatches,
    ...otherMatches
  ];
  console.log('palabra clave: ', keywordMatches.length);
  console.log('otros: ', otherMatches.length);
  console.log('Total: ', result.length);
  console.log(keywordMatches);
  console.log(otherMatches);
      
      // Combinar resultados, dando prioridad a las coincidencias por palabras clave
      let filteredProducts = [
        ...keywordMatches,
        ...otherMatches
      ];

      let maxPrice = 0;
      let minPrice = 0;
      if (filteredProducts.length > 0) {
        maxPrice = filteredProducts.reduce(function (prev, current) {
          return prev.salePrice > current.salePrice ? prev : current;
        }).salePrice;
        minPrice = filteredProducts.reduce(function (prev, current) {
          return prev.salePrice < current.salePrice ? prev : current;
        }).salePrice;

        // if (sort === 'bestsellers') {
        //   filteredProducts = filteredProducts.filter((item) => item.isBestSeller === true).concat(filteredProducts.filter((item) => item.isBestSeller === false));
        // } else {
        //   filteredProducts.sort((a, b) => (sort === 'lowest' ? (a.salePrice > b.salePrice ? 1 : -1) : sort === 'highest' ? (a.salePrice > b.salePrice ? -1 : 1) : a.rentability > b.rentability ? -1 : 1));
        // }
      }

      // dispatch({
      // 	type: FILTER_PRODUCTS_BY_NAME,
      // 	payload: {
      // 		filteredProducts: filteredProducts,
      // 		minPrice: minPrice,
      // 		maxPrice: maxPrice,
      // 	},
      // });
      // localStorage.setItem(
      // 	"filteredProducts",
      // 	JSON.stringify(filteredProducts)
      // );
      setFilteredProducts(filteredProducts);
      setSearchProducts(filteredProducts)
      console.log('llego: ', filteredProducts);
      return filteredProducts;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    // if (e.target.value !== '') {
    //   let productos = products;
    //   const result = productos.filter((el) => el.name.toUpperCase().includes(e.target.value.toUpperCase()) && el.stockAmount !== 0);
    //   setPredictionProducts(result);
    // } else {
    //   setPredictionProducts([]);
    // }
  };

  const startSearch = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (searchValue !== '') {
        e.target.blur();
        // document.getElementById('input2').focus();
        console.log('enter1:'+searchValue);
        searchProductsByName(searchValue);
        setPredictionProducts([]);
        setSearchValue('');
        setShowSearch(false)  
        navigate('/tienda/busqueda');
      }
      // setSearchValue("");
    }
  };

  const handleCloseSuggestion = () => {
    setPredictionProducts([]);
    setSearchValue('');
    setShowSearch(false);
  };

  const handleClickSuggestion = (value) => {
    // searchProductsByName(value);
    console.log('valor:'+value);
    setShowSearch(false)
    setSearchValue('');
    setPredictionProducts([]);
    navigate(`/tienda/producto/${value}`);
  };

  const handleDropMenu = (category) => {
    setSubMenu(category);
  };

  const validateTagExist = (tag) => {
    return tags.some((item) => item === tag);
  };

  const handleList = (e) => {
    // const newValue = e.target.value;

    // if (e.target.value.length < 2) return;
    // if (newValue.slice(-1) === ',' && !validateTagExist(e.target.value) && !e.target.value.length === 0) {
    //   setTags((state) => [...state, e.target.value.slice(0, -1)]);
    //   setSearchList('');
    //   return;
    // }
    setSearchList(e.target.value);
  };

  const handleEnterTag = (e) => {
    // if (e.target.value.length < 2) return;
    if (e.keyCode === 13 || e.keyCode === 188 || e.keyCode === 32) {
      let newValue = e.target.value;

      if (e.keyCode === 188) {
        newValue = e.target.value.slice(0, -1);
      }
      if (!validateTagExist(newValue)) {
        setTags((state) => [...state, newValue]);
        setSearchList('');
      }
    }
  };

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((item) => item !== tag);
    setTags(newTags);
  };

  const handleListSearchDesktop = () => {
    if (tabSearch === 'list-search-desktop') return setTabSearch(null);
    setTabSearch('list-search-desktop');
  };

  const handleRemoveAllTags = () => {
    setTags([]);
  };

  const getDeliveryAdress = () => {
    
    //console.log('get direccion2: ', user);
    //const index = user.deliveryAddresses.findIndex(address => address.addressId === user.addressId)
    // Busca en la lista deliveryAddresses el elemento que coincida con el addressId proporcionado
    user.addressId = user.addressId ?? user.deliveryAddresses[0].addressId;
    const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
    //console.log('get direccion22: ', foundAddress);
    if (foundAddress != null)
    {
      user.address = foundAddress.address;
      user.addressId = foundAddress.addressId;
      return foundAddress.address;
    }
    else
    {
      user.address = user.deliveryAddresses[0].address;
      user.addressId = user.deliveryAddresses[0].addressId;
      return user.address;
    }
    //console.log('get direccion2: ', user);
    
    //if (index === -1) return user.deliveryAddresses[0].shortAddress
    //return user.deliveryAddresses[index].shortAddress
    
  }

  const handleChangeAddress = () => {
    console.log('change direccion: ', addressActive );
    const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === addressActive);
    user.address = foundAddress.address;
    user.addressId = foundAddress.addressId;
    user.priceListId = foundAddress.priceListId;
    user.dayToDelivery = foundAddress.dayToDelivery;
    user.dayOfWeek = foundAddress.dayOfWeek;
    console.log('change direccion: ', user.address );
    setSearchValue('');
    console.log('get direccion3: ', user);
    changeAddress(addressActive);
    setShowAddressModal(false);
    getProducts(user);
  }

  const handleCloseAddressModal = () => {
    setAddressActive(user.addressId)
    setShowAddressModal(false)
  }

     // Referencia al elemento de entrada
 const inputRef = useRef(null);

  const handleShowSearch = () => {

    const chatBot = document.querySelector('#xenioo-chat-widget')

    if (chatBot) {
      chatBot.style.display = showSearch ? 'block' : 'none';
    }

    setShowSearch((state) => !state)
    //document.querySelector('.eudistest')?.focus();

    // Enfocar el input cuando se hace clic en el botón
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500); // 1000 milisegundos = 1 segundo
  }

  const handleClickHome = () => {
    setShowNavMenu(false)
    navigate('/tienda')
  }




  useEffect(() => {
    console.log('cate: ', categories);
    if (categories.length === 0) getCategories(products);
  }, []);

  useEffect(() => {
    if (showMenu) {
      // document.getElementsByTagName('BODY')[0].style = 'overflow-x: hidden';
    } else {
      // document.getElementsByTagName('BODY')[0].removeAttribute('style');
      // document.getElementsByTagName('BODY')[0].style = 'overflow-x: hidden';
    }
  }, [showMenu]);

  useEffect(() => {
    // const timer = setTimeout(() => setSearchTerm(searchValue), 500);
    clearTimeout(timer.current)
    timer.current = setTimeout(() => setSearchTerm(searchValue), 500);

    return () => {
      return () => clearTimeout(timer.current);
    };
  }, [searchValue]);

  useEffect(() => {
    if (searchTerm !== '') {
      // Función para eliminar tildes de una cadena
      const normalizeSearchValue = searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
      // Lista de artículos comunes en español
      const articles = ['de', 'el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas', 'al', 'del', 'lo', 'le', 'a'];
      
      // Función para eliminar artículos de un array de palabras
      const filterArticles = (words) => {
        return words.filter(word => !articles.includes(word.toLowerCase()));
      };

      // Dividir el término de búsqueda, eliminar tildes y filtrar artículos
      const searchTerms = filterArticles(
        (normalizeSearchValue)
            .split(' ')
            .map(term => term.trim().toLowerCase())
            .filter(term => term !== '') // Excluir términos vacíos
      );
      console.log('texto a buscar: ', searchTerms);
      
      const keywordMatches = products.filter(el =>
        el.name && el.name.trim() !== '' && el.palabrasclave.trim() !== ''&& // Verifica que el.name no sea nulo o vacío
        searchTerms.every(term =>
          (el.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "") + ' ' + el.palabrasclave)
            .toLowerCase()
            .includes(term.trim())
        ) &&
        el.stockAmount !== 0
      );

// Filtrar por otros campos, excluyendo los que ya están en keywordMatches
const otherMatches = products.filter(el =>
  el.name && el.name.trim() !== '' && el.palabrasclave.trim() !== ''&& 
  el.marca && el.name.trim() !== '' && el.departamento.trim() !== ''&& 
  el.categoria && el.name.trim() !== '' && el.subcategoria.trim() !== ''&& 
  !keywordMatches.includes(el) && // Evita duplicados ya presentes en keywordMatches
  searchTerms.every(term =>
    (el.marca.toLowerCase() + ' ' +  el.departamento.toLowerCase() + ' ' +  el.categoria.toLowerCase() + ' ' + el.subcategoria.toLowerCase()).includes(term.trim())
  )
  && el.stockAmount !== 0
);
keywordMatches.sort((a, b) => a.code.localeCompare(b.name));
otherMatches.sort((a, b) => a.code.localeCompare(b.name));

// Combinar resultados, dando prioridad a las coincidencias por palabras clave
const result = [
  ...keywordMatches,
  ...otherMatches
];
console.log('palabra clave: ', keywordMatches.length);
console.log('otros: ', otherMatches.length);
console.log('Total: ', result.length);
console.log(keywordMatches);
console.log(otherMatches);

  // Filtrar los productos

    setPredictionProducts(result);
    } else {
      setPredictionProducts([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    let totalItems = 0;
    if (chart.products.length === 0) {
      chart.products = JSON.parse(localStorage.getItem('chartProducts') || '[]');
    }
    for (let i = 0; i < chart.products.length; i++) {
      const element = chart.products[i];
      totalItems = totalItems + element.amount;
    }
    setTotalItemsChart(totalItems);
  }, [chart]);

  const [isFocused, setIsFocused] = useState(false);
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER || '+51908824814'; // Valor por defecto
  const vedoraEnvironment = process.env.REACT_APP_VEDORA_ENVIRONMENT || ''; // Valor por defecto

  return (
    <header className="headerL__container pinned">
      <div className="headerL">
      
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img style={{height: '40px', width: '200px'}} src={HomeIcon} alt="" onClick={() => navigate('/tienda')} />
          {/* <IoMdHome size={37} color={'white'}/> */}
        </Box>
        
        
        <Box className={`headerL__search ${showSearch ? 'headerL__search__show' : ''}`} sx={{ maxWidth: '895px' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
            {/* //Busca Wev */}
            <Input placeholder="¿Qué estás buscando?" value={searchValue} 
            onChange={handleChange} 
            onKeyUp={(e) => startSearch(e)} 
            onFocus={() => setIsFocused(true)}
            onBlur={() => {setTimeout(() => setIsFocused(false), 300);}}
            
            sx={{
              fontSize: '14px', border: '1px #999999 solid', lineHeight: '20px',
              borderBottomLeftRadius: '32px', borderTopLeftRadius: '32px',
              padding: '2px 0px 3px 16px', width: '100%'
            }}/>            
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5,
              borderBottomRightRadius: 32, borderTopRightRadius: 32, width: 36, height: 36, padding: '8px', margin:0,
              backgroundColor: 'var(--primary-color)'
             }} />
          </Box>

          {searchValue !== '' && <FaTimes className="headerL__search--icon__close" onClick={handleCloseSuggestion} />}
          {predictionProducts.length > 0 && isFocused ? (
            <div className="headerL__prediction">
              {predictionProducts
                .map((el, i) => (
                  <SearchItem key={el.code} el={el} handleClickSuggestion={handleClickSuggestion} />
                ))}
            </div>
          ) :
            <div className="headerL__prediction">
              {searchValue == '' && isFocused && 
                categories.map((el, i) => (
                  el.name && el.name.trim() !== '' && (
                    <li className="menu__item" key={i}>
                      <NavLink className="menu__link" to={`/tienda/${el.name.split(' ').join('-')}/home`}>
                        {toTitleCase(el.name)}
                      </NavLink>
                    </li>
                  )
                ))
              }
            </div>
        }
        </Box>
       
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} onClick={() => setShowNavMenu((state) => !state)} justifyContent="center" alignItems="center">
          <IoMdMenu size={32} color="#999999" />
        </Box>
        <div className="headerL__logo">
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <img className="headerL__logo--img" src={HomeIcon} alt="" onClick={() => navigate('/tienda')} />
            {/* <IoMdHome size={37} color={'white'}/> */}
          </Box>
        </div>
        <Box className="headerL__icons" sx={{ gap: { xs: '33px', md: '0px' } }}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }} >
            {/* <FaSearch className="headerL__logo--search" size={26} onClick={() => setShowSearch((state) => !state)} /> */}
            {/* <Box component={<SearchIcon/>}  onClick={() => handleShowSearch()} />            */}
            <SearchIcon 
  className={style.searchIcon} 
  sx={{ 
    width: '36px', 
    height: '36px',
    textShadow: '0 1px 2px rgba(0,0,0,0.5)', // Añade sombra para un efecto de grosor
    border: '2px solid gray', // Añade borde para un efecto de grosor
    borderRadius: '50%' // Asegura que el borde sea redondeado si el ícono es circular
    , transition: 'transform 0.3s ease', // Transición para un cambio de tamaño suave
    boxShadow: '0 0 8px rgba(255, 215, 0, 0.5)', // Resplandor constante

    '&:hover': {
      transform: 'scale(1.1)', // Aumenta ligeramente el tamaño al pasar el mouse
      animation: 'flash 1s ease-in-out infinite', // Activa la animación de destello
    },

    '@keyframes flash': {
      '0%': { boxShadow: '0 0 8px rgba(255, 215, 0, 0.5)' },
      '50%': { boxShadow: '0 0 16px rgba(255, 215, 0, 1)' }, // Brillo máximo en el punto medio
      '100%': { boxShadow: '0 0 8px rgba(255, 215, 0, 0.5)' },
    },
  }}
  alt="Icono Carrito" 
  onClick={() => handleShowSearch()} 
/>
            {/* <FaSearch color='white' size={24}/> */}
          </Box>

          <Box className="headerL__icons--item" sx={{ display: { xs: 'none', md: 'block' }, position: 'relative' }}>
            {/* <FaClipboardList size={28} /> */}
            {/* <Box component={'img'} src={BuscadorLista} alt="Buscador Lista" onClick={handleListSearchDesktop} /> */}
            <Dialog PaperProps={{ sx: { position: 'absolute', left: 'calc(100vw - 900px)', top: '40px' } }} open={tabSearch === 'list-search-desktop'} onClose={() => setTabSearch(null)}>
              <Box
                tabIndex="0"
                sx={{ display: { xs: 'none', md: 'flex' }, width: '486px', height: '436px', backgroundColor: '#F4F4F4', padding: '40px' }}
                flexDirection="column"
                // alignItems="center"
                gap={2}
              >
                <Typography sx={{ fontSize: '21px', color: '#000000', fontWeight: 700, textAlign: 'left' }}>Escribe aquí tu lista de compras</Typography>
                <Typography sx={{ fontSize: '18px', color: '#000000', textAlign: 'left', fontWeight: 'normal', letterSpacing: '0' }}>Toca Enter después de cada item o sepáralos por comas (ej.: arroz, carne, huevos, azúcar).</Typography>
                <Box sx={{ height: '200px', width: '100%', display: 'inline-block', position: 'relative' }}>
                  <Box sx={{ height: '200px', width: '100%', display: 'inline-block', backgroundImage: `url(${FondoBusqueda})`, backgroundPosition: 'center', position: 'absolute', zIndex: '1' }} />
                  <Box sx={{ maxWidth: '100%', display: 'inline', zIndex: '10000000000000000' }}>
                    {tags.length > 0 &&
                      tags.map((tag, index) => (
                        <button
                          key={index}
                          className="fadeIn"
                          onClick={() => handleRemoveTag(tag)}
                          style={{ backgroundColor: '#21AEE0', padding: '7px 10px', fontSize: '13px', color: 'white', fontWeight: 600, borderRadius: '5px', marginRight: '8px', marginBottom: '8px' }}
                        >
                          {tag} x
                        </button>
                      ))}
                  </Box>
                  <input autoFocus disabled type="text" style={{ border: 'none', zIndex: '3', outline: 'none', background: 'transparent', '&:active': { border: 'none' } }} onKeyUp={handleEnterTag} value={searchList} onChange={handleList} />
                </Box>
                <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
                  <Button variant="contained" color="error" disabled fullWidth sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '20px', height: '60px', backgroundColor: 'rgba(255,0,0,0.5)!important', color: 'white!important' }}>
                    Próximamente
                  </Button>
                  {/* <Button variant="contained" sx={{ backgroundColor: '#21AEE0', fontWeight: 700, fontSize: '18px', width: '200px', height: '60px', lineHeight: '20px' }}>
                    Buscar Productos
                  </Button>
                  <Button onClick={handleRemoveAllTags} variant="outlined" sx={{ fontWeight: 700, fontSize: '18px', width: '200px', height: '60px', outline: 'none', border: 'none', color: '#707070', '&:hover': { border: 'none', outline: 'none' } }}>
                    Borrar Todo
                  </Button> */}
                </Box>
              </Box>
            </Dialog>
          </Box>

          {/* <Box className="headerL__icons--item header__categories" sx={{ display: { xs: 'none', md: 'block' } }}>
            <FaAlignRight
              onClick={() => {
                // alert('?');
                setShowMenu((state) => !state);
              }}
            />
             <ul className={`menu ${showMenu ? 'menu__show' : ''}`} ref={menuRef}>
              {categories.length > 0 &&
                categories.map((el, i) => (
                  <li className="menu__item" key={i}>
                    <NavLink className="menu__link" to={`/tienda/${el.name.split(' ').join('-')}/home`}>
                      {toTitleCase(el.name)}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </Box> */}

          <Box sx={{ display: { xs: 'none', md: 'block' }, cursor: 'pointer' }} onClick={() => navigate('/perfil')}>
            {/* <FaUser onClick={() => navigate('/perfil')} size={28} /> */}
            <Button variant="contained"  sx={{ textTransform: 'none', ":hover": {
                  backgroundColor: 'var(--primary-color)',
                },
                  width: '128px', height: '36px', 
                  backgroundColor: 'var(--primary-color)', borderRadius:32, padding: '6px'
                }} startIcon={<PersonOutlineOutlinedIcon />}>
              Mi cuenta
            </Button>
           
          </Box>
          <div className="headerL__icons--item__cart" onClick={() => navigate('/carrito')}>
            {/* <FaShoppingCart onClick={() => navigate('/carrito')} size={28} /> */}
            {/* <Box component={'img'} src={CarritoIcon} sx={{ width: { xs: '38px', md: '100%' } }} alt="Icono Carrito" /> */}
            <ShoppingCartOutlinedIcon sx={{ width: '36px', height: '36px' }} alt="Icono Carrito" />

            {/* <RiTruckFill size={40} color="white"/> */}

            {/* {totalItemsChart !== 0 && <p>{chart.products.reduce((total, currVal) => (total += currVal.amount), 0)}</p>} */}
            {totalItemsChart !== 0 && <p>{chart.products.length}</p>}
          </div>
          <Box className="headerL__ubicacion" sx={{ display: { xs: 'none !important', md: 'flex !important' }, flexDirection: 'column', width: '180px' }} alignItems="center">
            <Typography color="#999999" sx={{ fontSize: '14px', marginLeft: '44px', alignSelf: 'start', fontWeight: 400, marginBottom: '-10px' }}>
              Enviar a:
            </Typography>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', width: '100%', marginTop: '-12px' }} gap={1} onClick={() => setShowAddressModal(true)}>
              <LocalShippingOutlinedIcon 
                sx={{ 
                  width: '36px', height: '36px', 
                  backgroundColor: 'var(--primary-color)', color: '#FDFDFD', borderRadius:32, padding: '6px'
                }}
              alt="Icono Carrito" />
              <p style={{ marginBottom: '-15px', fontWeight: 700, fontSize: '14px', inlineSize: '310px', color: 'var(--primary-color)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{getDeliveryAdress()}</p>
            </Box>
          </Box>
        </Box>

        {/* Search Mobile */}
        {showSearch && (
          <Box sx={{ maxHeight: '115px', width: '100%', display: { xs: 'flex', md: 'none' }, position: 'absolute', zIndex: 100, top: '60px', left: 0 }} flexDirection="column" className="fadeIn">


            <Box
              className="headerL__search-mobile"
              sx={{
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '0px',
                  left: `${tabSearch === 'normal-search' ? '0px' : '50%'}`,
                  width: '50%'

                },
              }}>
              {
                <Box className="Box__search" >
                  <input className="headerL__search--input eudistest"  ref={inputRef} placeholder="¿Qué estás buscando?" 
                  onChange={handleChange} 
                  value={searchValue} 
                  onKeyUp={(e) => startSearch(e)} 
                  // onBlur={handleShowSearch} 
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {setTimeout(() => setIsFocused(false), 500);}}
                  />
                  {<FaTimes className="headerL__search--icon__close" onClick={handleCloseSuggestion} />}
                  {predictionProducts.length > 0 ? (
                    <div className="headerL__prediction">
                      {predictionProducts
                        .map((el, i) => (
                          <SearchItem key={el.code} el={el} handleClickSuggestion={handleClickSuggestion} />
                        ))}
                    </div>
                  ) :
                    <div className="headerL__prediction">
                      {searchValue == '' && isFocused && 
                        categories.map((el, i) => (
                          el.name && el.name.trim() !== '' && (
                            <li className="menu__item" key={i}>
                              <NavLink className="menu__link" to={`/tienda/${el.name.split(' ').join('-')}/home`}>
                                {toTitleCase(el.name)}
                              </NavLink>
                            </li>
                          )
                        ))
                      }
                    </div>
                }
                </Box>
              }
            </Box>
          </Box>
        )}

        {showNavMenu && (
          <Box sx={{ height: 'calc(100vh - 60px)', overflowY: 'auto', width: '100%', backgroundColor: '#fff', display: { xs: 'flex', md: 'none' }, position: 'absolute', zIndex: 200, top: '60px', left: 0 }} gap={4} flexDirection="column" className="fadeIn">
            <Box display="flex" flexDirection="column">
              <Box className="menu__nav-item" gap={2} onClick={() => navigate('/perfil')}>
                <FaUser size={16} color={'#000000'} />
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Mi perfil</Typography>
              </Box>
              <Box className="menu__nav-item" gap={2} onClick={() => navigate('/tienda/promociones')}>
                <FaBullhorn size={16} color={'#000000'} />
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Promociones</Typography>
              </Box>
              <Box className="menu__nav-item" gap={2} onClick={() => navigate('/tienda/marcas')}>
                <FaMedal size={16} color={'#000000'} />
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Marcas1</Typography>
              </Box>
              <Box className="menu__nav-item" gap={2} onClick={() => navigate('/tienda/categories')}>
                <FaSearch size={16} color={'#000000'} />
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Categorias</Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" component={'ul'} gap={2} justifyContent="center" alignItems="center" sx={{ marginBottom: '20px' }}>
              <Typography sx={{ fontSize: '14px', color: '#000000' }}>Politicas de privacidad</Typography>
              <Typography sx={{ fontSize: '14px', color: '#000000' }}>Libro de reclamaciones</Typography>
              <Link to="/faq">
                <Typography sx={{ fontSize: '14px', color: '#000000' }}>Preguntas frecuentes</Typography>
              </Link>
              <Link to='/experiencia'>
                <Typography sx={{ fontSize: '14px', color: '#000000' }}>Ayudanos a mejorar</Typography>
              </Link>
              <Typography sx={{ fontSize: '14px', color: '#000000' }}>¿Como comprar?</Typography>
              <Button variant="contained" sx={{ backgroundColor: '#14B928', paddingX: '30px', textTransform: 'none', marginTop: '18px' }} startIcon={<IoLogoWhatsapp size={25} color={'white'} />} onClick={() => window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`Hola VedoraPlus ${vedoraEnvironment}`)}`, '_blank')}>
                Ir a WhatsApp
              </Button>
              <Box display="flex" justifyContent="center" alignItems="center" gap={6} sx={{ marginTop: '30px' }}>
                <FaFacebookF size={25} color="#000000" />
                <FaInstagram size={25} color="#000000" />
              </Box>
              <Typography sx={{ fontSize: '25px', color: '#000000', marginTop: '30px' }}>VedoraPlus</Typography>
            </Box>
          </Box>
        )}
      </div>
      <Box sx={{ borderBottom: '1px solid white' }} />
      <Box className="subheaderL" display="flex" justifyContent="space-between" alignItems="center" 
        sx={{ display: { xs: 'none', md: 'flex', backgroundColor: 'var(--primary-50-color)' } }}>
        <Box
          className="header__categories"
          sx={{ marginLeft: 2, paddingX: 2 }}
          onBlur={() => {
            setSubMenu(null);
            setShowMenu(false);
          }}
          tabIndex="0"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            gap={2}
            onClick={() => {
              if (showMenu) {
                setSubMenu(null);
              }
              setShowMenu((state) => !state);
            }}
          >
            <Typography color="var(--primary-color)" fontSize="22px">
              Categorias
            </Typography>
            <FaAngleDown color="var(--primary-color)" />
          </Box>

          <ul className={`menu ${showMenu ? 'menu__show' : ''}`} ref={menuRef}>
            {categories.length > 0 &&
              categories.map((el, i) => (
                el.name && (
                  <li className="menu__item" key={i} onMouseOver={() => handleDropMenu(el.name)}>
                    <NavLink className="menu__link" to={`/tienda/${el.name.split(' ').join('-')}/home`}>
                      {toTitleCase(el.name)}
                    </NavLink>
                  </li>
                )
              ))
            }
            {/* {subMenu && showMenu && (
              <Box sx={{ maxWidth: 'calc(1440px - 300px)', width: 'calc(100vw - 360px)', maxHeight: 'calc(100vh - 200px)', backgroundColor: 'white', position: 'absolute', left: 300, top: 0, padding: '50px 0px' }}>
                <Typography sx={{ fontSize: '35px', fontWeight: 'bold', paddingX: '100px' }} color={'#000000'}>
                  Licores
                </Typography>
                <Grid container rowSpacing={2} columnSpacing={20} sx={{ marginTop: '20px', maxHeight: 'calc(100vh - 450px)', overflowY: 'auto', paddingX: '100px' }}>
                  <Grid item sm md>
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} color={'#000000'}>
                        Vino
                      </Typography>
                      <Box component={'ul'}>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm md>
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} color={'#000000'}>
                        Ron
                      </Typography>
                      <Box component={'ul'}>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm md>
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} color={'#000000'}>
                        Whisky
                      </Typography>
                      <Box component={'ul'}>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm md>
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} color={'#000000'}>
                        Vodka
                      </Typography>
                      <Box component={'ul'}>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm md>
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} color={'#000000'}>
                        Destilados
                      </Typography>
                      <Box component={'ul'}>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Alegoria</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Black Tower</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Crossbarn</a>
                        </Box>
                        <Box component={'li'} sx={{ fontSize: '20px', color: '#000000' }}>
                          <a href="#">Errazuriz</a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )} */}
          </ul>
        </Box>

        <Box flex={1} />
        <Box component="ul" display="flex" justifyContent="space-between" 
           alignItems="center" gap={'86px'} sx={{ marginRight: 5, backgroundColor: 'var(--primary-50-color)' }}>
          <li style={{ color: 'var(--primary-color)', fontSize: '22px' }}>
            <NavLink to="/tienda" >Inicio</NavLink>
          </li>
          <li style={{ color: 'var(--primary-color)', fontSize: '22px' }}>
            <NavLink to="/tienda/promociones">Promociones</NavLink>
          </li>
          <li style={{ color: 'var(--primary-color)', fontSize: '22px' }}>
            <NavLink to="/tienda/marcas">Marcas</NavLink>
          </li>
          <a href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`Hola VedoraPlus ${vedoraEnvironment}`)}`} target="_blank">
            <Box display="flex" component={'li'} sx={{ color: 'var(--primary-color)', fontSize: '22px' }} flexDirection={'column'}>
              <Box display="flex" alignItems="center" gap="10px">
                <IoLogoWhatsapp size={15} color={'var(--primary-color)'} />
                <Typography sx={{ fontSize: '15px', fontWeight: 700 }}>Ir a WhatsApp</Typography>
              </Box>
              <Typography sx={{ fontSize: '20px' }}>{whatsappNumber}</Typography>
            </Box>
          </a>
        </Box>
      </Box>

      <Box className="subheaderL" sx={{ display: { xs: 'flex', md: 'none' } }} justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1} sx={{ marginLeft: '20px' }}>
          <LocalShippingOutlinedIcon style={{ fontSize: 20, color: 'var(--primary-color)', width: '28px', height: '28px' }} />
          <Typography sx={{ fontWeight: 900, fontSize: '14px', color: 'var(--primary-color)', inlineSize: '230px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>Enviar a: {getDeliveryAdress()}</Typography>
        </Box>
        <Button variant="contained" style={{ backgroundColor: 'var(--primary-color)', borderRadius: 32, fontSize: 14, fontWeight: 700, textTransform: 'none', color: '#FDFDFD', marginRight: '20px', height: 28, width: 86, }} onClick={() => setShowAddressModal(true)}>
          Cambiar
        </Button>
      </Box>

      {/* Modal de direcciones */}
      <Dialog
        open={showAddressModal}
        onClose={handleCloseAddressModal}
        maxWidth="xl"
        PaperProps={{
          sx: {
            position: { xs: 'absolute', md: 'relative' },
            minWidth: { xs: '100%', md: '998px' },
            top: '30px',
            height: { xs: '100vh', md: '585px' },
          },
        }}
      >
        <Box sx={{ padding: { xs: '80px 40px', md: '20px 30px' } }}>
          <DialogTitle id="alert-dialog-title" sx={{ color: { xs: '#000000', md: '#21AEE0' }, fontWeight: { xs: 900, md: 600 }, textAlign: 'center', fontSize: { xs: '20px', md: '30px' } }}>
            ¡Elige tu dirección!
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ margin: { xs: '10px auto 0 auto', md: '0 auto' }, fontWeight: { xs: 900, md: 'normal' }, maxWidth: { xs: '270px', md: '370px' }, color: '#000000', textAlign: 'center', letterSpacing: '0px', fontSize: { xs: '15px', md: '20px' } }}
            >
              Elige la dirección a la cual quieres enviar el pedido que vas a realizar
            </DialogContentText>
          </DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: { xs: '350px', md: '200px' }, padding: { xs: 0, md: '0 20px' }, overflowY: 'auto', marginTop: { xs: '30px', md: '50px' } }} justifyContent="center" gap={{ xs: 1, md: 2 }}>
            {user.deliveryAddresses.map(address => (
              <Box
                className={`ubication__item ${addressActive === address.addressId  && 'ubication__item-active'} `}
                sx={{
                  cursor: 'pointer',
                  width: { xs: '100%', md: '276px' },
                  display: 'flex',
                  justifyContent: { xs: 'start', md: 'center' },
                  padding: { xs: '0 25px', md: '0' },
                  gap: { xs: '25px', md: '20px' },
                  flexDirection: { xs: 'row', md: 'column' },
                  alignItems: 'center',
                  height: { xs: '91px', md: '193px' },
                  border: '1px solid #297ABA',
                }}
                key={address.addressId}
                onClick={() => setAddressActive(address.addressId)}
              >
                {/* <IoStorefront size={36} color={`${addressActive === 1 ? 'white' : '#000000'}`} /> */}
                <Box component={'img'} sx={{ width: { xs: '27px', md: 'auto' } }} src={`${addressActive === address.addressId ? DireccionIcon : DireccionActivaIcon}`} />
                <Box display="flex" flexDirection={'column'} sx={{ alignItems: { xs: 'start', md: 'center' }, paddingX: '8px' }}>
                  <p className={`ubication__address ${addressActive === address.addressId && 'ubication__address-active'}`}>{address.address}</p>
                  <p className={`ubication__distrito ${addressActive === address.addressId && 'ubication__distrito-active'}`}>{address.lineOfBusiness}</p>
                </Box>
              </Box>
            ))}
          </Box>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: 3, md: '70px' } }}>
            <Button
              sx={{
                padding: '0',
                backgroundColor: '#21AEE0',
                width: { xs: '200px', md: '276px' },
                height: { xs: '58px', md: '68px' },
                textTransform: { xs: 'uppercase', md: 'capitalize' },
                borderRadius: '5px',
                fontSize: { xs: '15px', md: '21px' },
                fontWeight: 900,
              }}
              onClick={() => handleChangeAddress()}
              variant="contained"
            >
              Seleccionar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </header>
  );
};

export default UserHeader;
