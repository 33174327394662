import { AppBar, Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useUserAuth } from '../context/UserAuthContext';
import { toTitleCase, a11yProps } from '../utils';
import theme from '../utils/ThemeConfig';

const promotions = [
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
  {
    promoDescription: 'Por 24 und Clorandina lavavjilla limón gratis 1 und Clorandina limpiatodo 310ml',
    promoTitle: 'Clorandina Lava Limon 180gr',
    discountPrice: 1.1,
    price: 1.27,
  },
];

export const UserPromotionForYou = () => {

  const { user } = useUserAuth()

  return (
    <div className="l-container-carrousel" style={{ marginTop: '20px' }}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="end" sx={{ padding: { xs: '0px 20px', md: '0px 35px' }, marginBottom: '0px' }}>
          <Box>
            <Typography sx={{ fontSize: { xs: '18px', md: '35px', fontWeight: 900 }, color: '#000000' }}>¡Hola {user.shortName ? ` @${user.shortName}` : ''}!</Typography>
            {/* <Typography sx={{ fontSize: { xs: '18px', md: '35px', fontWeight: 900 }, color: '#2D85C5' }}>Promociones especiales para ti</Typography> */}
          </Box>
          {/* <Typography sx={{ fontSize: { xs: '15px', md: '22px' }, color: '#2D85C5' }}>Ver todo</Typography> */}
        </Box>

        {/* <Box width="100%" display="flex" justifyContent="center">
          <Box
            mt={0}
            sx={{
              // paddingLeft: { xs: '15px'},
              [theme.breakpoints.up('md')]: {
                width: '100%',
              },
              width: '100%',
              // background: 'yellowgreen',
            }}
          >
            <AppBar
              sx={{
                boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
                '& span': {
                  height: '0px',
                  fontSize: '12px',
                  color: theme.palette.secondary.main,
                },
                '& svg': {
                  color: theme.palette.secondary.main,
                },
                backgroundColor: 'white',
              }}
              position="static"
              color="default"
            >
              <Tabs value={false} variant="scrollable" scrollButtons sx={{ paddingLeft: { xs: '20px', md: 0 } }}>
                {promotions.map((item, index) => (
                  <Tab
                    onClick={() => {}}
                    key={index}
                    label={
                      <Box sx={{ marginTop: { xs: '10px', md: '15px' }, width: '100%' }} display="flex" flexDirection={'column'}>
                        <Box display="flex" justifyContent="start" alignItems="center" gap={4} sx={{ marginLeft: '15px' }}>
                          <Typography sx={{ color: '#2D85C5', fontWeight: 900, fontSize: { xs: '17px', md: '28px' } }}>S./{item.discountPrice.toFixed(1)}</Typography>
                          <Typography sx={{ textDecoration: 'line-through', color: '#707070', fontSize: { xs: '12px', md: '17px' } }}>S./{item.price.toFixed(1)}</Typography>
                        </Box>
                        <Typography sx={{ fontSize: { xs: '13px', md: '19px' }, color: '#2D85C5', textAlign: 'left', marginLeft: '15px', fontWeight: 600 }}>{item.promoTitle}</Typography>
                        <Button fullWidth variant="contained" sx={{ backgroundColor: '#21AEE0', height: { xs: '36px', md: '56px' }, fontWeight: 900, fontSize: { xs: '13px', md: '18px' }, marginTop: { xs: '12px', md: '20px' } }}>
                          Añadir al carrito
                        </Button>
                      </Box>
                    }
                    icon={
                      <Box
                        // component="img"
                        // src={'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/icons/' + item.name + '.png'}
                        // alt={'Imagen no disponible'}
                        // onError={(e) => {
                        //   e.target.onerror = null;
                        //   e.target.src = 'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/icons/Logo_E_azul.png';
                        // }}
                        display="flex"
                        flexDirection="column"
                        sx={{
                          width: '100%',
                          backgroundColor: 'white',
                          padding: { xs: '7px 6px 6px 6px', md: '17px 15px 15px 16px' },
                          height: { xs: '169px', md: '248px' },
                          marginBottom: 0,
                          borderRadius: '5px',
                        }}
                      >
                        <Box
                          component={'img'}
                          src={'https://www.casadefuego.pe/wp-content/uploads/2020/06/FOSFOROS-PARRILLEROS.jpg'}
                          sx={{
                            maxWidth: '100%',
                            backgroundColor: 'white',
                            margin:"0 auto",
                            height: { xs: '84px', md: '115px' },
                          }}
                        />
                        <Typography sx={{ color: '#2D85C5', fontSize: { xs: '15px', md: '22px' }, fontWeight: 900, textAlign: 'center',letterSpacing:'0px' }}>{item.promoDescription}</Typography>
                      </Box>
                    }
                    {...a11yProps(index)}
                    sx={{
                      backgroundColor: '#F4F4F4',
                      justifyContent: 'start',
                      padding: { xs: '11px 10px', md: '25px 24px' },
                      width: { xs: '278px', md: '427px' },
                      minWidth: { xs: '278px', md: '427px' },
                      height: { xs: '298px', md: '459px' },
                      minHeight: { xs: '298px', md: '459px' },
                      color: '#31aee0',
                      marginRight: { xs: 1, md: '20px' },
                      '& span': {
                        textTransform: 'none',
                        fontSize: '14px',
                      },
                      opacity: '1',
                      textTransform: 'none',
                    }}
                  >
                    Hola
                  </Tab>
                ))}
              </Tabs>
            </AppBar>
          </Box>
        </Box> */}
      </Box>
    </div>
  );
};
