import MainLayout from '../layout/MainLayout'
import PromoBar from '../components/PromoBar'
import SlideShow from '../components/SlideShow'
import ScrollerMenu from '../components/ScrollerMenu'
import Marquee from 'react-fast-marquee'
import UserCatalogContainer from '../containers/UserCatalogContainer'
import UserPromoPage from './UserPromoPage'

import InfiniteScroll from 'react-infinite-scroll-component'

import '../styles/UserStorePage.css'
import { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material'

import { UserPromotionForYou } from '../containers/UserPromotionForYou'
import { useUserAuth } from '../context/UserAuthContext'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { useProducts } from '../context/ProductsContext'
import { GET_CLIENT_SERVICE } from '../services/userService'

const UserStorePage = () => {
  const [page, setPage] = useState(0)
  const { user, logIn } = useUserAuth()
  const [testDialog, setTestDialog] = useState(!user.access)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errorPhone, setErrorPhone] = useState(false)
  const { products, getBanners, getProducts, setProducts, getCategories, banners } = useProducts()

  const handleChangeAuth = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleAuthByPhone = async () => {
    try {
      setErrorPhone(false)
      let config = {
        method: 'get',
        url: process.env.REACT_APP_CHECK_MOBILE_PHONE + '/' + '51' + phoneNumber,
        headers: {
          api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
          'Content-Type': 'application/json',
        }
      }
      const response = await axios(config)

      if (response.data.access) {
        setTestDialog(!response.data.access)

        let getClientbody = JSON.stringify({
          clientCode: response.data.clientInfo.clientCode,
          clientMobilePhone: response.data.clientInfo.mobilePhone,
        })

        let configAuthClient = {
          method: 'get',
          url: process.env.REACT_APP_GET_CLIENT_URL + '/' + response.data.clientInfo.clientCode,
          headers: {
            api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
            'Content-Type': 'application/json',
          }          
        }

        let newUser = {}
        const responseClient = await axios(configAuthClient)

        if (responseClient.status === 200) {
          newUser = responseClient.data
        }
        if ( user.addressId == null)
        {
          newUser.mobilePhone = response.data.clientInfo.mobilePhone
          newUser.access = response.data.access
          newUser.addressId = newUser.deliveryAddresses[0].addressId
          newUser.officeId = newUser.deliveryAddresses[0].officeId
          newUser.storageId = newUser.deliveryAddresses[0].storageId
          newUser.priceListCode = newUser.deliveryAddresses[0].priceListCode
          newUser.routes = newUser.deliveryAddresses[0].routes
          newUser.schedule = newUser.deliveryAddresses[0].schedule
          newUser.priceListId = newUser.deliveryAddresses[0].priceListId
          newUser.dayToDelivery = newUser.deliveryAddresses[0].dayToDelivery
          newUser.dayOfWeek = newUser.deliveryAddresses[0].dayOfWeek
        }
        else
        {
          const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
          newUser.address = foundAddress.address;
          newUser.addressId = foundAddress.addressId;
          newUser.priceListId = foundAddress.priceListId;
          newUser.dayToDelivery = foundAddress.dayToDelivery;
          newUser.dayOfWeek = foundAddress.dayOfWeek;
        }
        logIn(newUser)
        console.log('usuario logeado2: ', newUser);
      } else {
        setPhoneNumber('')
        setErrorPhone(true)
      }
    } catch (error) {
      setPhoneNumber('')
      setErrorPhone(true)
      console.log(error)
    }
  }

  const nextInfinityScroll = () => {
    setPage((prevPage) => prevPage + 1)
    // console.log(page);
    switch (page) {
      case 0:
        // console.log('estamos en 0');
        return <UserPromoPage nextInfinity />
        break
    }
  }

  const handleCloseAlert = () => {
    setErrorPhone(false)
  }
  

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await GET_CLIENT_SERVICE(user);
      //console.log('usuario logeado0: ', user);
      const newUser = data
      if ( user.addressId == null)
      {      
        newUser.mobilePhone = user?.mobilePhone
        newUser.access = user.access
        newUser.addressId = newUser.deliveryAddresses[0].addressId
        newUser.officeId = newUser.deliveryAddresses[0].officeId
        newUser.storageId = newUser.deliveryAddresses[0].storageId
        newUser.priceListCode = newUser.deliveryAddresses[0].priceListCode
        newUser.routes = newUser.deliveryAddresses[0].routes
        newUser.schedule = newUser.deliveryAddresses[0].schedule
        newUser.priceListId = newUser.deliveryAddresses[0].priceListId
        newUser.dayToDelivery = newUser.deliveryAddresses[0].dayToDelivery
        newUser.dayOfWeek = newUser.deliveryAddresses[0].dayOfWeek
        logIn(newUser)
       //console.log('usuario logeado1: ', newUser);
      }
      else
      {
        const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
        newUser.address = foundAddress.address;
        newUser.addressId = foundAddress.addressId;
        newUser.priceListId = foundAddress.priceListId;
        newUser.dayToDelivery = foundAddress.dayToDelivery;
        newUser.dayOfWeek = foundAddress.dayOfWeek;
        logIn(newUser)
        //console.log('usuario logeado1.1: ', newUser);
      }
      
      if (banners.mediumBanners.length === 0 && banners.topBanners.length === 0) {
        console.log({user, banners: 'ok'})
        await getBanners(user)
      }
      if (products.length === 0) {
        console.log({user, products: 'ok'})
        const fetchedProducts = await getProducts(user)
        setProducts(fetchedProducts)
        getCategories(fetchedProducts)
      }
    }
    fetchData()
  }, [])

  return (
    <MainLayout access='user'>
      
      {/* <PromoBar /> */}
      <SlideShow />
      <div className='l-container--full'>
        {/* <Box component={'img'} src={BannerImg} sx={{ maxWidth: { xs: 'calc(100% - 40px)', md: '1690px'}, margin:{ xs: '0 20px', md:'0 auto'},height: { xs: '198px', md:'550px'}}}/> */}

        <ScrollerMenu data='categories' />
        <UserPromotionForYou />
        {/* <ScrollerMenu data="brands" /> */}
      </div>

      <InfiniteScroll
        dataLength={4}
        hasMore={true}
        next={nextInfinityScroll}
        // loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }>
        <UserCatalogContainer />        
      </InfiniteScroll>

      {products.length > 0 && (
        <Dialog
          //open={testDialog}
          open={false}  //entrada libre el escenario serpa tratado desde un botón login más adelante
          // fullWidth
          PaperProps={{
            sx: { overflow: 'visible' },
          }}
          maxWidth='md'>
          <DialogContent sx={{ position: 'relative', overflow: 'visible' }}>
            {/* <Box
              onClick={() => setTestDialog(false)}
              sx={{
                cursor: 'pointer',
                top: '-23px',
                right: '-23px',
                overflow: 'visible',
                width: '47px',
                height: '47px',
                position: 'absolute',
                backgroundColor: '#A5D3EB',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImCross color="white" size={13} />
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: { xs: '70px 30px', md: '120px 126px' },
                gap: { xs: '30px', md: '50px' },
              }}>

              <Typography
                sx={{ textAlign: 'center', color: '#21AEE0', fontSize: { xs: '18px', md: '30px' }, fontWeight: 700 }}>
                Ingresa tu número de celular para continuar tu pedido.
              </Typography>
              {
                errorPhone ? (
                  <Alert severity="error" onClose={handleCloseAlert} >El teléfono digitado no es correcto, intenta otra vez</Alert>
                ): ''
              }
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: { xs: '13px', md: '18px' },
                  gap: { xs: '10px', md: '30px' },
                }}>
                <TextField label='Celular' fullWidth onChange={handleChangeAuth} value={phoneNumber} />
                <Button
                  sx={{
                    backgroundColor: '#21AEE0',
                    padding: { xs: '10px 100px', md: '15px 150px' },
                    fontSize: { xs: '13px', md: '18px' },
                    fontWeight: 900,
                  }}
                  variant='contained'
                  onClick={handleAuthByPhone}
                  id='btn-revalidate-user'>
                  Ingresar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </MainLayout>
  )
}

export default UserStorePage
