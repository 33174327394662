import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, AppBar, Tabs, Tab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Product from '../components/Product';
import SpinnerTeresa from '../components/SpinnerTeresa';
// import Filtro from './Filtro';
import { toTitleCase, a11yProps } from '../utils';
import theme from '../utils/ThemeConfig';
import MainLayout from '../layout/MainLayout';
import ScrollerMenu from '../components/ScrollerMenu';
import { useUserAuth } from '../context/UserAuthContext';
import { useProducts } from '../context/ProductsContext';

const UserCategoryPage = () => {
  const urlParamsCategory = useParams().category;
  const urlParamsSubcategory = useParams().subcategory;

  const [category, setCategory] = useState(useParams().category);
  const [subcategory, setSubcategory] = useState(useParams().subcategory);
  const [limitItems, setLimitItems] = useState(12);

  const [productsCollection, setProductsCollection] = useState({});

  const { user } = useUserAuth();
  const { products, categories, getProducts, getCategories, filterProductsByCategory, filteredProducts } = useProducts();
  const navigate = useNavigate();

  // const productsCollection = useSelector((store) => store.productsCollection);

  useEffect(() => {
    (async () => {
      if (products.length === 0) {
        let products = await getProducts(user);
        getCategories(products);
      }
    })();
  }, [products]);

  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    }

    if (products.length !== 0) {
      let respuesta;
      // eslint-disable-next-line no-debugger
      if (category !== urlParamsCategory || subcategory !== urlParamsSubcategory) {
        // eslint-disable-next-line no-debugger
        setCategory(urlParamsCategory);
        setSubcategory(urlParamsSubcategory);
        setLimitItems(12);
        respuesta = filterProductsByCategory(products, categories, urlParamsCategory, urlParamsSubcategory);
        // console.log('respuesta if', respuesta);
        setProductsCollection(respuesta);
      } else {
        // console.log('Category: ', category);
        respuesta = filterProductsByCategory(products, categories, category, subcategory);
        // console.log('respuesta else', respuesta);
        setProductsCollection(respuesta);
      }
    }
  }, [categories, category, urlParamsCategory, urlParamsSubcategory]);
  // }, []);

  const showMoreItems = () => {
    setLimitItems(limitItems + 12);
  };

  return (
    <>
      {products.length === 0 && <SpinnerTeresa />}
      {categories.length === 0 && <SpinnerTeresa />}
      {filteredProducts === undefined && <SpinnerTeresa />}
      {!productsCollection.hasOwnProperty('subCategoryIndex') ? (
        <SpinnerTeresa />
      ) : (
        <MainLayout access="user">
          <div className="l-container--full">
            <ScrollerMenu data="categories" />
          </div>
          <div className="l-container">
            <Typography component="div" color="primary">
              <Box component="div" maxHeight="40px" textOverflow="ellipsis" overflow="hidden" textAlign="center" fontSize="30px" fontWeight="500" whiteSpace="nowrap" mt={3} mb={1}>
                {productsCollection.category}
              </Box>
            </Typography>
            {productsCollection.subCategory === '' ? null : (
                <Tabs value={productsCollection.subCategoryIndex + 1} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile aria-label="scrollable force tabs example">
                  <Tab
                    onClick={() => navigate('/tienda/' + category + '/home')}
                    label={toTitleCase('ver todo')}
                    {...a11yProps(0)}
                    sx={{
                      '& span': {
                        textTransform: 'none',
                        fontSize: '14px',
                      },
                    }}
                  />
                  {productsCollection.subCategories?.map((item, index) => (
                    <Tab
                      onClick={() => navigate('/tienda/' + category + '/' + item.name.toLowerCase().split(' ').join('-'))}
                      key={index + 1}
                      label={toTitleCase(item.name)}
                      {...a11yProps(index + 1)}
                      sx={{
                        '& span': {
                          textTransform: 'none',
                          fontSize: '14px',
                        },
                      }}
                    />
                  ))}
                </Tabs>
            )}
            <div
              style={{
                flexGrow: '1',
                backgroundColor: 'white',
                paddingTop: theme.spacing(2),
                // minHeight: 'calc(100vh - 64px - 196px)',
              }}
            >
              <Grid container direction="row" display="flex" alignItems="stretch" alignContent="center">
                {/* {productsCollection.minPrice === 0 || productsCollection.maxPrice === 0 || productsCollection.categories.length === 0 ? null : (
              <Grid item xs={12}>
                <Box><Filtro priceFilter={false} /></Box>
              </Grid>
            )} */}
                {filteredProducts.length <= limitItems
                  ? filteredProducts
                      .filter((el) => el.stockAmount !== 0)
                      .map((item, index) => (
                        <Grid item xs={6} sm={4} md={3} key={item.code}>
                          <Box my={1} margin={"5px"}>
                            <Product product={item} />
                          </Box>
                        </Grid>
                      ))
                  : filteredProducts
                      .slice(0, limitItems)
                      .filter((el) => el.stockAmount !== 0)
                      .map((item, index) => (
                        <Grid item xs={6} sm={4} md={3} key={item.code} >
                          <Box margin={"5px"}>
                            <Product key={item.code} product={item} />
                          </Box>
                        </Grid>
                      ))}
              </Grid>
              {limitItems >= filteredProducts.length ? null : (
                <Grid container direction="row" justify="center" alignItems="center" alignContent="center">
                  <Grid item xs={12}>
                    <Box width="100%" my={2} display="flex" justifyContent="center" >
                      <button className='buttonMore' 
                          onClick={showMoreItems} 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}>
                            Ver Más
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default UserCategoryPage;
