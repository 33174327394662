import { api } from './axiosConfig'

export const GET_SURVEY_INFORMATION = async () => {
  try {
    const data = await api.post(process.env.REACT_APP_GET_SURVEY_INFORMATION_URL)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const SAVE_SURVEY = async (surveyResponse, mobilePhone) => {
  try {
    const data = await api.post(process.env.REACT_APP_SAVE_SURVEY_URL, { surveyResponse, mobilePhone })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const SAVE_PURCHASE_SURVEY = async (purchaseSurveyResponse, mobilePhone) => {
  try {
    const data = await api.post(process.env.REACT_APP_SAVE_PURCHASE_SURVEY_URL, { purchaseSurveyResponse, mobilePhone })
    return data
  } catch (error) {
    console.error(error)
  }
}