/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Delivery = ({ className }) => {
  return (
    <svg
      className={`delivery ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 23 16"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.77905 16C3.94953 16 3.24531 15.7099 2.66638 15.1298C2.08745 14.5496 1.79799 13.8452 1.79799 13.0166H-0.000152588V1.92087C-0.000152588 1.38409 0.185798 0.929754 0.5577 0.557852C0.929601 0.185951 1.38385 0 1.92045 0H16.388V4.04602H19.2078L22.6407 8.64379V13.0166H20.7201C20.7201 13.8452 20.4297 14.5496 19.849 15.1298C19.2685 15.7099 18.5636 16 17.7342 16C16.9049 16 16.2007 15.7099 15.6216 15.1298C15.0426 14.5496 14.7532 13.8452 14.7532 13.0166H7.76462C7.76462 13.8475 7.47436 14.5524 6.89384 15.1313C6.31332 15.7104 5.60839 16 4.77905 16ZM4.78144 14.4061C5.17105 14.4061 5.50001 14.2719 5.76831 14.0034C6.03661 13.7351 6.17076 13.4062 6.17076 13.0166C6.17076 12.6269 6.03661 12.2979 5.76831 12.0294C5.50001 11.7611 5.17105 11.627 4.78144 11.627C4.39183 11.627 4.06278 11.7611 3.79431 12.0294C3.52601 12.2979 3.39185 12.6269 3.39185 13.0166C3.39185 13.4062 3.52601 13.7351 3.79431 14.0034C4.06278 14.2719 4.39183 14.4061 4.78144 14.4061ZM1.59371 11.4227H2.36195C2.58811 11.029 2.91467 10.699 3.34165 10.4326C3.7688 10.1663 4.24873 10.0331 4.78144 10.0331C5.30033 10.0331 5.77672 10.1646 6.2106 10.4276C6.64449 10.6906 6.97451 11.0223 7.20066 11.4227H14.7941V1.59386H1.92045C1.83881 1.59386 1.7639 1.62795 1.69572 1.69614C1.62771 1.76414 1.59371 1.83905 1.59371 1.92087V11.4227ZM17.7366 14.4061C18.1262 14.4061 18.4552 14.2719 18.7235 14.0034C18.992 13.7351 19.1262 13.4062 19.1262 13.0166C19.1262 12.6269 18.992 12.2979 18.7235 12.0294C18.4552 11.7611 18.1262 11.627 17.7366 11.627C17.347 11.627 17.018 11.7611 16.7495 12.0294C16.4812 12.2979 16.347 12.6269 16.347 13.0166C16.347 13.4062 16.4812 13.7351 16.7495 14.0034C17.018 14.2719 17.347 14.4061 17.7366 14.4061ZM16.388 9.29754H21.1491L18.3904 5.63989H16.388V9.29754Z"
        fill="var(--primary-color)"
      />
    </svg>
  );
};
