
import FAQList from '../components/FAQList'
import MainLayout from '../layout/MainLayout'
import '../styles/UserFAQ.css'
export default function UserFAQ() {
  return (
    <MainLayout access='user'>
      <div className='l-container'>
        <div className='faq-container'>
          <h5 className='title-page'>Preguntas Frecuentes</h5>
          <FAQList/>
        </div>
      </div>
    </MainLayout>
  )
}
