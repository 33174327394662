import {
  Box,
  Button,
  CircularProgress,
  Container,
  createSvgIcon,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  gridFilteredSortedRowIdsSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridToolbarContainer,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { api } from '../services/axiosConfig'

const columns = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    description: 'Order Id',
    flex: 1,
  },
  {
    field: 'channel',
    headerName: 'Canal',
    description: 'Canal',
    flex: 1,
  },
  {
    field: 'clientCode',
    headerName: 'Codigo Cliente',
    description: 'Codigo Cliente',
    flex: 1,
  },
  {
    field: 'mobilePhone',
    headerName: 'Numero Telefono',
    description: 'Numero Telefono',
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'Fecha',
    description: 'Fecha',
    flex: 1,
  },
  {
    field: 'finalPrice',
    headerName: 'Precio Final',
    description: 'Precio Final',
    flex: 1,
  },
  {
    field: 'totalPrice',
    headerName: 'Precio Total',
    description: 'Precio Total',
    flex: 1,
  },
  {
    field: 'discount',
    headerName: 'Descuento',
    description: 'Descuento',
    flex: 1,
  },
  {
    field: 'deliveryDay',
    headerName: 'Día de Entrega',
    description: 'Día de Entrega',
    flex: 1,
  },
  {
    field: 'deliveryAddress',
    headerName: 'Direccion de Entrega',
    description: 'Direccion de Entrega',
    flex: 1,
  },
]

const ExportIcon = createSvgIcon(
  <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z' />,
  'SaveAlt',
)

const getRowsFromCurrentPage = ({ apiRef }) => gridPaginatedVisibleSortedGridRowIdsSelector(apiRef)

const getUnfilteredRows = ({ apiRef }) => gridFilteredSortedRowIdsSelector(apiRef)

const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef)

const CustomToolbar = () => {
  const apiRef = useGridApiContext()

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options)

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  }

  return (
    <GridToolbarContainer>
      <Button {...buttonBaseProps} onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}>
        Exportar Página Actual
      </Button>
      <Button {...buttonBaseProps} onClick={() => handleExport({ getRowsToExport: getFilteredRows })}>
        Exportar todo con filtros
      </Button>
      <Button {...buttonBaseProps} onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
        Expórtar Todo
      </Button>
    </GridToolbarContainer>
  )
}

export default function AdminPurchasesContainer() {
  const [datos, setDatos] = useState([])
  const [loading, setLoading] = useState(false)

  const getPurchases = async () => {
    try {
      setLoading(true)
      const { status, data } = await api.post(process.env.REACT_APP_GET_PURCHASES_URL)
      if (status === 200) {
        const purchases = data.purchases.map((purchase) => ({ ...purchase, id: purchase.orderId }))
        setDatos(purchases)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    getPurchases()
  }, [])

  return (
    <div>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display='flex' justifyContent='center' alignItems='center' width='100%'>
              <Typography component='div' flexGrow='1'>
                <Box fontSize='18px' fontWeight='500'>
                  Pedidos
                </Box>
              </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {loading ? (
            <Box height='20vh' width='100%' alignItems='center' sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={datos}
              columns={columns}
              autoHeight
              pageSize={18}
              rowsPerPageOptions={[18]}
              density='compact'
              getRowId={(row) => row.orderId}
              components={{ Toolbar: CustomToolbar }}
            />
          )}
        </Paper>
      </Grid>
    </div>
  )
}
