import MainLayout from '../layout/MainLayout';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProducts } from '../context/ProductsContext';

import { Box, Grid, Typography, Button, InputBase, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RiEqualizerLine } from 'react-icons/ri';
import SearchIcon from '@mui/icons-material/Search';
import '../styles/UserSearchPage.css';
import { alpha } from '@mui/material/styles';
// import Filtro from "../categoria/Filtro";
import Product from '../components/Product';
import theme from '../utils/ThemeConfig';
import { SearchSideBar } from '../components/SearchSideBar';
import { useLayoutMenu } from '../context/LayoutContext';

const UserSearchPage = () => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [limitItems, setLimitItems] = useState(12);
  const { products, search, setSearch, filteredProducts, setFilteredProducts, searchProducts, setSearchProducts } = useProducts();
  const { openFilter, openSideMenu, closeSideMenu } = useLayoutMenu();
  const [sortType, setSortType] = useState('')
  // const productsCollection = useSelector((store) => store.productsCollection);

  const searchProductsByName = async (searchValue) => {
    try {
      // dispatch({
      // 	type: SET_SEARCH,
      // 	payload: {
      // 		search: searchValue,
      // 	},
      // });
      console.log('zzz: ', searchValue);
      console.log(searchValue);
      setSearch(searchValue);

      // let products = getState().productsCollection.products.slice();
      // if (products.length === 0) {
      // 	products = JSON.parse(localStorage.getItem("products")) || "[]";
      // }

      // const sort = getState().productsCollection.sort;
      const sort = 'bestsellers';

      // console.log('search: ', searchValue.toUpperCase());

      let filteredProducts = products.filter(
        (item) =>
          item.name.includes(searchValue.toUpperCase()) ||
          item.subcategoria.includes(searchValue.toUpperCase()) ||
          item.categoria.includes(searchValue.toUpperCase()) ||
          item.proveedor.includes(searchValue.toUpperCase()) ||
          item.code.includes(searchValue.toUpperCase()),
      );

  //     if (searchValue !== '') {
  //       // Función para eliminar tildes de una cadena
  //       const normalizeSearchValue = searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  
  //       // Lista de artículos comunes en español
  //       const articles = ['de', 'el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas', 'al', 'del', 'lo', 'le', 'a'];
        
  //       // Función para eliminar artículos de un array de palabras
  //       const filterArticles = (words) => {
  //         return words.filter(word => !articles.includes(word.toLowerCase()));
  //       };
  
  //       // Dividir el término de búsqueda, eliminar tildes y filtrar artículos
  //       const searchTerms = filterArticles(
  //         (normalizeSearchValue)
  //             .split(' ')
  //             .map(term => term.trim().toUpperCase())
  //       );
  
  //       // Filtrar los productos
  //       let filteredProducts  = products.filter((el) =>
  //       searchTerms.every(term =>
  //           el.palabrasclave.toUpperCase().includes(term.trim())
  //       )
  //       && el.stockAmount !== 0
  //   );      // Filtrar los productos
  //   //setPredictionProducts(result);
  // }
    
      console.log('searchProductsByName - filteredProducts: ', filteredProducts);
      let maxPrice = 0;
      let minPrice = 0;
      if (filteredProducts.length > 0) {
        maxPrice = filteredProducts.reduce(function (prev, current) {
          return prev.salePrice > current.salePrice ? prev : current;
        }).salePrice;
        minPrice = filteredProducts.reduce(function (prev, current) {
          return prev.salePrice < current.salePrice ? prev : current;
        }).salePrice;

        if (sort === 'bestsellers') {
          filteredProducts = filteredProducts.filter((item) => item.isBestSeller === true).concat(filteredProducts.filter((item) => item.isBestSeller === false));
        } else {
          filteredProducts.sort((a, b) => (sort === 'lowest' ? (a.salePrice > b.salePrice ? 1 : -1) : sort === 'highest' ? (a.salePrice > b.salePrice ? -1 : 1) : a.rentability > b.rentability ? -1 : 1));
        }
      }

      // dispatch({
      // 	type: FILTER_PRODUCTS_BY_NAME,
      // 	payload: {
      // 		filteredProducts: filteredProducts,
      // 		minPrice: minPrice,
      // 		maxPrice: maxPrice,
      // 	},
      // });
      // localStorage.setItem(
      // 	"filteredProducts",
      // 	JSON.stringify(filteredProducts)
      // );
      setFilteredProducts(filteredProducts);
      setSearchProducts(filteredProducts);
      return filteredProducts;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
    //setSearch(searchValue);
    //console.log('filtro Productos: ', products[0]);
    // console.log('filtro Productos MIN: ', products[0].saleMin);
    filteredProducts.forEach((item) => {
      // console.log('filtro Productos MIN1: ', item.saleMin);
      let exists = products.find((prod) => prod.code === item.code);
      // console.log('filtro Productos MIN2: ', exists.saleMin);
      //item = exists;
      item.discountPrice = exists.salePrice?? 0;
      item.saleMin = exists.saleMin?? 0;
      item.salePrice = exists.salePrice?? 0;
      item.sugPrice = exists.sugPrice?? 0;
      // console.log('filtro Productos MIN3: ', item.saleMin);
      //setFilteredProducts(filteredProducts);
      // item.product = exists;
      // console.log('ex:', exists);
    });
  }, [products]);

  const showMoreItems = () => {
    setLimitItems(limitItems + 12);
  };

  const handleSortChange = (e) => {

    let evaluateParam = 1;

    if(e.target.value === 'minorPrice') {
      evaluateParam = -1
    }
    const newFilteredProducts = filteredProducts.sort((a, b) => {
      if(a.price < b.price) {
        return -1 * evaluateParam;
      }
      if( a.price > b.price) {
        return 1 * evaluateParam;
      }
      return 0
    })
      
    setFilteredProducts(newFilteredProducts)

    setSortType(e.target.value)
  }
  

  const startSearch = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (searchValue !== '') {

        console.log('buscado:',searchValue);
        console.log(searchValue);
        searchProductsByName(searchValue);
        navigate('/tienda/busqueda');
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <MainLayout access="user">
      <div
        className="l-container"
        style={{
          flexGrow: '1',
          backgroundColor: 'white',
          padding: theme.spacing(1),
          minHeight: 'calc(100vh - 64px - 76px)',
        }}
      >
        <div className="search__container">
          <div className={`search__sidebar ${openFilter ? 'open fadeIn' : ''}`} onMouseLeave={closeSideMenu}>
            <div className='search__sidebar--close' onClick={closeSideMenu}>x</div>
            <SearchSideBar />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box display="flex" justifyContent="space-between" flexDirection="row" sx={{ display: {xs:'flex', md:'none'}, marginBottom: '20px' }}>
              <Button variant="outlined" color="primary" sx={{ textTransform: 'none', marginTop: '20px', color: '#2D85C5', fontSize: '15px' }} onClick={openSideMenu} id="btn-search-filter">
                <RiEqualizerLine /> Filtrar
              </Button>
              {/* <select value={sortType} onChange={handleSortChange}>
                <option value={""}> Ordernar </option>
                <option value={"minorPrice"}> Menor Precio </option>
                <option value={"majorPrice"}> Mayor Precio </option>
              </select> */}
              {/* <Typography component="div" color="primary">
                <Box textAlign="center" fontSize="18px" mt={1}>
                  Resultados de la búsqueda para:
                </Box>
                <Box fontWeight="fontWeightBold" fontSize="22px" textAlign="center" py={1}>
                  "{search}"
                </Box>
              </Typography> */}
            </Box>

            <Grid container direction="row" display="flex" spacing={{ xs: '10px', md: '30px' }}>
              {search === '' || filteredProducts.length === 0 ? (
                <>
                  {search === '' ? (
                    <>
                      <Grid item xs={12}>
                        <Typography component="div" color="primary">
                          <Box marginY={4} textAlign="center" fontSize="22px" fontWeight={500}>
                            ¿Qué producto estás buscando?
                          </Box>
                        </Typography>
                        <Box display="flex" justifyContent="center" wi>
                          <Box
                            sx={{
                              flexGrow: 1,
                              alignItems: 'center',
                              justifyContent: 'center',
                              maxWidth: '80%',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                maxWidth: '100%',
                                width: 'auto',
                                minWidth: '100px',
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: alpha('#aaa', 0.15),
                                '&:hover': {
                                  backgroundColor: alpha('#aaa', 0.25),
                                },
                                margin: theme.spacing(2),
                                [theme.breakpoints.up('sm')]: {
                                  marginLeft: theme.spacing(3),
                                  width: 'auto',
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  padding: theme.spacing(0, 1),
                                  height: '100%',
                                  position: 'relative',
                                  pointerEvents: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <SearchIcon color="primary" />
                              </Box>
                              <InputBase
                                placeholder="Buscar"
                                classes={{
                                  root: {
                                    color: 'inherit',
                                  },
                                  input: {
                                    padding: theme.spacing(1, 1, 1, 0),
                                    // vertical padding + font size from searchIcon
                                    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                                    transition: theme.transitions.create('width'),
                                    width: '100%',
                                    [theme.breakpoints.up('md')]: {
                                      width: '20ch',
                                    },
                                  },
                                }}
                                inputProps={{
                                  'aria-label': 'search',
                                }}
                                value={searchValue}
                                onKeyUp={(e) => startSearch(e)}
                                onChange={(e) => handleChange(e)}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography component="div" color="primary">
                          <Box display="flex" justifyContent="center" flexDirection="row">
                            <Typography component="div" color="primary">
                              <Box textAlign="center" fontSize="18px" mt={3}>
                                No se han encontrado resultados para:
                              </Box>
                              <Box fontWeight="fontWeightBold" fontSize="22px" textAlign="center" py={2}>
                                "{search}"
                              </Box>
                            </Typography>
                          </Box>
                          <Box marginY={1} textAlign="center" fontSize="16px" fontWeight={400}>
                            Nuestras sugerencias de búsqueda para ti:
                          </Box>
                          <Box marginY={1} textAlign="center" color="#aaa" fontSize="16px" fontWeight={400}>
                            - Revisa tu entrada para ver si hay errores tipográficos
                          </Box>
                          <Box marginY={1} textAlign="center" fontSize="16px" fontWeight={400} color="#aaa">
                            - Prueba con palabras relacionadas para tu búsqueda
                          </Box>
                          <Box marginY={1} textAlign="center" fontSize="16px" fontWeight={400} color="#aaa">
                            - Revisa nuestras categorías y refina tu búsqueda hasta el producto deseado
                          </Box>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" flexDirection="row">
                    <Typography component="div" color="primary">
                      <Box textAlign="center" fontSize="18px" mt={1}>
                        Resultados de la búsqueda para:
                      </Box>
                      <Box fontWeight="fontWeightBold" fontSize="22px" textAlign="center" py={1}>
                        "{search}"
                      </Box>
                    </Typography>
                  </Box>
                </Grid> */}

                  {/* {productsCollection.minPrice === 0 || productsCollection.maxPrice === 0 || categories.length === 0 ? null : (
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <Filtro priceFilter={false} />
                    </Box>
                  </Grid>
                )} */}
                  {filteredProducts.length <= limitItems
                    ? filteredProducts
                        .filter((el) => el.stockAmount !== 0)
                        .map((item) => (
                          <Grid item xs={6} sm={6} md={6} lg={4} xl={3} key={item.code} className="fadeIn">
                            <Box my={1}>
                              <Product product={item} />
                            </Box>
                          </Grid>
                        ))
                    : filteredProducts
                        .slice(0, limitItems)
                        .filter((el) => el.stockAmount !== 0)
                        .map((item) => (
                          <Grid item xs={6} sm={6} md={6} lg={4} xl={3} key={item.code} className="fadeIn">
                            <Box>
                              <Product key={item.code} product={item} />
                            </Box>
                          </Grid>
                        ))}
                  {limitItems >= filteredProducts.length ? null : (
                    <Grid item xs={12}>
                      <Box mt={2} mb={1} pl={1} pr={1} display="flex" justifyContent="center" >
                        <button className='buttonMore' 
                          onClick={showMoreItems} 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}>
                            Ver Más
                      </button>
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default UserSearchPage;
