import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAdminAuth } from '../context/AdminAuthContext';

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import MainLayout from '../layout/MainLayout';

const theme = createTheme();

const AdminLoginPage = () => {
  const [error, setError] = useState(false);
  const { logIn, admin } = useAdminAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await logIn(data.get('email'), data.get('password'));
      navigate('/');
    } catch (error) {
      setError(true);
    }
  };

  if (!admin) {
    return (
      <ThemeProvider theme={theme}>
        <MainLayout>
          <Container component="main">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                marginBottom: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Panel de Gestión
              </Typography>
              <Typography component="h5" variant="h6">
                Identificación de acceso
              </Typography>
              {error && (
                <Box fullWidth>
                  <Alert severity="error">Ocurrió un error en la identificación</Alert>
                </Box>
              )}

              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField margin="normal" required fullWidth id="email" label="Email" name="email" autoComplete="email" autoFocus />
                <TextField margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Iniciar Sesión
                </Button>
              </Box>
            </Box>
          </Container>
        </MainLayout>
      </ThemeProvider>
    );
  }
  return <Navigate replace to="/" />;
};

export default AdminLoginPage;
