import '../styles/SpinnerTeresa.css';
import VedoraPlusIco from '../assets/spinner-teresa.gif';

const SpinnerTeresa = () => {
  return (
    <div className="spinner__container spinnerApiLogo" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img  src={VedoraPlusIco} alt="" />
      <span>Cargando las mejores promociones</span>
    </div>
  );
};

export default SpinnerTeresa;
