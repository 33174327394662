import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import TimerIcon from '@mui/icons-material/Timer'
import { green, red } from '@mui/material/colors'

export default function ApiCard({ title, api, loadingServices }) {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        maxHeight: '250px',
        minHeight: '200px',
      }}>
      <Box p={1} display='flex' flexDirection='column' gap={2}>
        <Box display='flex' justifyContent='center' alignItems='center' m={1}>
          <Typography component='div' flexGrow='1'>
            <Box fontSize='22px' fontWeight='500'>
              {title}
            </Box>
          </Typography>

          {loadingServices ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress size={50}/>
              <Box
                display='flex'
                flexGrow='1'
                justifyContent='center'
              >
                Cargando
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              {api.status === 'OK' ? (
                <CheckCircleIcon fontSize='large' sx={{ fontSize: '50px', color: green[500] }} />
              ) : (
                <ErrorIcon fontSize='large' sx={{ fontSize: '50px', color: red[500] }} />
              )}
              <Box
                display='flex'
                flexGrow='1'
                justifyContent='center'
                color={api.status === 'OK' ? green[500] : '#666666'}>
                {api.status === 'OK' ? 'Disponible' : 'No disponible'}
              </Box>
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display='flex' justifyContent='center' alignItems='center'  flexDirection="column">
            <Typography textAlign="center">Tiempo medio</Typography>
            <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
              <TimerIcon sx={{ fontSize: '25px', color: '#666666' }} />
              <Box color='#666666' mb='2px'>
                {api.status === 'OK' ? api.averageTime + ' s' : '- s'}
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' flexDirection="column">
            <Typography textAlign="center">Tiempo medio 95%</Typography>
            <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
              <TimerIcon sx={{ fontSize: '25px', color: '#666666' }} />
              <Box color='#666666' mb='2px'>
                {api.status === 'OK' ? api.averageTime95 + ' s' : '- s'}
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center'   flexDirection="column">
            <Typography textAlign="center">Tiempo Maximo</Typography>
            <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
              <TimerIcon sx={{ fontSize: '25px', color: '#666666' }} />
              <Box color='#666666' mb='2px'>
                {api.status === 'OK' ? api.maxTime + ' s' : '- s'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}
