import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/firebase';
// import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
// import { auth } from '../services/firebase';

const AdminAuthContext = createContext();

export const AdminAuthContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState({});

  const logIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logOut = () => {
    return auth.signOut();
  };

  useEffect(() => {
    let mounted = true;
    const unsubscribe = auth.onAuthStateChanged((currentAdmin) => {
      if (mounted) setAdmin(currentAdmin);
    });
    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  return <AdminAuthContext.Provider value={{ admin, logIn, logOut }}>{children}</AdminAuthContext.Provider>;
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};
