// import { Collapse } from '@mui/material'
import { Collapse } from '@mui/material'
import { useState } from 'react'
import '../styles/Faq.css'
export const FAQ = ({ faq }) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded(!expanded)
  }
  return (
    <div className={`faq-box ${expanded ? 'expanded' : ''}`} onClick={handleExpand}>
      <div className='faq-question-container'>
        <p className={`faq-question ${expanded ? 'expanded-question' : ''}`}>{faq.question}</p>
        <div className={`faq-icon ${expanded ? 'expanded-icon' : ''}`} />
      </div>
      <Collapse in={expanded} unmountOnExit>
        <div className='faq-collapse'>
          <p className='faq-answer' dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
        </div>
      </Collapse>
    </div>
  )
}
