/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Offer = ({ className }) => {
  return (
    <svg
      className={`offer ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 37 16"
      width="37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.0434 9.85432L20.3543 15.5434C20.2013 15.6955 20.0292 15.8097 19.8379 15.8859C19.6466 15.962 19.4559 16 19.2657 16C19.0754 16 18.8851 15.962 18.6947 15.8859C18.5044 15.8097 18.3332 15.6955 18.1811 15.5434L10.9438 8.31411C10.8002 8.176 10.6903 8.0139 10.6141 7.82779C10.538 7.64168 10.5 7.44589 10.5 7.24042V1.53032C10.5 1.10954 10.6487 0.749263 10.9461 0.449473C11.2436 0.149824 11.6051 0 12.0303 0H17.7404C17.9438 0 18.1408 0.0412633 18.3314 0.12379C18.5221 0.206176 18.6874 0.317123 18.8272 0.456632L26.0434 7.68589C26.1976 7.83888 26.3101 8.01095 26.3808 8.20211C26.4516 8.3934 26.4869 8.58519 26.4869 8.77747C26.4869 8.96975 26.4516 9.15881 26.3808 9.34463C26.3101 9.5306 26.1976 9.70049 26.0434 9.85432ZM19.4539 14.6558L25.1429 8.96674C25.1968 8.91284 25.2238 8.8494 25.2238 8.77642C25.2238 8.70358 25.1968 8.64021 25.1429 8.58632L17.8328 1.27116H12.0303C11.9548 1.27116 11.8914 1.29551 11.84 1.34421C11.7888 1.39277 11.7632 1.45481 11.7632 1.53032V7.22758C11.7632 7.26 11.7686 7.29235 11.7794 7.32463C11.7902 7.35705 11.8091 7.38674 11.836 7.41368L19.0733 14.6558C19.1273 14.7098 19.1907 14.7368 19.2636 14.7368C19.3364 14.7368 19.3999 14.7098 19.4539 14.6558ZM13.8895 4.44526C14.1831 4.44526 14.4325 4.34295 14.6377 4.13832C14.8427 3.93368 14.9453 3.68512 14.9453 3.39263C14.9453 3.09804 14.8429 2.84765 14.6383 2.64147C14.4337 2.43516 14.1851 2.332 13.8926 2.332C13.598 2.332 13.3476 2.43481 13.1415 2.64042C12.9352 2.84603 12.832 3.09572 12.832 3.38947C12.832 3.68309 12.9348 3.93249 13.1404 4.13768C13.346 4.34274 13.5957 4.44526 13.8895 4.44526Z"
        fill="var(--primary-color)"
      />
    </svg>
  );
};
