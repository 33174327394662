import { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const LayoutContextProvider = ({ children }) => {
  const [openFilter, setOpenFilter] = useState(false);

  const openSideMenu = () => {
    setOpenFilter(true)
  }

  const closeSideMenu = () => {
    setOpenFilter(false)
  }
  
  

  return <LayoutContext.Provider value={{ openFilter, openSideMenu, closeSideMenu }}>{children}</LayoutContext.Provider>;
};

export const useLayoutMenu = () => {
  return useContext(LayoutContext);
};
