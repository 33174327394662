import { Routes, Route, Navigate } from 'react-router-dom';
import UserProtectedRoute from './UserProtectedRoute';
import { UserAuthContextProvider } from '../context/UserAuthContext';
import { ProductsContextProvider } from '../context/ProductsContext';
import { CartContextProvider } from '../context/CartContext';

import UserHomePage from '../pages/UserHomePage';
import UserAuthPage from '../pages/UserAuthPage';

import UserProfilePage from '../pages/UserProfilePage';
import UserStorePage from '../pages/UserStorePage';
import UserSearchPage from '../pages/UserSearchPage';
import UserPromoPage from '../pages/UserPromoPage';
import UserBrandPage from '../pages/UserBrandPage';
import PrivacyPoliciesWhatsAppPage from '../pages/PrivacyPoliciesWhatsAppPage';
import UserCategoryPage from '../pages/UserCategoryPage';
import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from '../utils/ThemeConfig';
import { UserAllBrandPage } from '../pages/UserAllBrandPage';
import { UserAllCategories } from '../pages/UserAllCategories';
import ScrollToTop from '../components/ScrollToTop';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LayoutContextProvider } from '../context/LayoutContext';
import UserProductPage from '../pages/UserProductPage';
import { UserCart } from '../pages/UserCart';
import UserExperience from '../pages/UserExperience';
import UserFAQ from '../pages/UserFAQ';
import { Catlogo } from '../pages/Catlogo';
const UserRouter = () => {
  // useEffect(() => {
  //   const scriptChatbotsrc = document.createElement('script');
  //   const scriptExperience = document.createElement('script');
  //   scriptChatbotsrc.setAttribute('data-id', 'chatbotsrc');
  //   scriptChatbotsrc.setAttribute('data-node', 'chatexperience');
  //   scriptChatbotsrc.async = false;
  //   scriptChatbotsrc.src = 'https://chatexperience.linkmobility.com/webchatsrc/chatexperience.js';
  //   //document.body.appendChild(scriptChatbotsrc);

  //   scriptChatbotsrc.addEventListener('load', () => {
  //     scriptExperience.innerHTML = `chatexperience.Start("355119db-a765-4d6c-8770-8a70abe1465a");`;
  //     //document.body.appendChild(scriptExperience);
  //   });

  //   return () => {
  //     scriptChatbotsrc.remove();
  //     scriptExperience.remove();
  //   };
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserAuthContextProvider>
        <LayoutContextProvider>
          <ProductsContextProvider>
            <CartContextProvider>
              <ScrollToTop>
                <ToastContainer position="top-right" theme="colored" transition={Slide} autoClose={3000} hideProgressBar newestOnTop={true} closeOnClick rtl={false} />

                <Routes>
                  <Route path="/" element={<UserHomePage />} />
                  <Route path="/ofertas" element={
                    <UserProtectedRoute>
                      <Catlogo />
                    </UserProtectedRoute>
                  } />                  
                  <Route path="/PPWSP" element={<PrivacyPoliciesWhatsAppPage />} />
                  <Route path="/es" element={<Navigate replace to="/" />} />
                  <Route path="/auth/:token" element={<UserAuthPage />} />
                  <Route
                    path="/perfil"
                    element={
                      <UserProtectedRoute>
                        <UserProfilePage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda"
                    element={
                      <UserProtectedRoute>
                        <UserStorePage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/busqueda"
                    element={
                      <UserProtectedRoute>
                        <UserSearchPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/promociones"
                    element={
                      <UserProtectedRoute>
                        <UserPromoPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/marcas"
                    element={
                      <UserProtectedRoute>
                        <UserAllBrandPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/marca/:brand/:subcategory"
                    element={
                      <UserProtectedRoute>
                        <UserBrandPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/categories"
                    element={
                      <UserProtectedRoute>
                        <UserAllCategories />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/:category/:subcategory"
                    element={
                      <UserProtectedRoute>
                        <UserCategoryPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/tienda/producto/:productCode"
                    element={
                      <UserProtectedRoute>
                        <UserProductPage />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/carrito"
                    element={
                      <UserProtectedRoute>
                        <UserCart />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/experiencia"
                    element={
                      <UserProtectedRoute>
                        <UserExperience />
                      </UserProtectedRoute>
                    }
                  />
                  <Route
                    path="/faq"
                    element={
                      <UserProtectedRoute>
                        <UserFAQ />
                      </UserProtectedRoute>
                    }
                  />
                  <Route path="*" element={<Navigate replace to="/tienda" />} />
                </Routes>
              </ScrollToTop>
            </CartContextProvider>
          </ProductsContextProvider>
        </LayoutContextProvider>
      </UserAuthContextProvider>
    </ThemeProvider>
  );
};

export default UserRouter;
