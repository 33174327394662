import Lottie from 'lottie-react'
import cartLoading from '../animations/Carrito_Cargando.json'
import checkConfirmation from '../animations/Check_Confirmacion.json'
import { useState } from 'react'
import '../styles/FinishOrder.css'
import { Rating } from '@mui/material'
import FacebookIcon from '../assets/Facebook_pedido_confirmado.png'
import InstagramIcon from '../assets/Instagram_pedido_confirmado.png'
import { Link } from 'react-router-dom'
import { useCart } from '../context/CartContext'
import { useUserAuth } from '../context/UserAuthContext'
import { SAVE_PURCHASE_SURVEY } from '../services/experienceService'
import React, { useEffect, useRef } from "react";

export const FinishOrder = () => {
  const [rating, setRating] = useState(0)
  const [disableRating, setDisableRating] = useState(false)
  const { user } = useUserAuth()
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER || '+51908824814'; // Valor por defecto
  const vedoraEnvironment = process.env.REACT_APP_VEDORA_ENVIRONMENT || ''; // Valor por defecto
  
  const { order, loadingOrder } = useCart() 
  const handleRating = async (e, newValue) => {
    if (disableRating) return

    try {
      setRating(newValue)
      setDisableRating(true)

      await SAVE_PURCHASE_SURVEY(newValue, user.mobilePhone)
    } catch (error) {
      console.error(error)
    }
  }

  const whatsappButtonRef = useRef(null);

  const abrirWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`. ${vedoraEnvironment}`)}`;
    //window.open(url, "");
    window.location.href = url;
};

// // Ejecuta la acción después de 5 segundos al cargar la página
// useEffect(() => {
//   const timer = setTimeout(() => {
//     whatsappButtonRef.current?.click();
//   }, 5000); 

// // Limpia el temporizador cuando el componente se desmonte
//   return () => clearTimeout(timer);
// }, []);

  return (
    <div className={`l-container ${loadingOrder ? 'finish-container' : ''}`}>
      {loadingOrder ? (
        <div className='finish-loading'>
          <Lottie animationData={cartLoading} loop={true} />
        </div>
      ) : (
        <div className='finish-card-container'>
          <div className='check-confirmation'>
            <Lottie animationData={checkConfirmation} loop={false} />
          </div>
          <h5 className='finish-card-title'>¡Pedido confirmado! </h5>
          <div className='finish-card-body'>
            <div className='finish-card-info'>
              <p className='finish-card-label'>Su pedido es el nro:</p>
              <p className='finish-card-number'>{order?.orderId}</p>
            </div>
            <div className='finish-card-info'>
              <p className='finish-card-label'>El pedido llegará el:</p>
              <p className='finish-card-text'>{order?.deliveryDay}</p>
            </div>
          </div>
          <div className='finish-card-rating'>
            <p className='finish-card-label'>Califica tu experiencia comprando el catálogo:</p>
            <Rating
              sx={{
                '& .MuiRating-iconEmpty': {
                  color: '#A5D3EB',
                },
              }}
              readOnly={disableRating}
              className='finish-rating-size'
              size='large'
              name='simple-controlled'
              value={rating}
              onChange={handleRating}
            />
          </div>
          <div className='finish-card-btns'>
            <Link to='/tienda' className='finish-card-btn'>
              Ir al inicio
            </Link>
            <a
            // target='_blank'
              rel='noreferrer'
              href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`. ${vedoraEnvironment}`)}`}
              className='finish-card-btn'>
              Ir a Whatsapp
            </a>
            <button 
              ref={whatsappButtonRef} 
              onClick={abrirWhatsApp} 
              style={{ display: "none" }} 
              aria-hidden="true"
            >
              Ir a Whatsapp 
            </button>
          </div>
          <div className='finish-card-footer'>
            <p className='finish-card-social-text'>Síguenos en:</p>
            <div className='finish-card-social'>
              <a target='_blank' rel='noreferrer' href='https://www.facebook.com/vedoraplus'>
                <img src={FacebookIcon} alt='Icono Facebook' />
              </a>
              <a target='_blank' rel='noreferrer' href='https://www.instagram.com/vedoraplus/'>
                <img src={InstagramIcon} alt='Icono Instragram' />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
