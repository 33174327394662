import { Box, Button, CircularProgress, Container, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SaveIcon from '@mui/icons-material/Save';
import { api } from '../services/axiosConfig';
import toast from 'react-hot-toast';

export default function AdminBrandsContainer() {
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [loading, setLoading] = useState(false)

  const getBrands = async () => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_GET_BRANDS_URL, {}, { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } });
      setLoadingBrands(false);
      setBrands(data.brands);
    } catch (err) {
      console.log(err);
    }
  };

  const onDragEnd = (results) => {
    const { source, destination } = results;

    if (!destination) return;

    if (source.index === destination.index && source.droppableId === destination.droppableId) return;

    const sortedBrands = reorder(brands, source.index, destination.index);

    setBrands(sortedBrands);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const columns = [
    {
      field: 'nombre',
      headerName: 'Marca',
      description: 'Nombre de la Marca',
    },
  ];

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    background: isDragging ? '#eee' : 'white',

    ...draggableStyle,
  });

  const handleSavePosition = async () => {
    setLoading(true);
    const params = {
      city: 'Default',
      officeId: '0',
      brands,
    };
    try {
      const { data } = await api.post(process.env.REACT_APP_UPDATE_BRANDS_URL, params);
      if (data.success) {
        toast.success('El orden de las marcas fue actualizado en la base de datos');
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography component="div" flexGrow="1">
              <Box fontSize="18px" fontWeight="500">
                Orden de visualización en el CATÁLOGO...
              </Box>
            </Typography>
            {
              loading ? <CircularProgress size={20}/> : ""
            }
            <Button
              variant="contained"
              sx={{ textTransform: 'none', color: 'white', marginLeft:'10px' }}
              onClick={() => {
                handleSavePosition();
              }}
              startIcon={<SaveIcon />}
            >

              
              Guardar
            </Button>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {loadingBrands ? (
            <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((col, index) => (
                        <TableCell key={index}>{col.headerName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="brands">
                    {(dropplableProvided) => (
                      <TableBody {...dropplableProvided.droppableProps} ref={dropplableProvided.innerRef}>
                        {brands.map((row, index) => (
                          <Draggable key={row} draggableId={row} index={index}>
                            {(draggableProvided, snapshot) => (
                              <TableRow {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps} style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}>
                                <TableCell>{row}</TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {dropplableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </TableContainer>
            </DragDropContext>
          )}
        </Paper>
      </Grid>
    </Container>
  );
}
