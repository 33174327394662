/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconEnd = ({ className }) => {
  return (
    <svg
      className={`icon-end ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.0191 18L11.1739 12.1546C10.7086 12.5388 10.1734 12.8395 9.56853 13.0566C8.9636 13.2738 8.33782 13.3823 7.69117 13.3823C6.10053 13.3823 4.75434 12.8316 3.65261 11.7302C2.55087 10.6288 2 9.28289 2 7.69256C2 6.10239 2.55071 4.75605 3.65214 3.65354C4.75357 2.55118 6.09944 2 7.68977 2C9.27995 2 10.6263 2.55087 11.7288 3.6526C12.8312 4.75434 13.3823 6.10053 13.3823 7.69117C13.3823 8.35581 13.2708 8.99059 13.0478 9.59552C12.8246 10.2004 12.5268 10.7266 12.1546 11.1739L17.9998 17.0191L17.0191 18ZM7.69117 11.9866C8.89031 11.9866 9.90597 11.5704 10.7381 10.7381C11.5704 9.90596 11.9866 8.89031 11.9866 7.69117C11.9866 6.49202 11.5704 5.47637 10.7381 4.64421C9.90597 3.8119 8.89031 3.39574 7.69117 3.39574C6.49202 3.39574 5.47637 3.8119 4.64421 4.64421C3.8119 5.47637 3.39574 6.49202 3.39574 7.69117C3.39574 8.89031 3.8119 9.90596 4.64421 10.7381C5.47637 11.5704 6.49202 11.9866 7.69117 11.9866Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};
