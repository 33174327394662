/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Cart } from "../../icons/Cart";
import "./style.css";

export const TypePrimaryStateWrapper = ({
  className,
  icon = <Cart className="type-base-10" color="#FDFDFD" />,
  divClassName,
  text = "Button",
}) => {
  return (
    <div className={`type-primary-state-wrapper ${className}`}>
      {icon}
      <div className={`button-2 ${divClassName}`}>{text}</div>
    </div>
  );
};

TypePrimaryStateWrapper.propTypes = {
  text: PropTypes.string,
};
