import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useAdminAuth } from '../context/AdminAuthContext';
import { adminDrawerItems } from '../utils';

import AdminMainContainer from '../containers/AdminMainContainer';
import AdminClientsContainer from '../containers/AdminClientsContainer';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AdminBannersContainer } from '../containers/AdminBannersContainer';
import { AdminProductsContainer } from '../containers/AdminProductsContainer';
import { AdminCatalogContainer } from '../containers/AdminCatalogContainer';
import { AdminBestProfitContainer } from '../containers/AdminBestProfitContainer';
import { AdminBestSellerContainer } from '../containers/AdminBestSellerContainer';
import { AdminMoreInterestingContainer } from '../containers/AdminMoreInterestingContainer';
import { AdminApisContainer } from '../containers/AdminApisContainer';
import AdminBrandsContainer from '../containers/AdminBrandsContainer';
import AdminPurchasesContainer from '../containers/AdminPurchasesContainer';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AdminDashboardPage = () => {
  const { admin, logOut } = useAdminAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [logoutCheck, setLogoutCheck] = useState(false);
  const [configPage, setConfigPage] = useState('DASHBOARD');

  const handleLogout = async () => {
    setLogoutCheck(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setLogoutCheck(false);
  };

  const handleConfirmButton = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {admin.email && (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                Panel de Gestión - Economysa
              </Typography>
              {admin?.email !== undefined && (
                <Box display="flex" justifyContent="center" alignContent="center" mx={2}>
                  <Typography component="div" color="white">
                    <Box mx={1} height="100%" display="flex" alignItems="center">
                      {admin.email}
                    </Box>
                  </Typography>
                  <AccountCircleIcon fontSize="large" sx={{ fill: 'white' }} />
                  <IconButton color="inherit" onClick={handleLogout}>
                    <LogoutIcon sx={{ fill: 'white' }} />
                  </IconButton>
                </Box>
              )}
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
              Opciones de Gestión
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem button onClick={() => setConfigPage('DASHBOARD')}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
              </ListItem>
            </List>
            <Divider />
            <List>
              {adminDrawerItems.map((item) => (
                <ListItem button key={item.id} onClick={() => setConfigPage(item.route)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
            <Divider />
            <ListSubheader inset>WhatsApp</ListSubheader>
            <List>
              <ListItem button onClick={() => setConfigPage('CLIENTS')}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListSubheader inset>Servicios</ListSubheader>
              <ListItem button onClick={() => setConfigPage('APIS')}>
                <ListItemIcon>
                  <ElectricalServicesIcon />
                </ListItemIcon>
                <ListItemText primary="APIs" />
              </ListItem>
            </List>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {
              {
                DASHBOARD: <AdminMainContainer navegate={setConfigPage} />,
                BANNERS: <AdminBannersContainer navegate={setConfigPage} />,
                MARCAS: <AdminBrandsContainer navegate={setConfigPage} />,
                PEDIDOS: <AdminPurchasesContainer navegate={setConfigPage} />,
                PRODUCTS: <AdminProductsContainer navegate={setConfigPage} />,
                CATALOGO: <AdminCatalogContainer navegate={setConfigPage} />,
                MASRENTABLES: <AdminBestProfitContainer navegate={setConfigPage} />,
                MASVENDIDOS: <AdminBestSellerContainer />,
                MASINTERESANTES: <AdminMoreInterestingContainer />,
                CLIENTS: <AdminClientsContainer />,
                APIS: <AdminApisContainer />,
              }[configPage]
            }
          </Box>

          <Dialog open={!!logoutCheck} onClose={handleClose}>
            <DialogTitle>¿Seguro que quieres desconectarte del Panel de Control?</DialogTitle>
            <DialogContent>
              <Box p={2}>
                <Typography component="div">
                  <Box m={1} display="flex">
                    <Box m={1} color="#999999">
                      Si te desconectas, ya no podrás seguir gestionando el Catálogo Online. Deberás volver a ingresar tu usuario y contraseña para tener acceso nuevamente.
                    </Box>
                  </Box>
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} sx={{ textTransform: 'none' }}>
                Cancelar
              </Button>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    color: '#fff',
                  }}
                  onClick={() => {
                    handleConfirmButton();
                  }}
                >
                  Confirmar
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default AdminDashboardPage;
