import { Routes, Route, Navigate } from 'react-router-dom';
import { AdminAuthContextProvider } from '../context/AdminAuthContext';

import AdminLoginPage from '../pages/AdminLoginPage';
import AdminDashboardPage from '../pages/AdminDashboardPage';
import AdminProtectedRoute from './AdminProtectedRoute';

const AdminRouter = () => {
  return (
    <AdminAuthContextProvider>
      <Routes>
        <Route
          path="/"
          element={
            <AdminProtectedRoute>
              <AdminDashboardPage />
            </AdminProtectedRoute>
          }
        />
        <Route path="/login" element={<AdminLoginPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </AdminAuthContextProvider>
  );
};

export default AdminRouter;
