
export const getPriceWithDiscount = (product, units, priceDiscount) => {

  //if (priceDiscount) return priceDiscount;
  
  // const qtyDiscounts = product?.dinamicDiscounts; //?.filter(item => item.type === 'qty')
  const amtDiscounts = product?.dinamicDiscounts;//?.filter(item => item.type === 'amt')

  const productPrice = product?.salePrice || ''

  // let qtyValidDiscount = qtyDiscounts?.map(item => {
  //   if (units >= item.minimumQuantity && units <= item.maximunQuantity) {
  //     return item.salePrice
  //   }
  //   return null
  // }).find(item => item)

  // qtyValidDiscount = qtyValidDiscount ? qtyValidDiscount : productPrice

  let amtValidDiscount = amtDiscounts?.map(item => {

    if (productPrice !== '') {
      const calculateAmount = units * productPrice

      //if (calculateAmount >= item.minimumAmount && calculateAmount <= item.maximunAmount) {
        const totalDiscount = (productPrice * item.priceDiscount) / 100
        return (productPrice - totalDiscount).toFixed(1)
      //}
      return null
    }

    return null
  }).find(item => item)

  amtValidDiscount = amtValidDiscount; //? amtValidDiscount : qtyValidDiscount

  if (amtValidDiscount) {
    return Number(amtValidDiscount)
  }

  return Number(productPrice)
}

export const getGanancia = (product, units, priceDiscount) => {
  return (((product.sugPrice * 100) / (product.salePrice * ((100 - priceDiscount) / 100)).toFixed(1)) - 100).toFixed(1)
}