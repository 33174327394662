import { Navigate } from 'react-router-dom';
import { useAdminAuth } from '../context/AdminAuthContext';

const AdminProtectedRoute = ({ children }) => {
  const { admin } = useAdminAuth();

  // console.log('Check admin in Private: ', admin);
  if (!admin) {
    return <Navigate replace to="/login" />;
  }
  return children;
};

export default AdminProtectedRoute;
