/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TopBanner = ({ className, divClassName, text = "Lorem ipsum dolor sit amet" }) => {
  return (
    <div className={`top-banner ${className}`}>
      <p className={`lorem-ipsum-dolor ${divClassName}`}>{text}</p>
    </div>
  );
};

TopBanner.propTypes = {
  text: PropTypes.string,
};
