import React from 'react'
import { CardInfoResume } from '../components/CardInfoResume'
import { CartList } from '../components/CartList'
import "../styles/CartResume.css"


export const CartResume = () => {
  return (
    <div className='l-container'>
      <div className='cart-container'>
        <div className='list-items'>
          <CartList editable={true}/>
        </div>
        <div className='resume-card'>
          <CardInfoResume/>
        </div>

      </div>
    </div>
  )
}
