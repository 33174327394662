import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, CircularProgress, Typography, Divider, Dialog, DialogTitle, DialogActions, Button, DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox, Tabs, Tab } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { MdDelete, MdEdit } from 'react-icons/md';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import { api } from '../services/axiosConfig';
import toast from 'react-hot-toast';
import { uploadNewImageBanner } from '../services/storageF';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player'
import { isImage, isVideo } from '../utils/typeOfUrl';

// const isImage = ['.gif','.jpg','.jpeg','.png']; //you can add more
// const isVideo =['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4'] 


export const AdminBannersContainer = () => {
  const [option, setOption] = useState('');
  const [datos, setDatos] = useState([]);
  const [bannerUpload, setBannerUpload] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [showModalItems, setShowModalItems] = useState(false);
  const [checked, setChecked] = useState([]);
  const [bannerEditar, setBannerEditar] = useState({});
  const [showRegister, setShowRegister] = useState(false);

  const [selectedTab, setSelectedTab] = useState(1);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedBannerImage, setSelectedBannerImage] = useState(null);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_GET_BANNER_URL, {
        headers: {
          'Content-Type': 'application/json',
          api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        },
      });
      setDatos(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProductsData = async () => {
    const { data } = await axios.post(
      process.env.REACT_APP_GET_PRODUCT_URL,
      {
        officeId: '37',
        storageId: '1',
        productId: 'adminProducts',
        priceListCode: '60',
      },
      { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } },
    );
    let products = data.products.map((dato) => ({ ...dato, id: uuidv4() }));
    setProductsData(products);
  };

  useEffect(() => {
    fetchData();
    fetchProductsData();
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setBannerEditar({});
    setBannerUpload([]);
    handleCloseConfirmDialog();
  };

  const handleCloseConfirmDialog = () => {
    setSelectedBannerImage(null);
    setShowConfirmationDialog(false);
  };

  const columns = [
    {
      field: 'officeId',
      headerName: 'Código',
      // flex: 1,
      width: 100,
      description: 'Código del Producto',
      sortable: false,
    },
    {
      field: 'city',
      headerName: 'Nombre Ubicación',
      flex: 1,
      // width: 200,
      description: 'Nombre Ubicación',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Acciones',
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="w-full flex text-2xl text-gray-500" style={{ cursor: 'pointer' }}>
            <MdEdit
              className="ml-2"
              onClick={() => {
                setBannerEditar(datos.find((banner) => banner.id === params.row.id));
                setShowModal(true);
              }}
            />
            <MdDelete className="ml-2" onClick={() => handleDeleteBanner(params.row.officeId)} />
          </div>
        );
      },
    },
  ];

  const columnsProducts = [
    {
      field: 'code',
      headerName: 'Código',
      flex: 1,
      // width: 200,
      description: 'Código del Producto',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Nombre largo',
      flex: 1,
      description: 'Nombre del Producto',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Acciones',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <Checkbox edge="end" onChange={handleToggle(params.row.code)} checked={checked.indexOf(params.row.code) !== -1} />;
      },
    },
  ];

  const handleActionBannerImageDelete = (id) => {
    setSelectedBannerImage(id);
    setShowConfirmationDialog(true);
  };

  const UpdateItems = (id, field, index) => {
    setSelectedBannerImage(id);
    setChecked(bannerEditar[field][index].relatedProductsIds || []);
    setShowModalItems(true);
  };

  const closeModalItems = () => {
    setShowModalItems(false);
    setChecked([]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleUpdateRelatedProducts = async (tab) => {
    const updateBanner = { ...bannerEditar };

    let typeBanner = tab === 1 ? 'topBanners' : 'mediumBanners';

    const bannerIndex = updateBanner[typeBanner].findIndex((banner) => banner.id === selectedBannerImage);

    if (bannerIndex === -1) return;

    updateBanner[typeBanner][bannerIndex].relatedProductsIds = checked;

    try {
      await axios.post(process.env.REACT_APP_UPDATE_BANNER_URL, updateBanner, { headers: { 'Content-Type': 'application/json', api_key: process.env.REACT_APP_ECONOMYSA_API_KEY } });

      closeModalItems();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDeleteImageBanner = async () => {
    try {
      const officeId = bannerEditar.officeId;
      const id = selectedBannerImage;

      const { data } = await api.post(process.env.REACT_APP_DELETE_IMAGEN_BANNER_ID_URL, { officeId, id });
      if (data.success) {
        setDatos([]);
        setBannerEditar({
          ...bannerEditar,
          topBanners: selectedTab === 1 ? bannerEditar?.topBanners?.filter((image) => image.id !== id) : bannerEditar?.topBanners,
          mediumBanners: selectedTab === 2 ? bannerEditar?.mediumBanners?.filter((image) => image.id !== id) : bannerEditar?.mediumBanners
        });
        handleCloseConfirmDialog();
        fetchData();
        toast.success('Banner Eliminado');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteBanner = async (officeId) => {
    try {
      const { data } = await api.post(process.env.REACT_APP_DELETE_BANNER_URL, { officeId });
      if (data.success) {
        toast.success('Banner Eliminado');
        setDatos([]);
        fetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCargarImageLocal = (images) => {
    let acumulador = bannerUpload.slice();
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        acumulador.push(images[i]);
      }
      setBannerUpload(acumulador);
    }
  };

  const handleAddImageBanner = async () => {
    let banners = [];
    if (bannerUpload.length > 0) {
      await Promise.all(
        bannerUpload.map(async (el) => {
          let url = await uploadNewImageBanner(el);
          banners.push({
            imgUrl: url,
            relatedProductsIds: []
          });
        }),
      );

      try {
        const { data } = await api.post(process.env.REACT_APP_CREATE_BANNER_URL, 
          { city: bannerEditar.city, officeId: bannerEditar.officeId, topBanners: selectedTab === 1 ? [...bannerEditar.topBanners, ...banners] : bannerEditar.topBanners , mediumBanners: selectedTab === 2 ? [...bannerEditar.mediumBanners, ...banners] : bannerEditar.mediumBanners }
        );

        if (data.success) {
          setDatos([]);
          handleClose();
          fetchData();
          toast.success('Imágenes agregadas correctamente');
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error('No hay cambios que agregar');
    }
  };

  const handleHideRegister = () => {
    setShowRegister(false);
    setBannerUpload([]);
    setOption('');
  };

  const handleRegisterImageBanner = async () => {
    if (option === '') {
      toast.error('Debes escoger una oficina');
      return;
    }
    const officeSelected = datos.find((banner) => banner.officeId === option);
    let banners = [];
    if (bannerUpload.length > 0) {
      await Promise.all(
        bannerUpload.map(async (el) => {
          let url = await uploadNewImageBanner(el);
          banners.push({
            imgUrl: url,
          });
        }),
      );

      try {
        const { data } = await api.post(process.env.REACT_APP_CREATE_BANNER_URL, { city: officeSelected.city, officeId: officeSelected.officeId, banners: [...officeSelected.banners, ...banners] });
        if (data.success) {
          setDatos([]);
          handleHideRegister();
          fetchData();
          toast.success('Imágenes agregadas correctamente');
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error('Debes agregar al menos una imagen');
    }
  };

  return (
    <section className=" mx-auto">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography component="div" flexGrow="1">
              <Box fontSize="18px" fontWeight="500">
                Banners
              </Box>
            </Typography>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <div className="flex justify-end mx-auto mt-2">
        <Button variant="outlined" onClick={() => setShowRegister(true)} sx={{ textTransform: 'none', marginLeft: 2 }}>
          Agregar
        </Button>
      </div>
      <div className="w-full mt-3">
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
            {datos.length === 0 ? (
              <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid rows={datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
            )}
          </Paper>
        </Grid>
        {/* Dialog that allows us to edit banners */}
        <Dialog open={showModal} onClose={handleClose} maxWidth="xl">
          <DialogTitle>
            Editar el banner de {bannerEditar.city} - {bannerEditar.officeId}
          </DialogTitle>

          {/* Dialog that shows actions availables */}
          <DialogContent style={{ maxHeight: 400 }}>
            <Tabs sx={{ marginBottom: '10px' }} value={selectedTab} onChange={handleChangeTab} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example" centered variant="scrollable" scrollButtons={true} allowScrollButtonsMobile>
              <Tab value={1} label="Banners Superiores" />
              <Tab value={2} label="Banners Inferiores" />
            </Tabs>

            {selectedTab === 1 ? (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="contained-button-file">
                    <input name="file[]" accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} onChange={(e) => handleCargarImageLocal(e.target.files)} />

                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '16px',
                      }}
                      endIcon={<UploadIcon />}
                    >
                      Cargar Imagenes
                    </Button>
                  </label>
                  {bannerUpload.length > 0 && (
                    <Button variant="outlined" onClick={() => setBannerUpload([])} sx={{ textTransform: 'none', marginLeft: 2 }}>
                      Borrar
                    </Button>
                  )}
                </div>
                {bannerUpload.map((el) => (
                  <p
                    style={{
                      fontSize: 14,
                      marginTop: 10,
                      color: '#005a87',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {el.name}
                  </p>
                ))}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px,1fr))',
                    gap: 20,
                    marginTop: 20,
                    cursor: 'pointer',
                  }}
                >
                  {bannerEditar.topBanners &&
                    bannerEditar.topBanners.map((el, index) => (
                      <div
                        key={index}
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '2px 5px',
                            backgroundColor: 'red',
                            color: '#FFF',
                            cursor: 'pointer',
                            borderRadius: '5px',
                          }}
                          onClick={() => handleActionBannerImageDelete(el.id)}
                        >
                          <p style={{ fontSize: '14px' }}>X</p>
                        </div>

                        {
                          isImage(el.imgUrl) ? (
                            <img style={{ width: '100%', border: '1px solid #eee' }} src={el.imgUrl} alt={el.imgUrl} onClick={() => UpdateItems(el.id, 'topBanners', index)} />
                          ) : ""
                        }

                        {
                          isVideo(el.imgUrl) ? (
                            <ReactPlayer url={el.imgUrl} width="100%" height="100%" onClick={() => UpdateItems(el.id, 'topBanners', index)} playing muted />
                          )
                          : ""
                        }
                      </div>
                    ))}
                </div>
              </>
            ) : (
              ''
            )}

            {selectedTab === 2 ? (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="contained-button-file">
                    <input name="file[]" accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} onChange={(e) => handleCargarImageLocal(e.target.files)} />

                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '16px',
                      }}
                      endIcon={<UploadIcon />}
                    >
                      Cargar Imagenes
                    </Button>
                  </label>
                  {bannerUpload.length > 0 && (
                    <Button variant="outlined" onClick={() => setBannerUpload([])} sx={{ textTransform: 'none', marginLeft: 2 }}>
                      Borrar
                    </Button>
                  )}
                </div>
                {bannerUpload.map((el) => (
                  <p
                    style={{
                      fontSize: 14,
                      marginTop: 10,
                      color: '#005a87',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {el.name}
                  </p>
                ))}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px,1fr))',
                    gap: 20,
                    marginTop: 20,
                    cursor: 'pointer',
                  }}
                >
                  {bannerEditar.mediumBanners &&
                    bannerEditar.mediumBanners.map((el, index) => (
                      <div
                        key={'seteado'}
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '2px 5px',
                            backgroundColor: 'red',
                            color: '#FFF',
                            cursor: 'pointer',
                            borderRadius: '5px',
                          }}
                          onClick={() => handleActionBannerImageDelete(el.id)}
                        >
                          <p style={{ fontSize: '14px' }}>X</p>
                        </div>

                        {
                          isImage(el.imgUrl) ? (
                            <img style={{ width: '100%', border: '1px solid #eee' }} src={el.imgUrl} alt={el.imgUrl} onClick={() => UpdateItems(el.id, 'mediumBanners', index)} />
                          ) : ""
                        }

                        {
                          isVideo(el.imgUrl) ? (
                            <ReactPlayer url={el.imgUrl} width="100%" height="100%" onClick={() => UpdateItems(el.id, 'mediumBanners', index)} playing muted />
                          )
                          : ""
                        }
                      </div>
                    ))}
                </div>
              </>
            ) : (
              ''
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose} sx={{ textTransform: 'none' }}>
              Cancelar
            </Button>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained" onClick={handleAddImageBanner} sx={{ textTransform: 'none' }} endIcon={<SaveIcon />}>
                Guardar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {/* Confirmation Delete Banner */}

        <Dialog open={showConfirmationDialog} onClose={handleCloseConfirmDialog} maxWidth="sm">
          <DialogTitle>Eliminar Banner</DialogTitle>
          <DialogContent>
            <Typography>¿Estas seguro de continuar?, al aceptar se eliminara el banner</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDeleteImageBanner()}>Aceptar</Button>
            <Button autoFocus onClick={handleCloseConfirmDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showModalItems} onClose={closeModalItems} fullWidth="true">
          <DialogTitle>Escoja los productos</DialogTitle>
          <DialogContent>
            {productsData.length === 0 ? (
              <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid rows={productsData} columns={columnsProducts} pageSize={10} rowsPerPageOptions={[10]} autoHeight disableMultipleSelection hideFooterSelectedRowCount density="compact" />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdateRelatedProducts(selectedTab)}>Aceptar</Button>
            <Button autoFocus onClick={closeModalItems}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showRegister} onClose={handleHideRegister} maxWidth="sm" fullWidth>
          <DialogTitle>Agregar un nuevo registro en el Banner</DialogTitle>
          <DialogContent style={{ maxHeight: 400 }}>
            <div className="my-3 mx-auto">
              <select className="block w-[100%] py-2 px-3 appearance-none outline-none border rounded text-center" value={option} onChange={({ target }) => setOption(target.value)}>
                <option disabled active value="">
                  -- Seleccionar --
                </option>
                {datos.map((banner) => (
                  <option key={banner.officeId} value={banner.officeId}>
                    {banner.city}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label
                htmlFor="contained-button-file"
                style={{
                  width: bannerUpload.length === 0 ? '100%' : null,
                }}
              >
                <input name="file[]" accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} onChange={(e) => handleCargarImageLocal(e.target.files)} />

                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '16px',
                    width: bannerUpload.length === 0 ? '100%' : null,
                  }}
                  endIcon={<UploadIcon />}
                >
                  Cargar Imagenes
                </Button>
              </label>
              {bannerUpload.length > 0 && (
                <Button variant="outlined" onClick={() => setBannerUpload([])} sx={{ textTransform: 'none', marginLeft: 2 }}>
                  Borrar
                </Button>
              )}
            </div>
            {bannerUpload.map((el, index) => (
              <p
                key={index}
                style={{
                  fontSize: 14,
                  marginTop: 10,
                  color: '#005a87',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {el.name}
              </p>
            ))}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleHideRegister} sx={{ textTransform: 'none' }}>
              Cancelar
            </Button>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained" onClick={handleRegisterImageBanner} sx={{ textTransform: 'none' }} endIcon={<SaveIcon />}>
                Guardar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};
