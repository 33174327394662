import { createTheme } from '@mui/material/styles';

const theme = createTheme({

  root:{
    fontFamily: "AmsiPro"
  },
  typography: {
    allVariants: {
      fontFamily: "AmsiPro"
    }
  },


});

export default theme;
