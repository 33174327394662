/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Timer = ({
  className,
  timerClassName,
  valueClassName,
  labelClassName,
  divClassName,
  valueClassNameOverride,
  labelClassNameOverride,
  divClassNameOverride,
  valueClassName1,
  labelClassName1,
  divClassName1,
  valueClassName2,
  labelClassName2,
}) => {
  return (
    <div className={`timer ${className}`}>
      <div className={`div ${timerClassName}`}>
        <div className="div-2">
          <div className={`value ${valueClassName}`}>00</div>
          <div className={`label ${labelClassName}`}>DIA</div>
        </div>
        <div className={`text-wrapper ${divClassName}`}>:</div>
        <div className="div-2">
          <div className={`value ${valueClassNameOverride}`}>00</div>
          <div className={`label ${labelClassNameOverride}`}>HRS</div>
        </div>
        <div className={`text-wrapper ${divClassNameOverride}`}>:</div>
        <div className="div-2">
          <div className={`value ${valueClassName1}`}>52</div>
          <div className={`label ${labelClassName1}`}>MIN</div>
        </div>
        <div className={`text-wrapper ${divClassName1}`}>:</div>
        <div className="div-2">
          <div className={`value ${valueClassName2}`}>24</div>
          <div className={`label ${labelClassName2}`}>SEG</div>
        </div>
      </div>
    </div>
  );
};
