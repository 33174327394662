import { Typography, Box, Grid, Container, Divider, Button } from '@mui/material';

const AdminMainContainer = (props) => {
  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1}>
              <Typography component="div">
                <Box fontWeight="500" fontSize="18px">
                  Inicio
                </Box>
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('BANNERS');
                }}
                width="100%"
              >
                Banners
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('PRODUCTS');
                }}
                width="100%"
              >
                Productos
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('PEDIDOS');
                }}
                width="100%"
              >
                Pedidos
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('MARCAS');
                }}
                width="100%"
              >
                Marcas
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('CATALOGO');
                }}
                width="100%"
              >
                Catálogo
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('MASRENTABLES');
                }}
                width="100%"
              >
                Más rentables
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('MASVENDIDOS');
                }}
                width="100%"
              >
                Más vendidos
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('MASINTERESANTES');
                }}
                width="100%"
              >
                Más interesantes
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('CLIENTS');
                }}
                width="100%"
              >
                Login
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              sx={{
                bgcolor: '#ffffff',
                boxShadow: '3',
                width: '100%',
                color: '#666666',
              }}
            >
              <Box
                p={4}
                fontWeight="500"
                fontSize="22px"
                color="#666666"
                onClick={() => {
                  props.navegate('APIS');
                }}
                width="100%"
              >
                APIs
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AdminMainContainer;
