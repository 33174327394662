import React from 'react'
import { FAQ } from './Faq'
import { FAQS } from '../utils'
import '../styles/FAQList.css'

export default function FAQList() {

  return (
    <div className='faq-list'>
      {
        FAQS.map(question => (
          <FAQ
            key={question.id}
            faq={question}
          />
        ))
      }
    </div>
  )
}
