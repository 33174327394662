import { createContext, useContext, useState } from 'react';

const UserAuthContext = createContext();

export const UserAuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userLoggedIn')) || {});

  const logIn = (userLoggedIn) => {
    localStorage.setItem('userLoggedIn', JSON.stringify(userLoggedIn));
    setUser(userLoggedIn);
  };

  const logOut = () => setUser({});

  const changeAddress = (addressCode) => {
    const newUser = {...user};
    localStorage.setItem('userLoggedIn',JSON.stringify(newUser))
    setUser(newUser)
  }
  

  return <UserAuthContext.Provider value={{ user, logIn, logOut, changeAddress }}>{children}</UserAuthContext.Provider>;
};

export const useUserAuth = () => {
  //console.log('usercontext: ', UserAuthContext);
  return useContext(UserAuthContext);
};
