import { createContext, useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { GET_BANNER_SERVICE } from '../services/bannerService';
import { PROMO_DATA } from '../utils/promos';


const ProductsContext = createContext();

export const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([])
  const [moreRentability, setMoreRentability] = useState([])
  const [moreInteresting, setMoreInteresting] = useState([])
  const [searchProducts, setSearchProducts] = useState([])
  const [banners, setBanners] = useState({ topBanners: [], mediumBanners: []})

  const getProducts = useCallback(async (user) => {
    //console.log('usuario logeado3: ', user);
    
    
    const data = JSON.stringify({
      officeId: user.officeId,
      storageId: user.storageId,
      productId: '*.*',
      priceListCode: user.priceListCode,
      clientMobilePhone: user.mobilePhone,
      addressCode: user.addressId
    });
    const cdata = {
      priceId: user.priceListId// Aquí 'parametro' es la clave y 'valorString' es el valor de tipo string.
    };
    
    
    const config = {
      method: 'post',
      url: process.env.REACT_APP_GET_PRODUCT_URL,
      headers: {
        api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        'Content-Type': 'application/json',
      },
      data: cdata,
    };
    
    try {
      const res = await axios(config);
      console.log('prod context: ', res.data.products.length);
      //console.log('prod context Canal: ', res.data.products[0]?.saleMin?? '');
      let products = res.data.products;     
      let brands = res.data.brands;
      if (res.data.products.length > 0)
        setProducts(res.data.products);
      const config2 = {
        method: 'get',
        url: process.env.REACT_APP_GET_PROMOTIONS_URL,
        headers: {
          api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
          'Content-Type': 'application/json',
        },
        data: data,
      };
  
      var res2 = await axios(config2);
      res2 = res2.data;
      console.log("promociones from getproducts");
      console.log(res2);
      var promotions2 = res2;
      for (let i = 0; i < promotions2.length; i++) {
        const promotion2 = promotions2[i];
        for (let j = 0; j < promotion2.products.length; j++) {
          const product = promotion2.products[j];
          if (products.findIndex((item) => item.codeProduct === product.codeProduct) !== -1) {
            products[products.findIndex((item) => item.codeProduct === product.codeProduct)].promotions.push({
              code: promotion2.code,
              promotion: promotion2.mechanics,
              dateStart: promotion2.dateStart,
              dateEnd: promotion2.dateEnd,
              timeStart: promotion2.timeStart,
              timeEnd: promotion2.timeEnd,
              promoType: promotion2.promoType,
            });
          }
        }
      }

      for (const element of products)
      {   
        element.dinamicDiscounts = promotions2.filter(discount => discount.products[0].codeProduct == element.code);
        element.dinamicDiscounts = element.dinamicDiscounts.length > 0 ? element.dinamicDiscounts : element.dinamicDiscounts;
        for (const element2 of element.dinamicDiscounts) {
          console.log("descuento encontrado");          
          element2.salePrice = Number(((100 - element2.priceDiscount) * element.salePrice / 100).toFixed(1));
          console.log(element2);
        }
      }

          // dispatch({
          // 	type: GET_ALL_PRODUCTS_EXITO,
          // 	payload: {
          // 		products: products,
          // 		promotions: promotions,
          // 		categories: allCategories,
          // 		bestSellerProducts: bestSellerProducts,
          // 		rentabilityProducts: rentabilityProducts,
          // 		interestingProducts: interestingProducts,
          // 		brandsOrdered: brands,
          // 	},
          // });

          // localStorage.setItem(
          // 	"products",
          // 	JSON.stringify(products)
          // );
          // localStorage.setItem(
          // 	"promotions",
          // 	JSON.stringify(promotions)
          // );
          // localStorage.setItem(
          // 	"allCategories",
          // 	JSON.stringify(allCategories)
          // );
          // localStorage.setItem(
          // 	"bestSellerProducts",
          // 	JSON.stringify(bestSellerProducts)
          // );
    
      // ************************
      getBestSeller(products)
      getMoreRentability(products)
      getMoreInteresting(products)
      setProducts(products);
      setBrands(brands)

      return products;
    } catch (error) {
      console.log(error);
      return error;
    }
  }, []);

  const getBanners = async (user) => {
    try {
      const rpta = await GET_BANNER_SERVICE();
      if (rpta.status === 200) {
      
          const storageBanners = rpta.data.filter((item) => item.officeId === user.storageId);

          if (storageBanners.length === 0) {
            const defaultBanners = rpta.data.find((item) => item.officeId === '0');
            setBanners({
              topBanners: defaultBanners.topBanners,
              mediumBanners: defaultBanners.mediumBanners
            })
          }
          
          setBanners({
            topBanners: storageBanners[0].topBanners,
            mediumBanners: storageBanners[0].mediumBanners
          })
        
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  

  function getCategories(products, allCategories = []) {
    // eslint-disable-next-line no-debugger
    for (let i = 0; i < products.length; i++) {
      const element = products[i];

      let index = allCategories.findIndex((item) => item.name === element.categoria);
      if (index === -1) {
        allCategories.push({
          name: element.categoria,
          subcategories: [],
        });
        index=allCategories.length-1;
       } 
      if (allCategories[index].subcategories.findIndex((item) => item.name === element.subcategoria) === -1) {
        allCategories[index].subcategories.push({
          name: element.subcategoria,
        });
      }
    }
    setCategories(allCategories);
    return allCategories;
  }

  function getBrands(products, brands = []) {
    for (let i = 0; i < products.length; i++) {
      const element = products[i];

      let index = brands.findIndex((item) => item.name === element.marca);
      if (index === -1) {
        brands.push({
          name: element.marca
        });
      }
    }

    setBrands(brands);
    return brands;
  }

  const getPromotions = async (user) => {
    let promotions = [];
    const data = JSON.stringify({
      officeId: user.officeId,
      storageId: user.storageId,
      priceListCode: user.priceListCode,
      clientCode: user.clientCode,
      addressCode: user.addressId
    });

    const config = {
      method: 'get',
      url: process.env.REACT_APP_GET_PROMOTIONS_URL,
      headers: {
        api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    var response = await axios(config);
    response = response.data;      
    console.log("promociones");
    console.log(response);
    promotions = response;
    for (let i = 0; i < promotions.length; i++) {
      const promotion = promotions[i];
      for (let j = 0; j < promotion.products.length; j++) {
        const product = promotion.products[j];
        if (products.findIndex((item) => item.codeProduct === product.codeProduct) !== -1) {
          products[products.findIndex((item) => item.codeProduct === product.codeProduct)].promotions.push({
            code: promotion.code,
            promotion: promotion.mechanics,
            dateStart: promotion.dateStart,
            dateEnd: promotion.dateEnd,
            timeStart: promotion.timeStart,
            timeEnd: promotion.timeEnd,
            promoType: promotion.promoType,
          });
        }
      }
    }
    console.log("getpromotions");
    console.log(promotions);
    setPromotions(promotions)
      
      // .catch(function (error) {
      //   // promotions = PROMO_DATA.data;
      //   // for (let i = 0; i < promotions.length; i++) {
      //   //   const promotion = promotions[i];
      //   //   for (let j = 0; j < promotion.products.length; j++) {
      //   //     const product = promotion.products[j];
      //   //     if (products.findIndex((item) => item.codeProduct === product.codeProduct) !== -1) {
      //   //         [products.findIndex((item) => item.codeProduct === product.codeProduct)].promotions.push({
      //   //         code: promotion.code,
      //   //         promotion: promotion.mechanics,
      //   //         dateStart: promotion.dateStart,
      //   //         dateEnd: promotion.dateEnd,
      //   //         timeStart: promotion.timeStart,
      //   //         timeEnd: promotion.timeEnd,
      //   //         promoType: promotion.promoType,
      //   //       });
      //   //     }
      //   //   }
      //   // }
      //   // setPromotions(promotions)
      //   console.error(error);
      // });
  }
  
  const getCategoriesSearch = (products) => {

    let allCategories = [];

    for (let i = 0; i < products.length; i++) {
      const element = products[i];

      let index = allCategories.findIndex((item) => item.name === element.categoria);
      if (index === -1) {
        allCategories.push({
          name: element.categoria,
          subcategories: [],
        });
      } else {
        if (allCategories[index].subcategories.findIndex((item) => item.name === element.subcategoria) === -1) {
          allCategories[index].subcategories.push({
            name: element.subcategoria,
          });
        }
      }
    }

    return allCategories;
  }
  
  const filterProductsByCategory = (products, categories, category, subcategory) => {
    try {
      // let products = getState().productsCollection.products.slice();
      if (products.length === 0) {
        products = JSON.parse(localStorage.getItem('products')) || '[]';
      }
      // let categories = getState().productsCollection.categories;
      if (categories.length === 0) {
        categories = JSON.parse(localStorage.getItem('allCategories')) || '[]';
      }
      category = category.split('-').join(' ');
      subcategory = subcategory.split('-').join(' ');
      let subCategories = categories[categories.findIndex((item) => item.name.toUpperCase() === category.toUpperCase())].subcategories;
      let subCategoryIndex = subCategories.findIndex((item) => item.name.toUpperCase() === subcategory.toUpperCase());
      let filteredProducts = products.filter((item) => item.categoria.toUpperCase() === category.toUpperCase());
      if (subcategory.toUpperCase() !== 'HOME') {
        filteredProducts = filteredProducts.filter((item) => item.subcategoria.toUpperCase() === subcategory.toUpperCase());
      }
      let maxPrice = filteredProducts.reduce(function (prev, current) {
        return prev.salePrice > current.salePrice ? prev : current;
      }).salePrice;
      let minPrice = filteredProducts.reduce(function (prev, current) {
        return prev.salePrice < current.salePrice ? prev : current;
      }).salePrice;
      // dispatch({
      // 	type: FILTER_PRODUCTS_BY_CATEGORY,
      // 	payload: {
      // 		category: category,
      // 		categories: categories,
      // 		subCategory: subcategory,
      // 		subCategories: subCategories,
      // 		subCategoryIndex: subCategoryIndex,
      // 		filteredProducts: filteredProducts,
      // 		minPrice: minPrice,
      // 		maxPrice: maxPrice,
      // 	},
      // });
      // localStorage.setItem(
      // 	"filteredProducts",
      // 	JSON.stringify(filteredProducts)
      // );
      console.log({filtrado: filteredProducts});
      setFilteredProducts(filteredProducts);
      return {
        category: category,
        categories: categories,
        subCategory: subcategory,
        subCategories: subCategories,
        subCategoryIndex: subCategoryIndex,
        // filteredProducts: filteredProducts,
        minPrice: minPrice,
        maxPrice: maxPrice,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const filterProductsByBrand = (products, categories, brand, subcategory) => {
    try {
      // let products = getState().productsCollection.products.slice();
      if (products.length === 0) {
        products = JSON.parse(localStorage.getItem('products')) || '[]';
      }

      // let categories = getState().productsCollection.categories;

      if (categories.length === 0) {
        categories = JSON.parse(localStorage.getItem('allCategories')) || '[]';
      }

      brand = brand.split('-').join(' ');

      subcategory = subcategory.split('-').join(' ');

      let filteredProducts = products.filter((item) => item.marca.toUpperCase() === brand.toUpperCase());

      let subCategories = [];
      for (let i = 0; i < filteredProducts.length; i++) {
        if (subCategories.filter((item) => item.name === filteredProducts[i].subcategoria).length === 0) {
          subCategories.push({ name: filteredProducts[i].subcategoria });
        }
      }

      let subCategoryIndex = subCategories.findIndex((item) => item.name.toUpperCase() === subcategory.toUpperCase());

      if (subcategory.toUpperCase() !== 'HOME') {
        filteredProducts = filteredProducts.filter((item) => item.subcategoria.toUpperCase() === subcategory.toUpperCase());
      }

      let maxPrice = filteredProducts.reduce(function (prev, current) {
        return prev.salePrice > current.salePrice ? prev : current;
      }).salePrice;
      let minPrice = filteredProducts.reduce(function (prev, current) {
        return prev.salePrice < current.salePrice ? prev : current;
      }).salePrice;

      // dispatch({
      // 	type: FILTER_PRODUCTS_BY_BRAND,
      // 	payload: {
      // 		brand: brand,
      // 		categories: categories,
      // 		subCategory: subcategory,
      // 		subCategories: subCategories,
      // 		subCategoryIndex: subCategoryIndex,
      // 		filteredProducts: filteredProducts,
      // 		minPrice: minPrice,
      // 		maxPrice: maxPrice,
      // 	},
      // });

      // console.log('filterProductsByBrand\nproductosDuck\n')
      // console.log('categories', categories)
      // console.log('subcategory', subcategory)
      // console.log('subCategories', subCategories)
      // console.log('subCategoryIndex', subCategoryIndex)
      // console.log('filteredProducts', filteredProducts)
      // console.log('minPrice', minPrice)
      // console.log('maxPrice', maxPrice)

      // localStorage.setItem(
      // 	"filteredProducts",
      // 	JSON.stringify(filteredProducts)
      // );

      setFilteredProducts(filteredProducts);
      return {
        brand: brand,
        categories: categories,
        subCategory: subcategory,
        subCategories: subCategories,
        subCategoryIndex: subCategoryIndex,
        // filteredProducts: filteredProducts,
        minPrice: minPrice,
        maxPrice: maxPrice,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getBestSeller = (products) => {
    const newProducts = products
      .filter((item) => item.clasificacion === '1' && item.stockAmount !== 0)
      .sort((a, b) => {
        if (a.numberBestSeller > b.numberBestSeller) return 1;
        if (a.numberBestSeller < b.numberBestSeller) return -1;
        return 0;
      });

    setBestSellers(newProducts)
  };

  const getMoreRentability = (products) => {
    const newProducts = products
      .filter((item) => item.clasificacion === '2' && item.stockAmount !== 0)
      .sort((a, b) => {
        if (a.margin > b.margin) return -1;
        if (a.margin < b.margin) return 1;
        return 0;
      });
      // console.log(JSON.stringify(products));
      // console.log(JSON.stringify(newProducts));
    setMoreRentability(newProducts)
  };

  const getMoreInteresting = (products) => {
    const newProducts = products
      .filter((item) => item.clasificacion === '3' && item.stockAmount !== 0)
      .sort((a, b) => {
        if (a.numberMoreInteresting > b.numberMoreInteresting) return 1;
        if (a.numberMoreInteresting < b.numberMoreInteresting) return -1;
        return 0;
      });

    setMoreInteresting(newProducts)
  };

  return (
    <ProductsContext.Provider
      value={{
        products,
        categories,
        brands,
        search,
        banners,
        promotions,
        searchProducts,
        bestSellers,
        moreRentability,
        moreInteresting,
        filteredProducts,
        setProducts,
        setBrands,
        setPromotions,
        getProducts,
        getCategories,
        getPromotions,
        getBrands,
        filterProductsByCategory,
        setSearch,
        setFilteredProducts,
        setSearchProducts,
        filterProductsByBrand,
        getCategoriesSearch,
        getBestSeller,
        getMoreRentability,
        getMoreInteresting,
        getBanners
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => {
  return useContext(ProductsContext);
};
