/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Categories } from "../../icons/Categories";
import { Home } from "../../icons/Home";
import { Offer } from "../../icons/Offer";
import { TypeBase7 } from "../../icons/TypeBase7";
import { TypePrimaryStateWrapper } from "../TypePrimaryStateWrapper";
import "./style.css";

export const AppBarOffer = ({ className, typePrimaryStateWrapperIcon = <Offer className="offer-instance" /> }) => {
  return (
    <div className={`app-bar-offer ${className}`}>
      <Home className="icon-instance-node" />
      <TypePrimaryStateWrapper
        className="type-primary-state-default-icon-left"
        divClassName="type-primary-state-instance"
        icon={typePrimaryStateWrapperIcon}
        text="Ofertas"
      />
      <Categories className="icon-instance-node" />
      <TypeBase7 className="icon-instance-node" />
    </div>
  );
};
