import MainLayout from '../layout/MainLayout';
import { Typography, Box, Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useState, useEffect } from 'react';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { toTitleCase } from '../utils';
import { useUserAuth } from '../context/UserAuthContext';
import { DataGrid } from '@mui/x-data-grid';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Tienda_Azul from '../assets/Tienda_Azul.png';
import Perfil_cliente from '../assets/Perfil_cliente.png';
import Perfil_cliente_blanco from '../assets/Perfil_cliente_blanco.png';
import Direcciones from '../assets/Direcciones.png';
import Direcciones_blanco from '../assets/Direcciones_blanco.png';
import Historial_blanco from '../assets/Historial_blanco.png';
import Historial from '../assets/Historial.png';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../styles/UserProfilePage.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddIcon from '@mui/icons-material/Add';
import { yellow } from '@mui/material/colors';
import { textAlign } from '@mui/system';


const UserProfilePage = () => {
  const { user } = useUserAuth();
  const [selected, setSelected] = useState('perfil');
  const [seeAll, setSeeAll] = useState(false)

  console.log(user)

  const columnsRecord = [
    { field: 'id', headerName: 'id', width: 100,
      headerClassName: 'super-app-theme--header',
    renderCell: (params) => {       
      return (
        <div className="w-full flex " style={{ justifyContent: 'space-between' }}>  
          <p style = {{ color: '#2D85C5'}}>
            {params.row.id}
          </p>                                   
        </div>
      );
    }
  
  },
    { field: 'fecha', headerName: 'fecha',flex: 1, headerClassName: 'super-app-theme--header', },
    { field: 'compra', headerName: 'compra',flex: 1, headerClassName: 'super-app-theme--header', },
    {
      field: 'total',
      headerName: 'total',
      sortable: false,      
      disableClickEventBubbling: true,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params) => {       
        return (
          <div className="w-full flex " style={{justifyContent: 'space-between' }}>  
            <p style={{fontWeight: 700, marginRight: -140}}>
              {params.row.total}              
            </p>
            <p>
              {params.row.siguiente}
            </p>
                 
            <Button
              sx={{background: '#21aee0',cursor: 'pointer', fontWeight: 700}}
              variant = 'contained'
              className="ml-4"
              onClick={() => {
                
              }}>
                Ver pedido
              </Button>                
          </div>
        );
      },
    }   
  ];
  
  const rowsRecord = [
    { id: 1, fecha: 'Mayo 19,222', compra: 'N°5116490806', total: 'S/324.99', siguiente: 'de 8 items'},
    { id: 2, fecha: 'Mayo 20,222', compra: 'N°5116490805', total: 'S/324.99', siguiente: 'de 7 items' },
    { id: 3, fecha: 'Mayo 21,222', compra: 'N°5116490804', total: 'S/324.99', siguiente: 'de 6 items' },
    { id: 4, fecha: 'Mayo 22,222', compra: 'N°5116490803', total: 'S/324.99', siguiente: 'de 5 items' },
    { id: 5, fecha: 'Mayo 23,222', compra: 'N°5116490802', total: 'S/324.99', siguiente: 'de 4 items' },
    { id: 6, fecha: 'Mayo 24,222', compra: 'N°5116490801', total: 'S/324.99', siguiente: 'de 3 items' },
    { id: 7, fecha: 'Mayo 25,222', compra: 'N°5116490800', total: 'S/324.99', siguiente: 'de 2 items' },
    { id: 8, fecha: 'Mayo 26,222', compra: 'N°5116490809', total: 'S/324.99', siguiente: 'de 1 items' },
    { id: 9, fecha: 'Mayo 27,222', compra: 'N°5116490808', total: 'S/324.99', siguiente: 'de 0 items' },
  ];

  const slicedArray = rowsRecord.slice(0, 4);
  const restArray = rowsRecord.slice(4)

  function createData(id, ubicacion) {
    return { id, ubicacion };
  }
  
  const rows = [
    createData(1, 'Calle el corregidor 456, La Molina'),
    createData(2, 'Av. Los ingenieros 521, Surco'),
    createData(3,'Calle Los diamantes 252, La Victoria'),
  ];

  const changeSelected = (selected) => {
    setSelected(selected);    
  };

  const changeRecords = () => {
    setSeeAll(true);    
  };

  return (
    <MainLayout access="user">
      <div className='l-container'>
      <Typography component="div" sx = {{ display: {xs:'none', md: 'block'}}}>
              <Box fontSize="40px" fontWeight="900" color={'#21AEE0'} textAlign="center" marginTop={10}>
                MI CUENTA
              </Box>
      </Typography>

      <Typography component="div" sx = {{ display: {xs:'block', md: 'none'}}}>
              <Box fontSize="22px" fontWeight="900" color={'#21AEE0'} textAlign="center" marginTop={2}>
                Perfil de cliente
              </Box>
      </Typography>


      <Box
      sx={{
        display: {xs:'none', md: 'flex'},
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 10,
          width: 394,
          height: 214,
        },
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper variant="outlined" sx = {{display: {xs:'none', md: 'block'}, background: selected == 'perfil' ? '#21AEE0': '#F4F4F4',color: selected == 'perfil' ? '#F4F4F4': '#21AEE0'}}>
        <div className= "fondoIconoNombre" onClick={() => changeSelected('perfil')} >
        {selected == 'perfil' ? <Box component="img" src={Perfil_cliente_blanco} alt="Perfil_cliente_blanco"/> : <Box component="img" src={Perfil_cliente} alt="Perfil_cliente"/> }
          <Typography fontSize="35px" fontWeight={900}>
            Perfil del Cliente
          </Typography>
        </div>
      </Paper>
      <Paper variant="outlined" sx = {{display: {xs:'none', md: 'block'},background: selected == 'direccion' ? '#21AEE0': '#F4F4F4', color: selected == 'direccion' ? '#F4F4F4': '#21AEE0'}}>
        <div className= "fondoIconoNombre" onClick={() => changeSelected('direccion')}>
        {selected == 'direccion' ? <Box component="img" src={Direcciones_blanco} alt="Direcciones_blanco"/> : <Box component="img" src={Direcciones} alt="Direcciones"/> }
          <Typography fontSize="35px" fontWeight={900}>
            Direcciones
          </Typography>
        </div>
      </Paper>
      {/* <Paper variant="outlined" sx = {{display: {xs:'none', md: 'block'},background: selected == 'historial' ? '#21AEE0': '#F4F4F4', color: selected == 'historial' ? '#F4F4F4': '#21AEE0'}}>
        <div className= "fondoIconoNombre" onClick={() => changeSelected('historial')}>
        {selected == 'historial' ? <Box component="img" src={Historial_blanco} alt="Historial_blanco"/> : <Box component="img" src={Historial} alt="Historial"/> }
          <Typography fontSize="35px" fontWeight={900}>
            Historial
          </Typography>
        </div>
      </Paper>       */}
    </Box>
    {selected === 'perfil' ?

    <div className='l-container mostrarWeb'>
    <div>
        <div className='backgroundIcono'>
        <PersonIcon className='MuiSvgIcon'/>
          <div className='iconoTexto'>
            <PhotoCameraIcon></PhotoCameraIcon>
            <p className='textoCambiarFoto'>Cambiar foto</p>            
          </div>
        </div>
        <div className='nombrePerfil'>
      {user.name}
    </div>
    <div>
      <div className='codigoCliente'>
        Cod. Cliente
        <span style = {{fontWeight: '700'}}> {toTitleCase(user.clientCode)}</span>
      </div>      
    </div>               
    </div>


  </div> : selected === 'direccion' ? 

    <div className='l-container mostrarWeb'>
    <TableContainer sx = {{display: {xs:'none', md: 'block'}}}>
      <Table sx={{ minWidth: 650, position: 'relative', left: '25%', width: '750px'}} aria-label="simple table">
        <TableHead>
          <TableRow className='headRow'>
            <TableCell>Tienda</TableCell>
            <TableCell align="left" sx= {{color: '#2D85C5',opacity: 1}}>Ubicacion</TableCell>            
          </TableRow>
        </TableHead>
        <TableBody>
          {user.deliveryAddresses.map((row,index) => (
            <TableRow key={row.code} className= 'bodyRow'>
              <TableCell component="td" scope="row" sx= {{color: '#2D85C5', textAlign: 'center'}}>
                #{index +1}
              </TableCell>
              <TableCell align="left" sx = {{color: '#707070'}}>{row.address}</TableCell>                                    
            </TableRow>
          ))}
          {/* <TableRow className='bodyRow'>
          <TableCell component="td" scope="row">         
              </TableCell>              
              <TableCell align="left" component="td" scope="row" sx = {{color: '#2D85C5', cursor: 'pointer'}}>
                + añadir nueva dirección
              </TableCell>                
          </TableRow>      */}
          
        </TableBody>
      </Table>
    </TableContainer>
    </div> : 

    <div className='l-container mostrarWeb'>
    <div style = {{width: '100%'}}>
    <DataGrid rows={rowsRecord} columns={columnsRecord} pageSize={18} rowsPerPageOptions={[18]} autoHeight hideFooterSelectedRowCount sx={{ '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {fontWeight: 700}, fontSize: '25px',color: '#707070','& .super-app-theme--header': {
          color: '#2d85c5'  
        }}} />
    </div>    
    </div> 
  }

<div className='l-container mostrarMobile'>
    <div>
        <div className='backgroundIcono'>
        <PersonIcon id= "MuiSvgIcon"/>
          <div className='iconoTexto'>
            <PhotoCameraIcon></PhotoCameraIcon>
            <p className='textoCambiarFoto'>Cambiar foto</p>
            
          </div>
        </div>                
    </div>

    <Typography component="div" sx = {{ display: {xs:'block', md: 'none'}}}>
              <Box fontSize="25px" fontWeight="900" color={'#2D85C5'} textAlign="center">
                 {user.name}
              </Box>
      </Typography>
    
    <Typography component="div">
        <Typography component="div" fontSize="20px" fontWeight="700" color={'#21AEE0'} textAlign="center">
              Cod. Cliente: {toTitleCase(user.clientCode)}              
        </Typography>              
    </Typography>
    
    
  </div> 

      <Typography component="div" sx = {{ display: {xs:'block', md: 'none', marginTop: '30px'}}}>
              <Box fontSize="22px" fontWeight="900" color={'#21AEE0'} textAlign="center">
                Mis direcciones
              </Box>
      </Typography>

      <Carousel
  additionalTransfrom={0}
  arrows = {false}
  autoPlaySpeed={3000}
  centerMode={false}
  className="mostrarMobile"
  containerClass="container"
  dotListClass=""
  draggable = 'true'
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{   
    mobile: {
      breakpoint: {
        max: 768,
        min: 0
      },
      items: 2,
      partialVisibilityGutter: 40, 
    }    
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=""
  slidesToSlide={1}
  swipeable

>

{user.deliveryAddresses.map((row,index) => (
            <div>
              <div className='direccionMobile'>
                <div style={{display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' , alignItems: 'center'}}>
                  <Box component="img" src={Tienda_Azul} alt="Tienda_Azul" width={30} height = {30} />
                  <div className='textoTienda'>
                    tienda # {index +1}                    
                  </div>
                </div>              
                  <div className='textoDireccion'>
                    {row.address}
                  </div>   
              </div>                    
          </div>         
         
))}
          {/* <div >
              <div className='agregarTienda'>
              <AddIcon id= "iconoAgregar"/>
                  <div className='textoAgregarTienda'>
                    Agregar dirección                    
                  </div>                   
              </div>                    
          </div>                 */}

</Carousel>

      {/* <Typography component="div" sx = {{ display: {xs:'block', md: 'none', marginTop: '20px', marginBottom: '10px'}}}>
              <Box fontSize="22px" fontWeight="900" color={'#21AEE0'} textAlign="center" marginBottom>
                Mi historial de pedidos
              </Box>
      </Typography>

      {slicedArray.map((row) => (
                <div className='l-container mostrarMobile'>
                    <div className='pedidoMobile'>
                    <Box component="img" src={Historial} alt="Historial" height={30} width= {30} marginLeft= {5} />
                      <div>
                        <div className='textoFecha'>
                          Entregado el {row.fecha}
                        </div>
                        <div className='textoCompra'>
                          Compra {row.compra}

                        </div>                   
                      </div>                    
                    </div>
                    
              </div>   
      ))     
      }
      {
         seeAll === false ? 
         
         <Button
         sx={{background: 'white', left: '30%', fontSize: 13, fontWeight: 900}}
         variant = 'outlined'
         className="ml-4"
         id = 'noDesktop'
         onClick={() => changeRecords()}>
           Ver todo
         </Button>
         
         : restArray.map((row) => (
          <div className='l-container mostrarMobile'>
              <div className='pedidoMobile'>
              <Box component="img" src={Historial} alt="Historial" height={30} width= {30} marginLeft= {5} />
                <div>
                  <div className='textoFecha'>
                    Entregado el {row.fecha}
                  </div>
                  <div className='textoCompra'>
                    Compra {row.compra}
  
                  </div>                   
                </div>                    
              </div>
              
        </div>   
  ))
      } */}
      </div>   
    </MainLayout>
  );
};

export default UserProfilePage;
