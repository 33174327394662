import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const AdminHeader = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="relative"
        sx={{
          // mt: 'auto',
          backgroundColor: 'var(--primary-color)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'center' }}>
          <img
            style={{
              width: '30px',
            }}
            src={'/assets/images/NoDisponible.png'}
            alt=""
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AdminHeader;
