import '../styles/UserPromoPage.css'
import axios from 'axios'
import MainLayout from '../layout/MainLayout'
import ProductPromo from '../components/ProductPromo'
import CountDown from '../components/CountDown'
import SpinnerTeresa from '../components/SpinnerTeresa'

import { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthContext'
import { useProducts } from '../context/ProductsContext'

const UserPromoPage = ({ nextInfinity }) => {
  const { user } = useUserAuth()
  const { products, promotions, getProducts, getPromotions, setPromotions } = useProducts()

  useEffect(() => {
    if (promotions.length === 0) {
      getPromotions(user)
      //setPromotions(promotions)
    }
    if (products.length === 0) {
      getProducts(user)
    }
    // console.log('promotions luego de useEffect', promotions);
  }, [])

  return (
    <>
      {products.length === 0 && <SpinnerTeresa />}
      {promotions.length === 0 && <SpinnerTeresa />}
      <MainLayout access='user' nextInfinity={nextInfinity}>
        <div className='promocion__container fadeIn'>
          <div className='l-container'>
            <div className='promocion__title'>
              <p>¡Ofertas para {user.shortName ? ` ${user.shortName}` : ''}!</p>
              <p style={{
                //styleName: Body/body-regular-md;
                
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                textAlign: 'center'
              }}>¡Aprovecha nuestras ofertas exclusivas y ahorra más!</p>
            </div>
            
            <div className='promocion'>
              {promotions.length > 0 &&
                promotions.map((item, index) => (
                  // <div key={item.code} className="promocion__item">

                  <>
                    {item.products.map((productItem) => {
                      const rpta = products.filter(
                        (item) => item.code === productItem.codeProduct && item.stockAmount !== 0,
                      )

                      return (
                        <>
                          {rpta.map((el) => (
                            <div key={el.code} className='promocion__item'>
                              <div className='promocion__item--body'>                                
                                <div className='promocion__item--body__producto'>
                                  <ProductPromo product={el} promo={item} />
                                </div>
                                <CountDown dateEnd={item.dateEnd + ' ' + item.timeEnd} />
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    })}
                  </>
                ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export default UserPromoPage
