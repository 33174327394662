import { useState, useEffect } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

import UserRouter from './UserRouter';
import AdminRouter from './AdminRouter';
import { getSubdomain } from '../utils';

const AppRouter = () => {
  const [route, setRoute] = useState('INIT');
  const [subdomain, setSubdomain] = useState("INIT");


  useEffect(() => {
    let newSubdomain = window.location.href;
    if (newSubdomain.split("://").length > 1) {
      newSubdomain = newSubdomain.split("://")[1];
    }
    if (newSubdomain.split(".").length > 1) {
      newSubdomain = newSubdomain.split(".");

      if (newSubdomain[0] === "pre") {
        newSubdomain = newSubdomain.filter((item) => item !== "pre");
      }
      newSubdomain = newSubdomain[0];
    }
    if (
      newSubdomain === "pideateresa" ||
      newSubdomain.includes("economysa") ||
      newSubdomain.includes("localhost")
    ) {
      setSubdomain("PIDEATERESA");
      setRoute(getSubdomain(window.location.href));
    } else if (newSubdomain === "admin") {
      setSubdomain("ADMIN");
      setRoute(getSubdomain(window.location.href));
    }
  }, []);

  return (
    <BrowserRouter>
      {subdomain === "ADMIN" ? <AdminRouter /> : <UserRouter />}
    </BrowserRouter>
  );
  // return (
  //   <BrowserRouter>
  //     <AdminRouter />
  //   </BrowserRouter>
  // );
};

export default AppRouter;

