import { getRouteCodeByCoord } from './utilsRouteCodeByCoord';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { createContext, useCallback, useContext, useState } from 'react';

const moment = require('moment-timezone');

// ====== START ====== adminDrawerItems
export const adminDrawerItems = [
  {
    id: 0,
    icon: <AddPhotoAlternateIcon />,
    label: 'Banners',
    route: 'BANNERS',
  },
  {
    id: 1,
    icon: <LocalOfferIcon />,
    label: 'Productos',
    route: 'PRODUCTS',
  },
  {
    id: 2,
    icon: <ListAltIcon />,
    label: 'Catálogo',
    route: 'CATALOGO',
  },
  {
    id: 3,
    icon: <AttachMoneyIcon />,
    label: 'Más Rentables',
    route: 'MASRENTABLES',
  },
  {
    id: 4,
    icon: <StarBorderIcon />,
    label: 'Más Vendidos',
    route: 'MASVENDIDOS',
  },
  {
    id: 5,
    icon: <FavoriteBorderIcon />,
    label: 'Más Interesantes',
    route: 'MASINTERESANTES',
  },
  {
    id: 6,
    icon: <WorkspacePremiumIcon />,
    label: 'Marcas',
    route: 'MARCAS',
  },
  {
    id: 7,
    icon: <ShoppingCartIcon />,
    label: 'Pedidos',
    route: 'PEDIDOS',
  },
];
// ====== END ====== adminDrawerItems

// ====== START ====== getSubdomain
export const getSubdomain = (currentLocation) => {
  if (currentLocation.split('://').length > 1) currentLocation = currentLocation.split('://')[1];
  if (currentLocation.split('.').length > 1) {
    currentLocation = currentLocation.split('.');
    if (currentLocation[0] === 'pre') currentLocation = currentLocation.filter((item) => item !== 'pre');
    currentLocation = currentLocation[0];
  }
  if (currentLocation === 'pideateresa' || currentLocation.includes('Vedoraplus') || currentLocation.includes('localhost')) return 'PIDEATERESA';
  else if (currentLocation === 'admin') return 'ADMIN';
};
// ====== END ====== getSubdomain

export function toTitleCase(string) {
  if (string === null) {
    string = '-';
  } else {
    let splitStr = string.toLowerCase().split(' ');

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
      string = splitStr.join(' ');
    }
  }
  return string;
}

export function getDeliveryDay(infoClient, addressId) {
  let deliveryDay = 0;
  let addressSelected = infoClient.deliveryAddresses.filter((item) => item.code === addressId)[0];

  let routeCode = addressSelected.routeCode;
  let visitDayCode = routeCode[routeCode.length - 1];
  // console.log('Dia de visita: ', visitDayCode);

  const dobleDeliveryDayRouteCodes = ['4004', '4011', '6051', '6054', '6071', '6074', '6191', '6194', '6341', '6344'];
  let dobleDeliveryDayPerWeek = dobleDeliveryDayRouteCodes.includes(routeCode);

  if (routeCode === '9999') {
    routeCode = getRouteCodeByCoord(addressSelected.latitud, addressSelected.longitud);
  }
  routeCode = Number(routeCode);

  let aditionalWeek = false;
  let timeLimit = 2; // 1 -> 24h, 2-> 48h, 3 -> 72h
  if (4000 <= routeCode && routeCode <= 4999) {
    // Lima Oeste Purina
    timeLimit = 1;
  } else if (6000 <= routeCode && routeCode <= 6999) {
    // Lima Oeste
    timeLimit = 1;
  } else if (7000 <= routeCode && routeCode <= 7599) {
    // Lima Sur
    timeLimit = 2;
  } else if (7600 <= routeCode && routeCode <= 7999) {
    // Lima Norte
    timeLimit = 1;
  } else if ((8030 <= routeCode && routeCode <= 8200) || (9010 <= routeCode && routeCode <= 9016) || (9030 <= routeCode && routeCode <= 9200)) {
    // Cajamarca Capital
    timeLimit = 2;
  } else if ((8010 <= routeCode && routeCode <= 8016) || (9020 <= routeCode && routeCode <= 9026)) {
    // Cajamarca Provincia
    deliveryDay = 4;
  } else if (8020 <= routeCode && routeCode <= 8026) {
    // Cajamarca Provincia
    deliveryDay = 3;
  } else if (9040 <= routeCode && routeCode <= 9046) {
    // Cajamarca Provincia
    deliveryDay = 1;
  }

  let currentDayHour = moment().locale('es').tz('America/Lima').format('HH');
  let currentWeekDay = getTodayWeekDay();

  // console.log('currentWeekDay: ', currentWeekDay)

  let currentWeekDayIndex = 0;
  switch (currentWeekDay) {
    case 'lunes':
    case 'monday':
      currentWeekDayIndex = 1;
      break;
    case 'martes':
    case 'tuesday':
      currentWeekDayIndex = 2;
      break;
    case 'miercoles':
    case 'wednesday':
      currentWeekDayIndex = 3;
      break;
    case 'jueves':
    case 'thursday':
      currentWeekDayIndex = 4;
      break;
    case 'viernes':
    case 'friday':
      currentWeekDayIndex = 5;
      break;
    case 'sabado':
    case 'saturday':
      currentWeekDayIndex = 6;
      break;
    case 'domingo':
    case 'sunday':
      currentWeekDayIndex = 7;
      break;
    default:
      break;
  }
  // console.log('currentWeekDayIndex: ', currentWeekDayIndex)

  visitDayCode = Number(visitDayCode);

  if (deliveryDay === 0) {
    if (dobleDeliveryDayPerWeek) {
      visitDayCode = visitDayCode + 3 < 7 ? visitDayCode : visitDayCode - 3;

      if ((visitDayCode === currentWeekDayIndex && currentDayHour >= 17) || (visitDayCode < currentWeekDayIndex && currentWeekDayIndex < visitDayCode + 3) || (currentWeekDayIndex === visitDayCode + 3 && currentDayHour < 17)) {
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(visitDayCode + 3 + timeLimit - currentWeekDayIndex, 'days');
      } else {
        if (currentWeekDayIndex < visitDayCode || (currentWeekDayIndex === visitDayCode && currentDayHour < 17)) {
          deliveryDay = moment()
            .tz('America/Lima')
            .locale('es')
            .add(visitDayCode + timeLimit - currentWeekDayIndex, 'days');
        } else {
          deliveryDay = moment()
            .tz('America/Lima')
            .locale('es')
            .add(visitDayCode + timeLimit + (7 - currentWeekDayIndex), 'days');
        }
      }
    } else {
      deliveryDay = visitDayCode + timeLimit > 6 ? visitDayCode + timeLimit + 1 : visitDayCode + timeLimit;

      // console.log('deliveryDay: ', deliveryDay)
      if (((visitDayCode === currentWeekDayIndex && currentDayHour >= 17) || visitDayCode < currentWeekDayIndex) && currentWeekDayIndex < deliveryDay) {
        aditionalWeek = true;
      }

      // console.log('aditionalWeek: ', aditionalWeek)

      if (deliveryDay > 6) {
        deliveryDay = deliveryDay - 7;
      }
      if (currentWeekDayIndex < deliveryDay) {
        // Hoy + (deliveryDay - currentWeekDayIndex)
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex, 'days');
      } else {
        // Hoy + (7 - currentWeekDayIndex) + deliveryDay
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(7 - currentWeekDayIndex + deliveryDay, 'days');
      }
      if (aditionalWeek) {
        deliveryDay = deliveryDay.add(7, 'days');
      }
    }
  } else {
    if (deliveryDay === 4 || deliveryDay === 3) {
      if (currentWeekDayIndex === 1 && currentDayHour < 17) {
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex, 'days');
      } else {
        if (currentWeekDayIndex === 1 && currentDayHour >= 17) {
          currentWeekDayIndex = 2;
        }
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex + 7, 'days');
      }
    } else if (deliveryDay === 1) {
      if ((currentWeekDayIndex === 6 && currentDayHour >= 17) || currentWeekDayIndex === 7) {
        aditionalWeek = true;
      }
      deliveryDay = moment()
        .tz('America/Lima')
        .locale('es')
        .add(7 + deliveryDay - currentWeekDayIndex, 'days');
      if (aditionalWeek) {
        deliveryDay = deliveryDay.add(7, 'days');
      }
    }
  }

  let day = deliveryDay.format('dddd');
  switch (day) {
    case 'lunes':
    case 'Monday':
      day = 'Lunes';
      break;
    case 'martes':
    case 'Tuesday':
      day = 'Martes';
      break;
    case 'miercoles':
    case 'Wednesday':
      day = 'Miércoles';
      break;
    case 'jueves':
    case 'Thursday':
      day = 'Jueves';
      break;
    case 'viernes':
    case 'Friday':
      day = 'Viernes';
      break;
    case 'sabado':
    case 'Saturday':
      day = 'Sábado';
      break;
    case 'domingo':
    case 'Sunday':
      day = 'Domingo';
      break;
    default:
      break;
  }
  let dayNumber = deliveryDay.format('DD');
  let month = deliveryDay.format('MMMM');
  switch (month) {
    case 'January':
      month = 'Enero';
      break;
    case 'February':
      month = 'Febrero';
      break;
    case 'March':
      month = 'Marzo';
      break;
    case 'April':
      month = 'Abril';
      break;
    case 'May':
      month = 'Mayo';
      break;
    case 'June':
      month = 'Junio';
      break;
    case 'July':
      month = 'Julio';
      break;
    case 'August':
      month = 'Agosto';
      break;
    case 'September':
      month = 'Septiembre';
      break;
    case 'October':
      month = 'Octubre';
      break;
    case 'November':
      month = 'Noviembre';
      break;
    case 'December':
      month = 'Diciembre';
      break;
    default:
      break;
  }

  deliveryDay = day + ' ' + dayNumber + ' de ' + month;

  // console.log('deliveryDay: ', deliveryDay)
  return deliveryDay;
}

export const calcDeliveryDay = (routeCode) => {
  let deliveryDay = 0;
  let visitDayCode = routeCode[routeCode.length - 1];
  // console.log('Dia de visita: ', visitDayCode);

  const dobleDeliveryDayRouteCodes = ['4004', '4011', '6051', '6054', '6071', '6074', '6191', '6194', '6341', '6344'];
  let dobleDeliveryDayPerWeek = dobleDeliveryDayRouteCodes.includes(routeCode);

  // if (routeCode === '9999') {
  //   routeCode = getRouteCodeByCoord(addressSelected.latitud, addressSelected.longitud);
  // }
  routeCode = Number(routeCode);

  let aditionalWeek = false;
  let timeLimit = 2; // 1 -> 24h, 2-> 48h, 3 -> 72h
  if (4000 <= routeCode && routeCode <= 4999) {
    // Lima Oeste Purina
    timeLimit = 1;
  } else if (6000 <= routeCode && routeCode <= 6999) {
    // Lima Oeste
    timeLimit = 1;
  } else if (7000 <= routeCode && routeCode <= 7599) {
    // Lima Sur
    timeLimit = 2;
  } else if (7600 <= routeCode && routeCode <= 7999) {
    // Lima Norte
    timeLimit = 1;
  } else if ((8030 <= routeCode && routeCode <= 8200) || (9010 <= routeCode && routeCode <= 9016) || (9030 <= routeCode && routeCode <= 9200)) {
    // Cajamarca Capital
    timeLimit = 2;
  } else if ((8010 <= routeCode && routeCode <= 8016) || (9020 <= routeCode && routeCode <= 9026)) {
    // Cajamarca Provincia
    deliveryDay = 4;
  } else if (8020 <= routeCode && routeCode <= 8026) {
    // Cajamarca Provincia
    deliveryDay = 3;
  } else if (9040 <= routeCode && routeCode <= 9046) {
    // Cajamarca Provincia
    deliveryDay = 1;
  }

  let currentDayHour = moment().locale('es').tz('America/Lima').format('HH');
  let currentWeekDay = getTodayWeekDay();

  // console.log('currentWeekDay: ', currentWeekDay)

  let currentWeekDayIndex = 0;
  switch (currentWeekDay) {
    case 'lunes':
    case 'monday':
      currentWeekDayIndex = 1;
      break;
    case 'martes':
    case 'tuesday':
      currentWeekDayIndex = 2;
      break;
    case 'miercoles':
    case 'wednesday':
      currentWeekDayIndex = 3;
      break;
    case 'jueves':
    case 'thursday':
      currentWeekDayIndex = 4;
      break;
    case 'viernes':
    case 'friday':
      currentWeekDayIndex = 5;
      break;
    case 'sabado':
    case 'saturday':
      currentWeekDayIndex = 6;
      break;
    case 'domingo':
    case 'sunday':
      currentWeekDayIndex = 7;
      break;
    default:
      break;
  }
  // console.log('currentWeekDayIndex: ', currentWeekDayIndex)

  visitDayCode = Number(visitDayCode);

  if (deliveryDay === 0) {
    if (dobleDeliveryDayPerWeek) {
      visitDayCode = visitDayCode + 3 < 7 ? visitDayCode : visitDayCode - 3;

      if ((visitDayCode === currentWeekDayIndex && currentDayHour >= 17) || (visitDayCode < currentWeekDayIndex && currentWeekDayIndex < visitDayCode + 3) || (currentWeekDayIndex === visitDayCode + 3 && currentDayHour < 17)) {
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(visitDayCode + 3 + timeLimit - currentWeekDayIndex, 'days');
      } else {
        if (currentWeekDayIndex < visitDayCode || (currentWeekDayIndex === visitDayCode && currentDayHour < 17)) {
          deliveryDay = moment()
            .tz('America/Lima')
            .locale('es')
            .add(visitDayCode + timeLimit - currentWeekDayIndex, 'days');
        } else {
          deliveryDay = moment()
            .tz('America/Lima')
            .locale('es')
            .add(visitDayCode + timeLimit + (7 - currentWeekDayIndex), 'days');
        }
      }
    } else {
      deliveryDay = visitDayCode + timeLimit > 6 ? visitDayCode + timeLimit + 1 : visitDayCode + timeLimit;

      // console.log('deliveryDay: ', deliveryDay)
      if (((visitDayCode === currentWeekDayIndex && currentDayHour >= 17) || visitDayCode < currentWeekDayIndex) && currentWeekDayIndex < deliveryDay) {
        aditionalWeek = true;
      }

      // console.log('aditionalWeek: ', aditionalWeek)

      if (deliveryDay > 6) {
        deliveryDay = deliveryDay - 7;
      }
      if (currentWeekDayIndex < deliveryDay) {
        // Hoy + (deliveryDay - currentWeekDayIndex)
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex, 'days');
      } else {
        // Hoy + (7 - currentWeekDayIndex) + deliveryDay
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(7 - currentWeekDayIndex + deliveryDay, 'days');
      }
      if (aditionalWeek) {
        deliveryDay = deliveryDay.add(7, 'days');
      }
    }
  } else {
    if (deliveryDay === 4 || deliveryDay === 3) {
      if (currentWeekDayIndex === 1 && currentDayHour < 17) {
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex, 'days');
      } else {
        if (currentWeekDayIndex === 1 && currentDayHour >= 17) {
          currentWeekDayIndex = 2;
        }
        deliveryDay = moment()
          .tz('America/Lima')
          .locale('es')
          .add(deliveryDay - currentWeekDayIndex + 7, 'days');
      }
    } else if (deliveryDay === 1) {
      if ((currentWeekDayIndex === 6 && currentDayHour >= 17) || currentWeekDayIndex === 7) {
        aditionalWeek = true;
      }
      deliveryDay = moment()
        .tz('America/Lima')
        .locale('es')
        .add(7 + deliveryDay - currentWeekDayIndex, 'days');
      if (aditionalWeek) {
        deliveryDay = deliveryDay.add(7, 'days');
      }
    }
  }
  return deliveryDay;
};

function getTodayWeekDay() {
  let todayWeekDay = moment().locale('es').tz('America/Lima').format('dddd');
  todayWeekDay = todayWeekDay.toLocaleLowerCase();
  todayWeekDay = todayWeekDay.replace('á', 'a');
  todayWeekDay = todayWeekDay.replace('é', 'e');
  return todayWeekDay;
}

export function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export const FAQS = [
  {
    id: 1,
    question: '¿Quién es Vedoraplus?',
    answer: 'Es la única <b>Vendedora Virtual</b>  👩🏻‍💻 en <b>WhatsApp</b>; que te permite abastecer tu negocio en cualquier momento 24/7,  con un amplio surtido y las mejores promociones 😊'
  },
  {
    id: 2,
    question: '¿Qué ventajas tengo comprando con Vedoraplus?',
    answer: 'Siendo cliente de <b>Vedoraplus</b>, además de contar con <b>los mejores proveedores</b> tendrás los <b>mejores precios, grandes descuentos y bonificaciones</b> 🎁 Y como queremos que tu negocio crezca, a mayor compra mayores descuentos y bonificaciones 🤑'
  },
  {
    id: 3,
    question: '¿Repartes en mi zona?',
    answer: 'Repartimos en <b>Lima y Callao</b>. Durante el proceso de registro comprobaré tu dirección para verificar tu zona.'
  },
  {
    id: 4,
    question: '¿Hay compra mínima?',
    answer: 'Para comprar con <b>Vedoraplus</b> es necesaria una compra <b>mínima de 50 soles</b>.'
  },
  {
    id: 5,
    question: '¿Cuánto tarda en llegar la mercadería?',
    answer: 'El tiempo de envío es según la zona de ubicación de tu bodega; en promedio son de uno a tres días hábiles dependiendo si haces tu pedido en la mañana o tarde.'
  },
  {
    id: 6,
    question: '¿Cuánto me cuesta el envío?',
    answer: 'No te cobramos por la entrega de productos a tu negocio 🚚.'
  },
  {
    id: 7,
    question: '¿Dónde me entregan la mercadería?',
    answer: 'Los <b>pedidos</b> se entregan directamente <b>a la puerta de tu negocio</b> 🏪'
  },
  {
    id: 8,
    question: '¿Hay un máximo de compras?',
    answer: 'En <b>Vedoraplus</b> no hay límite de pedidos.'
  },
  {
    id: 9,
    question: '¿Cuándo pago?',
    answer: 'El pago es <b>contra entrega</b>, cuando recibas tu pedido 📦'
  },
  {
    id: 10,
    question: '¿Es seguro comprar por Vedoraplus?',
    answer: 'Te garantizamos una compra y entrega segura. Y la protección de tus datos personales.'
  },
  {
    id: 11,
    question: '¿Qué hago si no recibo mi pedido?',
    answer: 'Por favor, llama a estos teléfonos y tu vendedor te ayudará personalmente para darte una solución: 📞 <b>+51 949 867 937</b> y <b>+51 975 545 364</b>'
  },
  {
    id: 12,
    question: '¿Cómo puedo contactarme con ustedes?',
    answer: 'Para poder contactar con mis compis humanos puedes hacerlo a los siguientes números: 📞 <b>+51 949 867 937</b> y <b>+51 975 545 364</b>'
  },
  {
    id: 13,
    question: '¿Que fechas de vencimiento tienen sus productos?',
    answer: 'Las <b>fechas de vencimiento</b> de los productos las establece el fabricante. Trabajamos con periodos de entre 6 a 18 meses como mínimo para la mayoría de nuestros productos.'
  },
  {
    id: 14,
    question: '¿Puedo comprar al por mayor?',
    answer: 'En <b>Vedoraplus</b> puedes comprar las cantidades que tu negocio necesite.'
  },
]