import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useProducts } from '../context/ProductsContext';
import { toTitleCase, a11yProps } from '../utils';
import theme from '../utils/ThemeConfig';

const ScrollerMenu = ({ data }) => {
  const navigate = useNavigate();
  const {categories, brands } = useProducts();

  return (
    <>
      {data === 'categories' ? (
        <div className="l-container-carrousel">
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: { xs: '0px 20px', md: '0px 35px' }, marginBottom: '20px', backgroundColor: '#F8F8F8', marginBottom: '0px' }}>
              <Typography sx={{ fontSize: { xs: '18px', md: '35px' }, color: '#000000', fontWeight: 900 }}>Categorías</Typography>
              <Link to="/tienda/categories">
                <Typography sx={{ display: { xs: 'block', md: 'none' }, fontSize: '15px', color: '#000000' }}>Ver todo</Typography>
              </Link>
            </Box>

            <Box width="100%" display="flex" justifyContent="center">
              <Box
                mt={0}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    width: '100%',
                  },
                  width: '100%',
                  // background: 'yellowgreen',
                }}
              >
                <AppBar
                  sx={{
                    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
                    '& span': {
                      height: '0px',
                      fontSize: { xs: '11px', md: '18px' },
                      color: '#2D85C5'

                      ,
                    },
                    '& svg': {
                      color: '#2D85C5',
                    },
                    backgroundColor: 'white',
                  }}
                  position="static"
                  color="default"
                >
                  <Tabs value={false} variant="scrollable" scrollButtons sx={{ gap: 0, paddingLeft: { xs: '20px', md: 0 }, backgroundColor: '#F8F8F8' }} >
                    {categories.map((item, index) => (
                      <Tab
                        onClick={() => navigate('/tienda/' + item.name.split(' ').join('-') + '/home')}
                        key={index}

                        icon={
                          <Box display="flex" flexDirection="column" sx={{ height: '100%' }} gap={0}>
                            <Box
                              sx={{
                                width: { xs: '68px', md: '113px' },
                                height: { xs: '68px', md: '113px' },
                                margin: '0 auto',
                                borderRadius: '50%',
                                position: 'relative',
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'absolute',
                                  minWidth: { xs: '68px', md: '113px' },
                                  minHeight: { xs: '68px', md: '113px' },
                                  borderRadius: '50%',
                                  border: '1px solid var(--primary-color)',
                                  backgroundColor: '#D9D9D9',
                                  mixBlendMode: "multiply",
                                  top: 0,
                                  left: 0,
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                  }

                                }}

                              />
                              <Box
                                component="img"
                                src={'https://images-dev.vedoraplus.com/categorias/' + item.name + '.webp'}
                                alt={'Imagen no disponible'}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = 'https://images-dev.vedoraplus.com/75016.webp';
                                }}
                                sx={{
                                  width: { xs: '68px', md: '113px' },
                                  height: { xs: '68px', md: '113px' },
                                  borderRadius: '50%'
                                }}
                              />
                            </Box>
                            <Box component={"span"} sx={{ letterSpacing: 0, flex: 1, color: 'var(--primary-color)!important', fontWeight: 700 }} >
                              {toTitleCase(item.name)}

                            </Box>
                          </Box>
                        }
                        {...a11yProps(index)}
                        sx={{
                          justifyContent: 'start',
                          gap: 0,
                          padding: { xs: 0, md: 0 },
                          // width: { xs: '69px', md: '113px' },
                          // maxWidth: { xs: '69px', md: '113px' },
                          minWidth: { xs: '69px', md: '113px' },
                          minHeight: { xs: '84px', md: '139px' },
                          letterSpacing: 0,
                          fontSize: { xs: '13px', md: '17px' },
                          color: 'var(--primary-color)',
                          marginRight: { xs: '10px', md: '55px' },
                          '& span': {
                            textTransform: 'none',
                            fontSize: { xs: '11px', md: '18px' },
                            '&:visited': {
                              textDecoration: 'none',
                              color: 'var(--primary-color)'
                            }
                          },
                          '&:visited': {
                            textDecoration: 'none',
                            color: 'var(--primary-color)'
                          },
                          opacity: '1',
                          textTransform: 'none',
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
            </Box>
          </Box>
        </div>
      ) : (
        <div className="l-container">
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
              }}
            >
              
                <Tabs value={false} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile style={{ height: '90px' }}>
                  {brands && brands.map((item, index) => (
                    <Tab
                      onClick={() => navigate('/tienda/marca/' + item.toLowerCase().split(' ').join('-') + '/home')}
                      key={index}
                      icon={
                        <Box>
                          <Box m={1}>
                            <img
                              src={'https://images-dev.vedoraplus.com/148401.webp'}
                              alt={item}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://images-dev.vedoraplus.com/149311.webp';
                              }}
                              style={{
                                height: 'auto',
                                width: 'auto',
                                maxWidth: '70px',
                                maxHeight: '45px',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        </Box>
                      }
                      sx={{ height: '100px', opacity: '1' }}
                    />
                  ))}
                </Tabs>
              
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default ScrollerMenu;
