import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useProducts } from '../context/ProductsContext';
import { useUserAuth } from '../context/UserAuthContext';
import UserHomePage from '../pages/UserHomePage';
import { useState } from 'react';
import { IconButton } from '@mui/material';

import { ImArrowUp2 } from 'react-icons/im';

const UserProtectedRoute = ({ children }) => {
  const { user, setUser  } = useUserAuth();
  const { products } = useProducts();

  const [showTopBtn, setShowTopBtn] = useState(false);

  const goToTopOfPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  if (Object.keys(user).length === 0) 
    {
    console.log('Check user Protect lenth1=0: ', Object.keys);
    return <Navigate replace to="/" />;
    }
  // else
  // {
  //   console.log('Check user Protect lenth2>0: ', (Date.now() - new Date(user.updateDate).getTime()) > 2 * 1 * 60 * 1000);
  //   if ( !user.UpdateDate || (Date.now() - new Date(user.updateDate).getTime()) > 2 * 60 * 60 * 1000) // verifica si han pasado más de 2 horas
  //   {
  //     console.log('Check user lenth3>0 y fecha: ', user);
  //     // return <Navigate replace to="/" />;
  //   }
  // }

  return (
    <>
      {children}
      {
        products.length !== 0 &&
        (
          <IconButton
            onClick={goToTopOfPage}
            sx={{
              display: `${showTopBtn ? 'flex' : 'none'}`,
              justifyContent:'center',
              alignItems:'center',
              position: 'fixed',
              bottom: 80,
              right: 25,
              zIndex: 20,
              width: 43,
              height: 43,
              backgroundColor: 'var(--primary-color)!important',
              borderRadius:'5px',
              color: '#fff',
              textAlign:'center',
              margin: 0,
            }}
          >
            <ImArrowUp2/>
          </IconButton>
        )
      }
    </>
  ) 
  
};

export default UserProtectedRoute;
