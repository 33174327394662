
import { useEffect,useMemo, useState } from 'react';
import { OrderSummary } from '../containers/OrderSummary';
import { CartResume } from '../containers/CartResume';
import { useCart } from '../context/CartContext';
import MainLayout from '../layout/MainLayout';
import { FinishOrder } from '../containers/FinishOrder';
import '../styles/UserCart.css';
import { useUserAuth } from '../context/UserAuthContext';


export const UserCart = () => {
  const { chart, stepper, resetStepper, setStepper, handleStartOrderAndUpdateOrder } = useCart();
  const { user } = useUserAuth();
  const [MINIMUM_AMOUNT, setMinimumAmount] = useState(40);
  
  const handleConfirmation = () => {
    console.log('hacled confi direcicon: ', user.addressId);
    // Busca en la lista deliveryAddresses el elemento que coincida con el addressId proporcionado
    const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
    //user?.address = foundAddress.deliveryAddresses;

    changeMinimumAmount(foundAddress?.orderSaleMin ?? 40)
     if (stepper !== 1 && totalPrice >= MINIMUM_AMOUNT) {
      setStepper(1);
      handleStartOrderAndUpdateOrder(user);
    }

    return;
  };

    // Función para cambiar el valor de MINIMUM_AMOUNT
    const changeMinimumAmount = (newAmount) => {
      setMinimumAmount(newAmount);
    };

  const totalPrice = useMemo(() => {
    return chart.products.reduce((prev, acc) =>  prev + Number(acc.amount * acc.discountPrice) ,0).toFixed(1)
  },[chart.products])

  useEffect(() => {
    resetStepper();
  }, []);

  return (
    <MainLayout access="user">
      {stepper !== 2 ? (
        <div className="mobile-tab-container">
          <button className={`mobile-tab-btn ${stepper === 0 ? 'active' : ''}`} 
            id="btn-mobile-backCart" data-btn-id="btn-backCart" onClick={resetStepper}>
            Carrito
          </button>
          <button 
            className={`mobile-tab-btn ${stepper === 1 ? 'active' : ''}`} 
            id="btn-mobile-cart-prepare-order"
            data-btn-id="btn-cart-prepare-order" 
            disabled={(chart.products.length === 0 || totalPrice < MINIMUM_AMOUNT)} 
            onClick={handleConfirmation}
          >
            Confirmación
          </button>
        </div>
      ) : (
        ''
      )}

      {stepper === 0 ? <CartResume /> : ''}

      {stepper === 1 ? <OrderSummary /> : ''}

      {stepper === 2 ? <FinishOrder /> : ''}
    </MainLayout>
  );
};
