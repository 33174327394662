/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Cart = ({ color = "#FDFDFD", className }) => {
  return (
    <svg
      className={`cart ${className}`}
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.6852 26C14.2826 26 13.9402 25.8589 13.6582 25.5768C13.3762 25.2947 13.2352 24.9524 13.2352 24.5498C13.2352 24.1471 13.3762 23.8047 13.6582 23.5227C13.9402 23.2406 14.2826 23.0996 14.6852 23.0996C15.0879 23.0996 15.4303 23.2406 15.7123 23.5227C15.9943 23.8047 16.1354 24.1471 16.1354 24.5498C16.1354 24.9524 15.9943 25.2947 15.7123 25.5768C15.4303 25.8589 15.0879 26 14.6852 26ZM22.7172 26C22.3144 26 21.972 25.8589 21.69 25.5768C21.408 25.2947 21.267 24.9524 21.267 24.5498C21.267 24.1471 21.408 23.8047 21.69 23.5227C21.972 23.2406 22.3144 23.0996 22.7172 23.0996C23.1198 23.0996 23.4621 23.2406 23.7441 23.5227C24.0261 23.8047 24.1672 24.1471 24.1672 24.5498C24.1672 24.9524 24.0261 25.2947 23.7441 25.5768C23.4621 25.8589 23.1198 26 22.7172 26ZM13.7417 12.9004L15.8422 17.2988H21.4916C21.5393 17.2988 21.5819 17.2869 21.6192 17.263C21.6563 17.2391 21.6882 17.2059 21.7147 17.1633L23.9378 13.1235C23.9697 13.0651 23.9724 13.0133 23.9457 12.9681C23.9192 12.923 23.874 12.9004 23.8102 12.9004H13.7417ZM13.1459 11.6574H24.6517C24.9907 11.6574 25.2469 11.8016 25.4205 12.0901C25.5943 12.3785 25.6026 12.6731 25.4454 12.9737L22.7903 17.7832C22.6544 18.0223 22.4746 18.2084 22.251 18.3417C22.0273 18.4751 21.7821 18.5419 21.5156 18.5419H15.4693L14.5099 20.2947C14.4674 20.3585 14.466 20.4276 14.506 20.5019C14.5457 20.5763 14.6055 20.6136 14.6852 20.6136H24.1672V21.8566H14.6852C14.1327 21.8566 13.7176 21.6183 13.4399 21.1418C13.162 20.6654 13.1522 20.1896 13.4104 19.7146L14.5928 17.5889L11.5778 11.243H10V10H12.3586L13.1459 11.6574Z"
        fill={color}
      />
    </svg>
  );
};

Cart.propTypes = {
  color: PropTypes.string,
};
