import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Container, Paper, CircularProgress, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';

import SaveIcon from '@mui/icons-material/Save';
import { api } from '../services/axiosConfig';
import toast from 'react-hot-toast';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export const AdminCatalogContainer = () => {
  const [datos, setDatos] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_GET_ORDER_ORDENCATALOG_URL, {
        headers: {
          'Content-Type': 'application/json',
          api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        },
      });
      setDatos(data.sort((a, b) => (b.positionOrderCatalog < a.positionOrderCatalog ? 1 : -1)));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: 'nombre',
      headerName: 'Nombre',
      flex: 1,
      // width: 200,
      description: 'Código del Producto',
      sortable: false,
    }
  ];

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if(!destination) return;

    if(source.index === destination.index && source.droppableId === destination.droppableId) return;

    const sortedCatalags = reorder(datos,source.index, destination.index)

    const updateCatalogsPosition = sortedCatalags.map((catalog, index) => {
      return {
        ...catalog,
        positionOrderCatalog: index + 1
      }
    })

    setDatos(updateCatalogsPosition)
  }

  const reorder = (list, startIndex, endIndex) => {
    
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;

  }

  const handleSavePosition = async () => {
    const params = datos.map((dato) => ({ id: dato.id, positionOrderCatalog: dato.positionOrderCatalog }));
    try {
      const { data } = await api.post(process.env.REACT_APP_UPDATE_ORDER_ORDENCATALOG_URL, params);
      if (data.success) {
        toast.success('El orden fue actualizado en la base de datos');
        setDatos([]);
        fetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({

    userSelect: "none",

    background: isDragging ? "#eee" : "white",

    ...draggableStyle
  });

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography component="div" flexGrow="1">
                <Box fontSize="18px" fontWeight="500">
                  Orden de visualización en el CATÁLOGO...
                </Box>
              </Typography>
              <Button
                variant="contained"
                sx={{ textTransform: 'none', color: 'white' }}
                onClick={() => {
                  handleSavePosition();
                }}
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          {datos.length === 0 ? (
            <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            // <DataGrid rows={datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
            <DragDropContext onDragEnd={onDragEnd}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      { columns.map((col, index) => (
                        <TableCell key={index} >{col.headerName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId='catalogos'>
                   {
                     (dropplableProvided) => (
                      <TableBody {...dropplableProvided.droppableProps} ref={dropplableProvided.innerRef} >
                        {datos.map((row, index) => (
                          <Draggable key={row.id} draggableId={row.id} index={row.positionOrderCatalog - 1}>
                            {
                              (draggableProvided, snapshot) => (
                                <TableRow 
                                  {...draggableProvided.draggableProps} 
                                  ref={draggableProvided.innerRef} 
                                  {...draggableProvided.dragHandleProps}
                                  style={getItemStyle(
                                      snapshot.isDragging,
                                      draggableProvided.draggableProps.style
                                  )}
                                >
                                  <TableCell>{row.nombre}</TableCell>
                                </TableRow>         
                              )
                            }
                          </Draggable>
                        ))}
                        {dropplableProvided.placeholder}
                      </TableBody>
                     )
                   }
                  </Droppable>
                </Table>
              </TableContainer>
            </DragDropContext>
          )}
        </Paper>
      </Grid>
    </div>
  );
};
