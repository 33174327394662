import React from "react";
import { AppBarOffer } from "../../components/AppBarOffer";
import { IconButtonDefault } from "../../components/IconButtonDefault";
import { SearchButton } from "../../components/SearchButton";
import { Tag } from "../../components/Tag";
import { Timer } from "../../components/Timer";
import { TopBanner } from "../../components/TopBanner";
import { TypePrimaryState } from "../../components/TypePrimaryState";
import { Delivery } from "../../icons/Delivery";
import { Logo } from "../../icons/Logo";
import { Offer } from "../../icons/Offer";
import "./style.css";

export const Catlogo = () => {
  return (
    <div className="catlogo">

      <div className="div-3">
        <div className="body">
          <div className="section">
            <div className="header">              
              <div className="text-wrapper-2">Ofertas</div>
              <p className="p">¡Aprovecha nuestras ofertas exclusivas y ahorra más!</p>
            </div>
            <div className="row">
              <div className="card-timer-card">
                <div className="card">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/product-image-2@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <div className="text-wrapper-3">Galletas Coconut</div>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-dp-fill"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/info-24dp-fill0-wght300-grad0-opsz24-1-4.svg"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/line-21-4.svg" />
                    <div className="images">
                      <img className="ellipse" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="timer-instance"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
              <div className="card-timer-card-2">
                <div className="card">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/product-image-1@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <div className="text-wrapper-3">Galletas Coconut</div>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-dp-fill"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/info-24dp-fill0-wght300-grad0-opsz24-1-1.svg"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/line-21-3.svg" />
                    <div className="images-2">
                      <img className="img" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/ellipse-2-1@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="design-component-instance-node-2"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
            </div>
            <div className="row">
              <div className="card-timer-card">
                <div className="card-2">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/product-image-2@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <div className="text-wrapper-3">Galletas Coconut</div>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-dp-fill"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/info-24dp-fill0-wght300-grad0-opsz24-1-2.svg"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/line-21-4.svg" />
                    <div className="images-2">
                      <img className="img" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/ellipse-2-2@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="timer-4"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
              <div className="card-timer-card-2">
                <div className="card">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/product-image-3@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <p className="text-wrapper-3">Combo Pepsi 500ml + Papas Lays</p>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-dp-fill"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/info-24dp-fill0-wght300-grad0-opsz24-1-3.svg"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/line-21-3.svg" />
                    <div className="images-2">
                      <img className="img" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/ellipse-2-3@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="design-component-instance-node-2"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
            </div>
            <div className="row">
              <div className="card-timer-card-2">
                <div className="card">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/product-image-4@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <p className="text-wrapper-3">Combo Pepsi 500ml + Papas Lays</p>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-dp-fill"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/info-24dp-fill0-wght300-grad0-opsz24-1-4.svg"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/line-21-4.svg" />
                    <div className="images-2">
                      <img className="img" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/ellipse-2-4@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="design-component-instance-node-2"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
              <div className="card-timer-card-3">
                <div className="card-2">
                  <div className="tag-wrapper">
                    <Tag
                      className="tag-instance"
                      divClassName="design-component-instance-node"
                      text="10% de descuento"
                    />
                  </div>
                  <img
                    className="product-image-2"
                    alt="Product image"
                    src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png"
                  />
                  <div className="info">
                    <div className="block">
                      <div className="text-wrapper-3">Galletas Coconut</div>
                      <div className="price">
                        <div className="text-wrapper-4">S/. 90.00</div>
                        <div className="text-wrapper-5">S/. 100.00</div>
                      </div>
                      <div className="alert-default">
                        <div className="content">
                          <div className="text">
                            <div className="title">
                              <img
                                className="info-2"
                                alt="Info"
                                src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png"
                              />
                              <div className="text-wrapper-6">Ganancia: 16.67%</div>
                            </div>
                            <div className="text-wrapper-7">Pregio sugerido: S/. 105.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="line-2" alt="Line" src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png" />
                    <div className="images-2">
                      <img className="ellipse-2" alt="Ellipse" src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png" />
                      <div className="div-wrapper">
                        <p className="text-wrapper-8">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dui lectus. Quisque auctor
                          lectus lorem, lacinia luctus sem eleifend non.
                        </p>
                      </div>
                    </div>
                    <TypePrimaryState
                      className="design-component-instance-node-2"
                      divClassName="type-primary-state-default-icon-none"
                      text="Añadir al carrito"
                    />
                  </div>
                </div>
                <Timer
                  className="timer-4"
                  divClassName="design-component-instance-node-3"
                  divClassName1="design-component-instance-node-3"
                  divClassNameOverride="design-component-instance-node-3"
                  labelClassName="timer-3"
                  labelClassName1="timer-3"
                  labelClassName2="timer-3"
                  labelClassNameOverride="timer-3"
                  timerClassName="timer-2"
                  valueClassName="design-component-instance-node-3"
                  valueClassName1="design-component-instance-node-3"
                  valueClassName2="design-component-instance-node-3"
                  valueClassNameOverride="design-component-instance-node-3"
                />
              </div>
            </div>
          </div>
        </div>
        <AppBarOffer className="app-bar-offer-instance" typePrimaryStateWrapperIcon={<Offer className="offer-2" />} />
        <div className="header-2">
          <div className="actions">
            <div className="row-2">
              <div className="col">
                <IconButtonDefault className="icon-button-default-instance" />
                <Logo className="logo-instance" />
              </div>
              <div className="col-2">
                <IconButtonDefault className="icon-button-default-2" />
                <IconButtonDefault className="icon-button-default-3" />
              </div>
            </div>
            <div className="searchbar-default">
              <div className="input-container">
                <div className="text-wrapper-9">¿Qué estas buscando?</div>
              </div>
              <SearchButton className="search-button-type" />
            </div>
          </div>
          <div className="address">
            <div className="address-info">
              <Delivery className="delivery-instance" />
              <div className="text-wrapper-10">Enviar a: +51933862445</div>
            </div>
            <TypePrimaryState
              className="type-primary-state-default-icon-none-instance"
              divClassName="design-component-instance-node-3"
              text="Cambiar"
            />
          </div>
        </div>
        <TopBanner
          className="top-banner-instance"
          divClassName="top-banner-2"
          text="¡Click para ver todas las ofertas!"
        />
        {/* <div className="status-bar-light">
          <div className="overlap-group">
            <img className="battery" alt="Battery" src="https://c.animaapp.com/U9iyoaYU/img/battery@2x.png" />
            <img className="wifi" alt="Wifi" src="https://c.animaapp.com/U9iyoaYU/img/wifi.svg" />
            <img
              className="mobile-signal"
              alt="Mobile signal"
              src="https://c.animaapp.com/U9iyoaYU/img/mobile-signal.svg"
            />
          </div>
          <img className="time" alt="Time" src="https://c.animaapp.com/U9iyoaYU/img/time@2x.png" />
        </div> */}
        <img className="whatsapp" alt="Whatsapp" src="https://c.animaapp.com/U9iyoaYU/img/whatsapp-1@2x.png" />
      </div>
    </div>
  );
};
