import { useMemo, useState  } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useProducts } from '../context/ProductsContext';
import { useCart } from '../context/CartContext';
import { useUserAuth } from '../context/UserAuthContext';
import '../styles/CardInfoResume.css';
import { colors } from '@mui/material';
import React, { useEffect } from 'react';

export const CardInfoResume = () => {
  const { user, changeAddress } = useUserAuth();
  const { chart, nextStepper, handleStartOrderAndUpdateOrder, RefreshProductToChartAction, updateProductToChartAction } = useCart();
  const [addressSelected, setAddressSelected] = useState(user?.addressId)
  const { products, categories, getProducts, getCategories, search, setSearch, filteredProducts, setFilteredProducts, setSearchProducts, setProducts  } = useProducts();
  const [prevAddressId, setPrevAddressId] = useState(user.addressId);
  const [MINIMUM_AMOUNT, setMinimumAmount] = useState(40);
  const totalPrice = useMemo(() => {

    const subTotal = chart.products.reduce((prev, acc) =>  prev + Number(acc.amount * acc.product.salePrice) ,0).toFixed(1)
    const total = chart.products.reduce((prev, acc) =>  prev + Number(acc.amount * acc.discountPrice) ,0).toFixed(1)
    const discount = (total - subTotal).toFixed(1)

    const ProductsMin = chart.products.reduce((prev, acc) =>  prev + Number(acc.product.saleMin > acc.amount ? 1 : 0) ,0).toFixed(1)

    return {
      subTotal,
      ProductsMin,
      total,
      discount
    }
  }, [chart.products, MINIMUM_AMOUNT]);

  useEffect(() => {
    // Este efecto se ejecuta cada vez que `products` cambia
    // guardo la direccion para que no sea un buble infinito ya que sesto se ejecuta cada vez q se actualiza el contexto 
    // y processAddressChange actualiza el contexto
    console.log('llega aqui: ', prevAddressId);
    const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
    changeMinimumAmount(foundAddress?.orderSaleMin ?? 40);
    if (user.addressId !== prevAddressId) {
      console.log('aqui cambia lista: ', user.address);
      console.log('prod des ', chart.products);
      // Si es diferente, ejecuta processAddressChange
      processAddressChange(user.addressId);

      // Actualiza prevAddressId al nuevo addressId
      setPrevAddressId(user.addressId);
    }
    
  }, [products, user.addressId, chart.products, MINIMUM_AMOUNT]); // Dependencia en `products`

  const handleChangeAddress = async (e) => {
    const newAddressId = e.target.value;
    // Busca en la lista deliveryAddresses el elemento que coincida con el addressId proporcionado
    await processAddressChange(newAddressId);
  }

  // La función que procesa el cambio de dirección
const processAddressChange = async (newAddressId) => {
  // Busca en la lista deliveryAddresses el elemento que coincida con el addressId proporcionado
  const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === newAddressId);

  if (!foundAddress) {
      console.error('Address not found');
      return;
  }

  // Actualiza la información de la dirección en el usuario
  user.address = foundAddress.address;
  user.addressId = foundAddress.addressId;
  user.priceListId = foundAddress.priceListId;
  user.dayToDelivery = foundAddress.dayToDelivery;
  user.dayOfWeek = foundAddress.dayOfWeek;

  // Actualiza el estado del elemento seleccionado
  setAddressSelected(newAddressId);

  // Realiza la lógica adicional
  changeAddress(newAddressId);
  console.log('aqui cambia lista: ', user.priceListId);

  // Obtiene los productos basados en la nueva dirección del usuario
  const fetchedProducts = await getProducts(user);

  //console.log('prod fet1: ', products[0]?.saleMin ?? 'N/A1');
  //console.log('prod fet2: ', fetchedProducts[0]?.saleMin ?? 'N/A1');

  // Actualiza el mínimo de la orden y refresca el carrito de compras
  changeMinimumAmount(foundAddress?.orderSaleMin ?? 40);
  RefreshProductToChartAction(fetchedProducts);
};

//   useEffect(() => {
//     console.log('Productos actualizados ef: ', products[0]?.saleMin?? 'N/A2');
//     //const foundAddress = user?.deliveryAddresses?.find(address => address.addressId === user.addressId);
//     //changeMinimumAmount(foundAddress?.orderSaleMin ?? 40)
//     //RefreshProductToChartAction(products);
//     //setProducts(products);
// }, [products]);

  // Función para cambiar el valor de MINIMUM_AMOUNT
  const changeMinimumAmount = (newAmount) => {
    setMinimumAmount(newAmount);
  };

  const handleContinue = () => {
    if(totalPrice.total < MINIMUM_AMOUNT || totalPrice.ProductsMin > 0) return;

    nextStepper();
    handleStartOrderAndUpdateOrder(user)
  }
  
//   return (
//     <div>
//      <div>
//         <p>Subtotal: {totalPrice.subTotal}</p>
//         <p>Productos mínimos: {totalPrice.ProductsMin}</p>
//         <p>Total: {totalPrice.total}</p>
//         <p>Descuento: {totalPrice.discount}</p>
//       </div>
//     </div>
//   );
// };

  return (
    <div className="card-resume-container">
      <h4 className="card-title">Resumen del Carrito</h4>      
      <h4 className="card-subtitle">Enviar a:</h4>     
      <select className="card-address" onChange={handleChangeAddress} value={addressSelected}>
        {user.deliveryAddresses.map((address) => (
          <option key={address.address} value={address.addressId}>{address.address}</option>
        ))}
      </select>
      <div className="card-body">
        <div className="card-subtotal">
          <p className="card-label-content">Subtotal</p>
          <p className="card-label-value">S/{totalPrice.subTotal}</p>
        </div>
        <div className="card-discount">
          <p className="card-label-content">Descuentos</p>
          <p className="card-label-value">S/{totalPrice.discount}</p>
        </div>
        <div className="card-total">
          <p className="card-label-content">Total</p>
          <p className="card-label-value">S/{totalPrice.total}</p>
        </div>
      </div>
      <div className="card-footer">
        <p className="card-quantity">Tiene ({chart.products.length}) ítems en el carrito</p>
        <Box 
          sx={{ 
            display: { xs: 'flex', md: 'flex' }, 
            flexDirection: 'row', // Esto asegura que los elementos se muestren en fila
            alignItems: 'center', // Opcional: Centra verticalmente si los elementos tienen diferentes alturas
            gap: '2px' // Opcional: Controla el espacio entre los elementos
          }}
          >
        <Link to="/tienda" className="card-back-store">
        <button className='addcart-buttonWhite2 fadeIn' 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}>
                            Seguir comprando
        </button>
        </Link>
        <button 
          className={`btn-start-order ${(chart.products.length === 0 || totalPrice.total < MINIMUM_AMOUNT || totalPrice.ProductsMin > 0) ? 'btn-disabled' : ''}`} 
          data-btn-id="btn-cart-prepare-order" 
          id='btn-cart-prepare-order'
          disabled={(chart.products.length === 0 || totalPrice.total < MINIMUM_AMOUNT || totalPrice.ProductsMin > 0)} onClick={handleContinue}
        >
          Siguiente
          
        </button>
        </Box>
        <p className="card-quantity-down"  hidden={!(chart.products.length === 0 || totalPrice.total < MINIMUM_AMOUNT)} >Orden mínima de {MINIMUM_AMOUNT} soles</p>
        <p className="card-quantity-down"  hidden={!(chart.products.length === 0 || totalPrice.ProductsMin > 0)} >Verifique mínimo de productos</p>
      </div>
    </div>
  );
};
