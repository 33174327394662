import {useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/UserCatalogContainer.module.css';

import UserItemListContainer from './UserItemListContainer';
import { useProducts } from '../context/ProductsContext';
import SpinnerTeresa from '../components/SpinnerTeresa';
import { PromotionBanners } from '../components/PromotionBanners';

const UserCatalogContainer = () => {
  const [orden, setOrden] = useState(['1', '2', '3']);
  const { products, bestSellers, moreRentability, moreInteresting } = useProducts();

  useEffect(() => {
    if(products.length!==0)
    (async () => {
      try {
        let config = {
          method: 'get',
          url: process.env.REACT_APP_GET_ORDER_ORDENCATALOG_URL,
          headers: {
            api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
            'Content-Type': 'application/json',
          },
        };
        const rpta = await axios(config);
        let ordenTemp = [];
        let oTemp;
        for (let i = 1; i < 4; i++) {
          oTemp = rpta.data.filter(function (item) {
            if (item.positionOrderCatalog == i.toString()) return item;
          });
          ordenTemp.push(oTemp[0].id);
        }
        setOrden(ordenTemp);
        // setOrden([3, 2, 1]);
        // console.log('bestSellers', moreInteresting);
        // console.log('rpta.data', rpta.data);
        // console.log('ORDEN DE MUESTRA => ', ordenTemp);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className={styles.catalog}>
      {(products.length === 0) ? (
        <SpinnerTeresa />
      ) : (
        // <>
        //   {orden.map((item, index) => {
        //     switch (item) {
        //       case '1':
        //         return (
        //           <>
        //             <UserItemListContainer key="best_seller" title="Nuestros productos más vendidos" index={index} maxItemsToShow={50} items={bestSellers} />
        //             <PromotionBanners />
        //           </>
        //         );
        //         break;
        //       case '2':
        //         return <UserItemListContainer key="more_rentability" title="Los más rentables" index={index} maxItemsToShow={50} items={moreRentability} />;
        //         break;
        //       case '3':
        //         return <UserItemListContainer key="more_interesting" title="Los más interesantes" index={index} maxItemsToShow={50} items={moreInteresting} />;
        //         break;
        //     }
        //   })}
        // </>
        <>
          <UserItemListContainer key="best_seller" title="Los más vendidos" maxItemsToShow={50} items={bestSellers} />
          
          <UserItemListContainer key="more_interesting" title="Los recomendados"  maxItemsToShow={50} items={moreInteresting} />

          <UserItemListContainer key="more_rentability" title="Los más rentables"  maxItemsToShow={50} items={moreRentability} />
          {/* <PromotionBanners /> */}
                
        </>
      )}
    </div>
  );
};

export default UserCatalogContainer;
