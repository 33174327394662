import AdminHeader from './AdminHeader';
import UserHeader from './UserHeader';
import Footer from './Footer';
import CategoriaIcon from '../assets/Categorias.svg';
import MarcaIcon from '../assets/Marcas.svg';
import HomeIconMovil from '../assets/HomeMovil.svg';
import PromocionesIcon from '../assets/Promociones.svg';
import FinalizarIcon from '../assets/Finalizar.svg';
import { BottomNavigation, BottomNavigationAction, Box, Typography } from '@mui/material';
import { IoMdHome, IoMdMenu } from 'react-icons/io';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import Marquee from 'react-fast-marquee'

const MainLayout = ({ access, nextInfinity, children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  if (nextInfinity) return children;

  return (
    <>
      {access === 'user' ? (
        <div>
          <NavLink to='/tienda/promociones'>
            <Marquee style={{ backgroundColor: 'var(--primary-color)', width: '100%' }} gradient={false} speed={70}>
              <Box sx={{
                width: 'Fixed (375px)px',
                height: 'Hug (32px)px',
                top: '44px',
                padding: '8px 16px 8px 16px',
                gap: '10px',
                opacity: '0px'
              }} display='flex' alignItems='center' gap={{ xs: 5, md: 10 }}>
                <Typography sx={{                  
                  fontWeight: '700',
                  lineHeight: '16px',
                  textAlign: 'center', fontFamily: 'AmsiPro', fontSize: { xs: '14px', md: '20px' }, color: 'white'
                }}>
                  ¡Click para ver todas las ofertas!
                </Typography>
                <Typography sx={{                   
                  fontWeight: '700',
                  lineHeight: '16px',
                  textAlign: 'center', fontFamily: 'AmsiPro', fontSize: { xs: '14px', md: '20px' }, color: 'white' }}>
                  ¡Click para ver todas las ofertas!
                </Typography>
                <Typography sx={{                   
                  fontWeight: '700',
                  lineHeight: '16px',
                  textAlign: 'center', fontFamily: 'AmsiPro', fontSize: { xs: '14px', md: '20px' }, color: 'white' }}>
                  ¡Click para ver todas las ofertas!
                </Typography>
                <Typography sx={{ 
                  fontWeight: '700',
                  lineHeight: '16px',
                  textAlign: 'center', fontFamily: 'AmsiPro', fontSize: { xs: '14px', md: '20px' }, color: 'white' }}>
                  ¡Click para ver todas las ofertas!
                </Typography>
                <Typography sx={{ 
                  fontWeight: '700',
                  lineHeight: '16px',
                  textAlign: 'center', fontFamily: 'AmsiPro', fontSize: { xs: '14px', md: '20px' }, marginRight: { xs: 5, md: 10 }, color: 'white' }}>
                  ¡Click para ver todas las ofertas!
                </Typography>
              </Box>
            </Marquee>
          </NavLink>
          <UserHeader />
          {/* <div style={{ margin: '30px' }}>{children}</div> */}
          <div style={{ marginBottom: '0' }}>{children}</div>

          {
            location.pathname !== '/carrito'
              ?
              (
                <div style={{ marginTop: '30px' }}>
                  <Footer />

                  <BottomNavigation
                    sx={{ display: { xs: 'flex', md: 'none' }, position: 'fixed', bottom: 0, minHeight: '60px', width: '100%', backgroundColor: 'var(--primary-color)' }}
                    showLabels
                  // value={value}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                  >
                    <BottomNavigationAction sx={{ color: 'white', fontFamily: 'AmsiPro', fontWeight: 100, borderRight: '1px solid rgba(244, 244, 244, 0.4)' }} onClick={() => navigate('/tienda/')} label="Inicio" icon={<Box component={'img'} src={HomeIconMovil} />} />
                    <BottomNavigationAction sx={{ color: 'white', fontFamily: 'AmsiPro', fontWeight: 100, borderRight: 'thin solid rgba(244, 244, 244, 0.4)' }} onClick={() => navigate('/tienda/categories')} label="Categorias" icon={<Box component={'img'} src={CategoriaIcon} />} />
                    <BottomNavigationAction sx={{ color: 'white', fontFamily: 'AmsiPro', fontWeight: 100, borderRight: '1px solid rgba(244, 244, 244, 0.4)' }} onClick={() => navigate('/tienda/promociones')} label="Promociones" icon={<Box component={'img'} src={PromocionesIcon} />} />
                    <BottomNavigationAction sx={{ color: 'white', fontFamily: 'AmsiPro', fontWeight: 100 }} label="Finalizar" icon={<Box component={'img'} onClick={() => navigate('/carrito')} src={FinalizarIcon} />} />
                  </BottomNavigation>
                </div>
              )
              : ""
          }
        </div>
      ) : (
        <div>
          <AdminHeader />
          <div className="w-full" style={{ margin: '50px auto 90px auto', textAlign: 'center' }}>
            {children}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MainLayout;