import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import SpinnerTeresa from '../components/SpinnerTeresa';
import { useProducts } from '../context/ProductsContext';
import MainLayout from '../layout/MainLayout';

export const UserAllCategories = () => {
  const { products, categories, getCategories } = useProducts();
  const navigate = useNavigate();

  useEffect(() => {
    if (categories.length === 0) getCategories(products);
  }, [products]);

  return (
    <MainLayout access="user">
      { products.length === 0 ? (
        <SpinnerTeresa/>
      ):(
        <Box sx={{ margin: '15px 21px' }}>
          <Grid container spacing={1}>
            {categories.map((item, index) => (
              <Grid item xs={12} md={3} key={index}>
                <button style={{ width:'100%'}} onClick={() => navigate('/tienda/' + item.name.split(' ').join('-') + '/home')}>
                  <Box display="flex" sx={{ border: '1px solid var(--primary-color)', borderRadius:'5px', padding:'0 10px'}} justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center" gap={2}>
                      <Box
                        component="img"
                        src={'https://images-dev.vedoraplus.com/categorias/' + item.name + '.webp'}
                        alt={'Imagen no disponible'}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://images-dev.vedoraplus.com/75016.webp';;
                        }}
                        sx={{
                          width: { xs: '70px', md: '113px' },
                          height: { xs: '70px', md: '113px' },
                          borderRadius: '50%',
                        }}
                      />
                      <Typography sx={{ fontSize:'20px', color:'#2D85C5'}}>{item.name}</Typography>
                    </Box>
                    <IoIosArrowForward size={25} color={'var(--primary-color)'}/>
                  </Box>
                </button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </MainLayout>
  );
};
