/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const TypeBase7 = ({ className }) => {
  return (
    <svg
      className={`type-base-7 ${className}`}
      fill="none"
      height="36"
      viewBox="0 0 37 36"
      width="37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.5 26L16.1315 23.6562H12.8438V20.3685L10.5 18L12.8438 15.6315V12.3438H16.1315L18.5 10L20.8685 12.3438H24.1562V15.6315L26.5 18L24.1562 20.3685V23.6562H20.8685L18.5 26ZM18.5 24.4104L20.3854 22.525H23.025V19.8854L24.9104 18L23.025 16.1146V13.475H20.3854L18.5 11.5896L16.6146 13.475H13.975V16.1146L12.0896 18L13.975 19.8854V22.525H16.6146L18.5 24.4104Z"
        fill="#999999"
      />
    </svg>
  );
};
