import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemText, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useLayoutMenu } from '../context/LayoutContext';
import { useProducts } from '../context/ProductsContext';

import '../styles/SearchSidebar.css';

export const SearchSideBar = () => {
  const { filteredProducts, setFilteredProducts, searchProducts, getCategoriesSearch, filterProductsByCategory } = useProducts();
  const { closeSideMenu } = useLayoutMenu()
  const [priceLimit, setPriceLimit] = useState({
    min: 0,
    max: 100,
  });

  const [openDropDown, setOpenDropDown] = useState({
    status: false,
    category: ''
  });

  const [filterApplied, setFilterApplied] = useState({
    range: {
      status: false,
      minRange: 0,
      maxRange: 0,
    },
    category: {
      status: false,
      category: '',
      subcategory: 'HOME',
    },
  });

  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [rangePrices, setRangePrices] = useState([0, 100]);

  const handleOpenDropDown = (category) => {

    setOpenDropDown({
      status: !openDropDown.status,
      category
    });
  };

  const handleChangeCategoryFilter = (category) => {

    let productsToFilter = filteredProducts;

    if(filterApplied.category.status) {
      productsToFilter = searchProducts
    }

    if(filterApplied.range.status) {
      productsToFilter = productsToFilter.filter((product) => product.salePrice >= rangePrices[0] && product.salePrice <= rangePrices[1])
    }


    filterProductsByCategory(productsToFilter, categoriesFiltered, category, 'HOME')

    setFilterApplied({
      ...filterApplied,
      category: {
        status: true,
        category,
        subcategory: 'HOME',
      },
    });

    closeSideMenu()
  }

  const handleChangeSubCategoryFilter = (category, subcategory) => {
    console.log('llego: ', category + ' - ' +  subcategory);
    let productsToFilter = filteredProducts;

    if(filterApplied.category.status) {
      productsToFilter = searchProducts
    }

    if(filterApplied.range.status) {
      productsToFilter = productsToFilter.filter((product) => product.salePrice >= rangePrices[0] && product.salePrice <= rangePrices[1])
    }


    filterProductsByCategory(productsToFilter, categoriesFiltered, category, subcategory)

    setFilterApplied({
      ...filterApplied,
      category: {
        status: true,
        category,
        subcategory
      },
    });

    closeSideMenu()
  }
  
  

  const getMinMaxPrice = () => {
    if (filteredProducts.length === 0) return;

    
    const minMaxPrices = filteredProducts.reduce(
      (acc, curr) => {
        if (curr.salePrice > acc.max) {
          acc.max = Number(curr.salePrice);
        }

        if (curr.salePrice < acc.min) {
          acc.min = Number(curr.salePrice);
        }
        return acc;
      },
      {
        min: Number(Number.MAX_VALUE),
        max: 0,
      },
    );
    setPriceLimit(minMaxPrices);
    setRangePrices([minMaxPrices.min, minMaxPrices.max]);
  
  };

  const onChangeFilterPrice = (e, newValue) => {
    setRangePrices(newValue);
  };

  const filterByPrice = () => {
    let productsToFilter = filteredProducts;
    let newFilteredProducts = [];
    
    if(filterApplied.range.status) {
      productsToFilter = searchProducts;
    }
    newFilteredProducts = productsToFilter.filter((product) => product.salePrice >= rangePrices[0] && product.salePrice <= rangePrices[1]);
    console.log('filtro3: ', newFilteredProducts);
    setFilterApplied({
      ...filterApplied,
      range: {
        status: true,
        minRange: rangePrices[0],
        maxRange: rangePrices[1]
      }
    });
    closeSideMenu()
    
    if (!filterApplied.category.status) {
      return setFilteredProducts(newFilteredProducts);
    }

    filterProductsByCategory(newFilteredProducts, categoriesFiltered, filterApplied.category.category, filterApplied.category.subcategory)
  };

  useEffect(() => {
    getMinMaxPrice();
    setCategoriesFiltered(getCategoriesSearch(searchProducts));
    // getCategories(searchProducts)
  }, [searchProducts]);

  return (
    <div className="sidebar-container">
      <div className="price-filter__section">
        <p className="price-filter__title">Precio</p>
        <Divider sx={{ color: '#9BCDE6', backgroundColor: '#9BCDE6' }} />
        <div className="price-filter__input">
          <Slider
            sx={{
              color: '#21AEE0',
              marginTop: '25px',
              paddingX: '0px',
            }}
            value={rangePrices}
            onChange={onChangeFilterPrice}
            valueLabelDisplay="auto"
            min={priceLimit.min}
            max={priceLimit.max}
            valueLabelFormat={(value) => value}
          />
        </div>
        <div className="price-ranges__section">
          <p className="price-ranges__text">
            S/{Number(rangePrices[0]).toFixed(1)} - S/{Number(rangePrices[1]).toFixed(1)}
          </p>
          <button className="price-ranges__button" onClick={filterByPrice}>
            Filtrar
          </button>
        </div>
      </div>
      <div className="category-filter__section">
        <p className="price-filter__title">Categorias</p>
        <Divider sx={{ color: '#9BCDE6', backgroundColor: '#9BCDE6' }} />
        <List>
          {categoriesFiltered.map((category) => (
            <Fragment key={category.name}>
              <Box display="flex">
                <ListItemButton onClick={() => handleChangeCategoryFilter(category.name)}>
                  <ListItemText primary={category.name} sx={{ color: '#2D85C5'}} primaryTypographyProps={{fontSize: {xs: '13px', md: '22px'}}}/>
                </ListItemButton>
                <IconButton onClick={() => handleOpenDropDown(category.name)}>
                  {(openDropDown.status && category.name === openDropDown.category) ? <ExpandLess  sx={{ color: '#9BCDE6'}}/> : <ExpandMore sx={{ color: '#9BCDE6'}}/>}
                </IconButton>
              </Box>
              <Collapse in={openDropDown.status && category.name === openDropDown.category} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {category.subcategories.map((subcategory) => (
                    <ListItemButton key={subcategory.name} onClick={() => handleChangeSubCategoryFilter(category.name, subcategory.name)}>
                      <ListItemText primary={subcategory.name} sx={{ color: '#2D85C5'}} primaryTypographyProps={{fontSize: {xs: '13px', md: '22px'}}}/>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </div>
    </div>
  );
};
