import { useEffect, useState, useRef } from 'react'
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { useProducts } from '../context/ProductsContext'
import useOnScreen from '../hooks/useOnScreen'
import '../styles/VideoBanner.css'

export const VideoBanner = ({ item }) => {
  const { products, setSearchProducts, setFilteredProducts, setSearch } = useProducts()
  const navigate = useNavigate()

  const [played, setPlayed] = useState(false)
  const [muted, setMuted] = useState(true)
  const videoRef = useRef()
  const isVisible = useOnScreen(videoRef)

  useEffect(() => {
    setPlayed(isVisible)
  }, [isVisible])

  const handleMuted = () => {
    setMuted(!muted)
  }

  const handleClickBanner = (ids) => {
    const newProducts = ids
      .map((id) => {
        return products.filter((product) => product.code === id)
      })
      .flat()

    if (newProducts.length === 0) return
    
    setSearchProducts(newProducts)
    setFilteredProducts(newProducts)
    setSearch('p')
    navigate('/tienda/busqueda')
  }

  return (
    <div className='video-wrapper' ref={videoRef}>
      <ReactPlayer
        className='react-player'
        url={item.imgUrl}
        width='auto'
        height='100%'
        onClick={() => handleClickBanner(item.relatedProductsIds)}
        loop={true}
        muted={muted}
        playing={played}
        volume={0.5}
      />

      <div className='volume-control'>
        <button className='btn-volume' onClick={handleMuted}>
          {muted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
      </div>
    </div>
  )
}
