import { useEffect, useRef, useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import { GET_BANNER_SERVICE } from '../services/bannerService';
import styles from '../styles/SlideShow.module.css';
import { useProducts } from '../context/ProductsContext';
import { useNavigate } from 'react-router-dom';
import { isImage, isVideo } from '../utils/typeOfUrl';
import { VideoBanner } from './VideoBanner';
import Carousel from 'react-multi-carousel';

const SlideShow = () => {
  const slideshow = useRef(null);
  const { user } = useUserAuth();
  const { products, setSearchProducts, setFilteredProducts, setSearch, banners } = useProducts();
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleClickBanner = (ids) => {
    const newProducts = ids
      .map((id) => {
        return products.filter((product) => product.code === id);
      })
      .flat();

    if(newProducts.length === 0) return;

    setSearchProducts(newProducts);
    setFilteredProducts(newProducts);
    setSearch('p');
    navigate('/tienda/busqueda');
  };

  return (
    <>
      <div className={styles.container}>
        <Carousel className={styles.container__slideshow} containerClass="banner-carousel" ref={slideshow} autoPlay autoPlaySpeed={8000} responsive={responsive} partialVisbile={false} infinite>
          {banners.topBanners.map((item) => (
            <div key={item.id} className={styles.slide} id={item.id}>
              {isImage(item.imgUrl) ? <img className={styles.slide__img} src={item.imgUrl} alt="" onClick={() => handleClickBanner(item.relatedProductsIds)} /> : ''}

              {isVideo(item.imgUrl) ? (
                <div style={{ width: '100%', maxHeight: '400px' }}>
                  <VideoBanner item={item} />
                </div>
              ) : (
                ''
              )}
              <button className={styles.btn__banners} onClick={() => handleClickBanner(item.relatedProductsIds)}>
                Ver Productos Relacionados
              </button>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default SlideShow;
