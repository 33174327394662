import axios from 'axios';
import { useState, useEffect } from 'react';

import { Typography, Box, Grid, Container, Paper, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import PowerOffIcon from '@mui/icons-material/PowerOff';
import DoneIcon from '@mui/icons-material/Done';
import LoopIcon from '@mui/icons-material/Loop';

const AdminClientsContainer = () => {
  const [code, setCode] = useState('');
  const [number, setNumber] = useState('');
  const [datos, setDatos] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [selected, setSelected] = useState({});
  const [disconected, setDisconected] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      field: 'ClientCode',
      headerName: 'Código',
      flex: 1,
      // width: 200,
      description: 'Código de cliente en el ERP.',
      sortable: false,
    },
    {
      field: 'MobilePhone',
      headerName: 'Número de teléfono',
      flex: 1,
      description: 'Número de teléfono del cliente.',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Acciones',
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
            {/* <MatEdit index={params.row.id} /> */}
            <Button
              variant="text"
              onClick={(e) => {
                startLogoutClient(params.row.id);
              }}
              sx={{ textTransform: 'none', fontWeight: '400' }}
              endIcon={<PowerOffIcon />}
            >
              Desconectar
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllClients();
  }, [disconected]);

  const startLogoutClient = (index) => {
    setSelected(datos.find((item) => item.id === index));
  };

  const handleClose = () => {
    setSelected({});
  };

  const handleConfirmButton = () => {
    deleteWhatsappUser(selected.MobilePhone);
    setSelected({});
    setDisconected(true);
  };

  const handleDisconected = () => {
    setDisconected(false);
  };

  const handleActualizar = () => {
    getAllClients();
    setUpdated(true);
  };

  const getAllClients = async () => {
    const config = {
      method: 'post',
      url: process.env.REACT_APP_GET_WHATSAPP_USERS_URL,
      headers: {
        api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios(config);
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].id = i + 1;
      }
      setDatos(res.data);
    } catch (error) {
      return error;
    }
  };

  const deleteWhatsappUser = async (clientMobile) => {
    const data = JSON.stringify({
      mobilePhone: clientMobile,
    });

    let config = {
      method: 'delete',
      url: process.env.REACT_APP_DELETE_WHATSAPP_USERS_URL,
      headers: {
        api_key: process.env.REACT_APP_ECONOMYSA_API_KEY,
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config)
      .then((res) => JSON.stringify(res.data.success))
      .catch((error) => error);
  };

  const handleSearchForCode = ({ target }) => {
    setCode(target.value);
  };

  const handleSearchForNumber = ({ target }) => {
    setNumber(target.value);
  };

  useEffect(() => {
    const filtrados = datos.filter((dato) => dato.ClientCode.includes(code));
    setFiltrados(filtrados);
  }, [code, datos]);

  useEffect(() => {
    const filtrados = datos.filter((dato) => dato.MobilePhone.includes(number));
    setFiltrados(filtrados);
  }, [number, datos]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box p={1} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography component="div" flexGrow="1">
              <Box fontSize="18px" fontWeight="500">
                Clientes en WhatsApp
              </Box>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 py-3">
                <div>
                  <input placeholder="Código" className="border rounded p-2" onChange={handleSearchForCode} value={code} />
                </div>
                <div>
                  <input placeholder="Número" className="border rounded p-2" onChange={handleSearchForNumber} value={number} />
                </div>
              </div>
            </Typography>
            <Button variant="contained" sx={{ textTransform: 'none', color: 'white' }} onClick={handleActualizar} startIcon={<LoopIcon />}>
              Actualizar
            </Button>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
            {datos.length === 0 ? (
              <Box height="20vh" width="100%" alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid rows={filtrados.length !== 0 ? filtrados : datos} columns={columns} pageSize={18} rowsPerPageOptions={[18]} autoHeight disableMultipleSelection density="compact" />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={!!selected.id} onClose={handleClose}>
        <DialogTitle>¿Quieres desconectar a este cliente?</DialogTitle>
        <DialogContent>
          <Box p={2}>
            <Typography component="div">
              <Box m={1} display="flex">
                <Box m={1} color="#999999">
                  Número de teléfono:
                </Box>{' '}
                <Box m={1} fontWeight="500" color="#666666">
                  {selected.MobilePhone}
                </Box>
              </Box>
              <Box m={1} display="flex">
                <Box m={1} color="#999999">
                  Código cliente:
                </Box>{' '}
                <Box m={1} fontWeight="500" color="#666666">
                  {selected.ClientCode}
                </Box>
              </Box>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} sx={{ textTransform: 'none' }}>
            Cancelar
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                color: '#fff',
              }}
              onClick={() => {
                handleConfirmButton();
              }}
            >
              Confirmar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={!!disconected} onClose={handleDisconected}>
        <DialogTitle>Cliente desconectado con éxito</DialogTitle>
        <DialogActions>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                color: '#fff',
              }}
              onClick={() => {
                handleDisconected();
              }}
              endIcon={<DoneIcon />}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={!!updated} onClose={() => setUpdated(false)}>
        <DialogTitle>Lista de clientes actualizada con éxito</DialogTitle>
        <DialogActions>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                color: '#fff',
              }}
              onClick={() => {
                setUpdated(false);
              }}
              endIcon={<DoneIcon />}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminClientsContainer;
