import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SpinnerTeresa from '../components/SpinnerTeresa'
import { useProducts } from '../context/ProductsContext'
import MainLayout from '../layout/MainLayout'
import { useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthContext'

export const UserAllBrandPage = () => {
  const { products, brands, getProducts, getBrands, getBanners, setBrands } = useProducts()
  const { user } = useUserAuth()
  const navigate = useNavigate()

  const removeFromDom = (id) => {
    const img = document.querySelector(`#${id}`)
    if (img) {
      img.remove()
    }
  }
  const getBrandNames = (brands)=>{
   return brands.map(b=>(b.name))
  }
  const initialData = async () => {
    // eslint-disable-next-line no-debugger
    try {
      const products = await getProducts(user)
      const brands = getBrands(products)
      setBrands(getBrandNames(brands))
      // await getBanners(user)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-debugger
    if (!brands || brands.length === 0) {
      initialData(user)
    }
    // console.log('promotions luego de useEffect', promotions);
  }, [brands, products])

  return (
    <MainLayout access='user'>
      {products.length === 0 ? (
        <SpinnerTeresa />
      ) : (
        <Box sx={{ margin: { xs: '17px 21px', md: '70px 117px' }, backgroundColor: { xs: 'white', md: 'white' } }}>
          <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: { xs: 0, md: '10px 20px 20px 20px' } }}>
            {brands && brands?.map((item, index) => (
              <Grid item key={index} xs={6} md={2} id={item.replaceAll(' ', '')}>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    maxWidth: '250px',
                    border: '1px solid var(--primary-color)',
                    borderRadius: '5px',
                    minHeight: '100px',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/tienda/marca/' + item.toLowerCase().split(' ').join('-') + '/home')}>
                  <Box
                    component={'img'}
                    src={'https://images-dev.vedoraplus.com/brands/' + item.toLowerCase() + '.png'}
                    alt={item}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src =
                        '/assets/images/NoDisponible.png'
                      
                    }}
                    sx={{
                      // width: 'auto',
                      height: 'auto',
                      width: '100%',
                      maxWidth: { xs: '200px', md: '200px' },
                      maxHeight: { xs: '100px', md: '100px' },
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </MainLayout>
  )
}
