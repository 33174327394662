/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Logo = ({ className }) => {
  return (
    <svg
      className={`logo ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 100 20"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.1253 11.1162C15.1253 12.9786 15.1197 14.8404 15.1291 16.7028C15.1315 17.2487 14.9521 17.6734 14.449 17.9237C14.159 18.0678 13.8578 18.1075 13.5504 17.9703C13.4753 17.9368 13.394 17.9125 13.325 17.8678C11.983 16.9996 10.4994 16.7947 8.93138 16.8369C7.09133 16.8866 5.24693 16.7991 3.4075 16.8636C1.83386 16.9189 -0.00246737 15.7042 0.000637678 13.4531C0.00436373 10.9082 0.00684777 8.36326 1.66683e-05 5.81836C-0.00619343 3.63427 1.72394 2.36556 3.39135 2.39226C6.18403 2.43697 8.97857 2.43635 11.7713 2.39288C13.5827 2.36494 15.1762 3.87709 15.1334 5.77863C15.093 7.5572 15.1247 9.33701 15.1247 11.1168L15.1253 11.1162Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M7.36208 9.87786C7.59745 9.62387 7.66638 9.35995 7.77071 9.11961C8.23274 8.05831 8.68856 6.99451 9.1419 5.9301C9.36981 5.39603 9.75918 5.1172 10.3479 5.13894C11.1614 5.16875 11.7104 5.93445 11.4328 6.70512C11.2092 7.32613 10.9236 7.9254 10.6627 8.53275C9.96908 10.1468 9.27169 11.7589 8.57988 13.3735C8.44761 13.6822 8.30043 13.9759 7.99862 14.1547C7.35587 14.5348 6.56409 14.2777 6.24116 13.5586C5.93625 12.8804 5.66611 12.1868 5.37982 11.4999C4.73583 9.95486 4.09744 8.40731 3.44475 6.86597C3.24541 6.39462 3.31558 5.97419 3.61305 5.58482C3.90554 5.20228 4.31603 5.08242 4.77558 5.16501C5.26742 5.2532 5.55681 5.57798 5.74311 6.03256C6.21322 7.18019 6.69947 8.32036 7.18013 9.46364C7.22795 9.57729 7.27949 9.6897 7.3627 9.87849L7.36208 9.87786Z"
        fill="white"
      />
      <path
        className="path"
        d="M12.1215 13.7232C12.1159 13.3562 12.3631 13.0935 12.7264 13.0804C13.0828 13.0674 13.3722 13.3239 13.3915 13.6685C13.4119 14.0343 13.1139 14.3485 12.7462 14.3498C12.3966 14.351 12.1277 14.0815 12.1221 13.7232H12.1215Z"
        fill="#FFFCFA"
      />
      <path
        className="path"
        d="M11.7539 13.8244C11.7365 14.1187 11.4763 14.3578 11.1807 14.351C10.8528 14.3435 10.6118 14.0678 10.6447 13.7374C10.6746 13.4362 10.9403 13.2114 11.2335 13.24C11.5191 13.2673 11.77 13.5493 11.7539 13.8244Z"
        fill="#FFF7F4"
      />
      <path
        className="path"
        d="M10.1243 13.8778C10.1181 14.1219 9.88588 14.3485 9.63996 14.3498C9.35864 14.351 9.13259 14.1057 9.15619 13.825C9.17792 13.5741 9.41888 13.3605 9.65859 13.3791C9.89333 13.3978 10.1293 13.6505 10.1237 13.8778H10.1243Z"
        fill="#FFF6F1"
      />
      <path
        className="path"
        d="M25.9061 7.72728C25.8216 7.90861 25.6974 8.21912 25.5633 8.50976C24.8528 10.1399 24.143 11.7701 23.4332 13.4002C23.3922 13.4952 23.35 13.5896 23.3109 13.6853C23.0594 14.3019 22.6309 14.6503 21.9353 14.6397C21.2665 14.6292 20.8678 14.2783 20.6238 13.6871C19.9531 12.0625 19.2743 10.4405 18.5993 8.81778C18.3689 8.26383 18.113 7.71921 17.9168 7.15347C17.5529 6.10334 18.3521 5.12836 19.4314 5.28486C20.0003 5.36745 20.3443 5.72763 20.5573 6.23934C20.9305 7.1367 21.305 8.03344 21.6857 8.92832C21.7733 9.13449 21.8223 9.36365 22.0353 9.57169C22.3452 8.85629 22.6451 8.16572 22.9445 7.47454C23.1587 6.98146 23.3469 6.47471 23.5934 5.9984C23.8642 5.47427 24.325 5.22338 24.923 5.26685C25.4894 5.30845 25.8986 5.58542 26.1141 6.11701C26.2514 6.45608 26.2433 6.80385 26.1197 7.14727C26.0495 7.34102 25.9775 7.53415 25.9061 7.7279"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M62.0991 9.13823C61.8184 9.58163 61.4527 9.88159 60.8956 9.89338C60.3212 9.90518 59.9051 9.64932 59.612 9.17239C59.558 9.08482 59.5269 8.98422 59.4822 8.89107C59.1649 8.23155 58.5873 7.90925 57.9328 8.02538C57.331 8.13158 56.8578 8.65509 56.7752 9.32206C56.7522 9.50588 56.7597 9.69404 56.7597 9.87972C56.7584 11.018 56.769 12.1563 56.7553 13.2947C56.746 14.0796 56.1958 14.6186 55.427 14.636C54.6402 14.6534 54.0185 14.1013 54.0074 13.3127C53.9881 11.9055 53.9676 10.497 54.0148 9.09103C54.0806 7.13112 55.8158 5.39169 57.762 5.265C59.8709 5.12776 61.3937 6.10708 62.1538 8.08375C62.2003 8.20547 62.2339 8.33216 62.2457 8.46195C62.2674 8.70104 62.2376 8.90473 62.0997 9.13823H62.0991Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M44.1935 2.17363C44.16 2.12146 44.1277 2.06867 44.0929 2.01713C43.6775 1.40419 43.1683 1.1825 42.574 1.35638C41.9616 1.53585 41.612 2.03762 41.5965 2.77289C41.5897 3.10327 41.5952 3.43428 41.5952 3.76466C41.5952 4.39809 41.5952 5.03152 41.5952 5.65936C41.3139 5.69352 41.1369 5.56124 40.9426 5.50349C40.026 5.22962 39.1106 5.22713 38.1958 5.4954C36.1179 6.10461 34.7778 7.99187 34.8691 10.2374C34.9449 12.1048 35.8993 13.4505 37.5456 14.246C39.1801 15.036 40.801 14.8727 42.2939 13.8207C43.3179 13.099 43.9197 12.0837 44.1998 10.8702C44.2643 10.3933 44.2426 9.03082 44.2476 8.4539C44.2637 6.64428 44.2569 4.83465 44.2494 3.02503C44.2482 2.74185 44.3165 2.44997 44.1923 2.17363H44.1935ZM39.5503 11.9483C38.507 11.9483 37.622 11.0559 37.6202 10.0039C37.6183 8.94698 38.479 8.08501 39.5515 8.07011C40.5991 8.0552 41.4717 8.93456 41.4698 10.0021C41.4679 11.0628 40.5942 11.9489 39.5503 11.9483Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M71.5391 9.71081C71.4621 7.35222 69.4482 5.37678 67.084 5.29791C66.2108 5.26873 65.4048 5.44943 64.6993 5.81707C64.69 5.82204 64.6776 5.82825 64.6633 5.83632C64.4807 5.93382 63.0064 6.74672 62.4488 8.33526C62.4401 8.35948 62.4307 8.38309 62.4208 8.40731C62.3718 8.52655 62.3165 8.70787 62.2736 8.85939C62.2258 9.02707 62.2004 9.19971 62.1966 9.37421C62.1904 9.70645 62.1792 10.0387 62.1941 10.3703C62.3376 13.5673 65.9761 15.7414 68.739 14.2162C68.9687 14.0895 69.1165 14.0622 69.324 14.2789C69.7133 14.6845 70.2039 14.823 70.7399 14.5864C71.2839 14.3454 71.554 13.9113 71.5521 13.3102C71.5478 12.1104 71.5776 10.91 71.5385 9.71204L71.5391 9.71081ZM66.8691 11.9489C65.8295 11.9446 64.9427 11.0497 64.9402 10.0027C64.9378 8.9333 65.7848 8.0937 66.8703 8.09059C67.959 8.08749 68.7967 8.92088 68.793 10.0033C68.7899 11.0572 67.9043 11.9526 66.8691 11.9489Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M29.7358 11.2491C29.7358 11.2491 29.7396 11.2478 29.7414 11.2478C30.9797 10.9181 32.2205 10.5964 33.4576 10.2635C34.4804 9.98843 34.8499 9.29103 34.5244 8.27817C33.7693 5.92827 31.2673 4.69307 28.8236 5.46126C28.4255 5.58671 28.0653 5.75625 27.7399 5.96491C26.7115 6.62504 26.2197 7.51184 26.0458 7.87203C25.9458 8.07944 25.7341 8.55825 25.6347 9.21341C25.5297 9.9046 25.6024 10.4474 25.6353 10.6535C25.6682 10.8591 25.7986 11.6086 26.3147 12.4191C28.1678 15.3266 31.5678 14.9627 33.5259 13.6226C33.9749 13.3152 34.1711 12.8128 34.2239 12.2843C34.2611 11.9129 34.1015 11.5975 33.76 11.4012C33.1905 11.074 32.6279 11.0609 32.0621 11.4198C31.4455 11.8111 30.7661 11.9477 30.042 11.8744C29.9358 11.8639 29.8327 11.8459 29.7321 11.8173C29.4458 11.7359 29.4471 11.3261 29.7346 11.2491H29.7358ZM28.4423 9.22894C28.7789 7.87265 30.8058 7.45222 31.4064 8.44397C30.4295 8.70293 29.4744 8.95569 28.4423 9.22894Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M53.7801 9.79525C53.6708 7.92229 52.7511 6.57283 51.1104 5.76986C49.4535 4.95882 47.8109 5.14265 46.3025 6.21514C45.2983 6.9293 44.7127 7.9347 44.4376 9.12704C44.2656 9.83437 44.5041 11.0665 44.527 11.1671C44.9891 13.1692 47.0105 15.0018 49.6858 14.6783C52.0319 14.3951 53.9254 12.2843 53.7801 9.79587V9.79525ZM49.1008 11.9495C48.0544 11.9514 47.1725 11.0696 47.167 10.0151C47.1607 8.93267 47.991 8.09866 49.0815 8.0912C50.1658 8.08375 51.0209 8.92149 51.0222 9.99335C51.0234 11.0429 50.1348 11.947 49.1008 11.9489V11.9495Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M92.2845 8.67874C92.2845 9.4432 92.3174 10.2095 92.2783 10.9721C92.1746 12.9842 90.6177 14.6199 88.6385 14.8348C86.5277 15.064 84.6287 13.7996 84.0859 11.808C83.9592 11.3435 83.9313 10.8703 83.9307 10.394C83.9282 9.06936 83.9288 7.74473 83.9294 6.42074C83.9294 6.29654 83.9244 6.17109 83.9431 6.04875C83.9983 5.68112 84.2002 5.50661 84.5691 5.49109C84.9466 5.47556 85.1764 5.6215 85.2621 5.97237C85.3056 6.14998 85.3012 6.34126 85.3018 6.52632C85.3081 7.76834 85.3118 9.00974 85.3118 10.2518C85.3118 10.7976 85.4155 11.3205 85.6415 11.8173C86.1632 12.9631 87.1612 13.5742 88.3591 13.4841C89.449 13.4021 90.4736 12.5054 90.7804 11.3721C90.8897 10.9684 90.9145 10.5579 90.9133 10.1425C90.9108 8.94205 90.9114 7.74164 90.9133 6.54123C90.9133 6.33505 90.8965 6.12638 90.9686 5.92642C91.0785 5.62088 91.3815 5.42465 91.6927 5.45446C91.9883 5.48303 92.218 5.66312 92.2696 5.98356C92.3019 6.18663 92.2907 8.67811 92.2845 8.67811V8.67874Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M96.1316 5.46933C96.9688 5.4743 97.781 5.59912 98.5604 5.91273C98.8715 6.03817 99.1584 6.20274 99.3938 6.44121C99.6205 6.67098 99.6248 6.90324 99.4491 7.17151C99.2833 7.42489 99.0516 7.446 98.7951 7.3572C98.679 7.31683 98.566 7.25846 98.4617 7.19263C97.7245 6.72812 96.9011 6.5443 96.0521 6.44867C95.2144 6.35489 94.4841 6.8815 94.3077 7.69626C94.1432 8.45576 94.6157 9.18731 95.4566 9.41894C96.0739 9.5891 96.7061 9.70214 97.3258 9.86422C97.7649 9.97911 98.2039 10.1083 98.6262 10.2728C100.186 10.8796 100.484 12.9519 99.1764 14.0144C98.6647 14.4305 98.0791 14.6621 97.4302 14.7602C96.285 14.9341 95.1616 14.8807 94.0699 14.4684C93.5215 14.2616 93.026 13.9703 92.6279 13.5282C92.4304 13.309 92.3242 13.0841 92.5031 12.8041C92.6732 12.5383 92.8887 12.4687 93.2595 12.6271C93.6004 12.773 93.9233 12.9624 94.2518 13.1376C95.1281 13.6064 96.0466 13.8778 97.052 13.7399C97.1942 13.7207 97.3389 13.6934 97.4724 13.6424C98.1095 13.4002 98.6032 12.768 98.6001 12.2122C98.597 11.6589 98.104 11.0597 97.4059 10.8572C96.7321 10.6616 96.0385 10.5361 95.3572 10.3654C94.7952 10.225 94.2593 10.0232 93.7327 9.76796C92.472 9.15627 92.4969 6.92745 93.4408 6.22075C93.9531 5.83758 94.5114 5.59725 95.1399 5.52397C95.4684 5.48609 95.8006 5.48486 96.131 5.46747L96.1316 5.46933Z"
        fill="var(--primary-color)"
      />
      <path
        className="path"
        d="M81.8254 7.39697C81.8254 5.33087 81.8248 3.26477 81.8267 1.19805C81.8267 0.991875 81.8174 0.781356 81.8546 0.580148C81.9198 0.232383 82.162 0.0404923 82.5023 0.022483C82.8762 0.00261074 83.1246 0.182069 83.232 0.552191C83.296 0.77327 83.2948 1.00057 83.2948 1.22848C83.2941 5.38179 83.2941 9.53447 83.2948 13.6878C83.2948 13.9151 83.2966 14.1411 83.2196 14.361C83.1115 14.6715 82.8942 14.8311 82.5719 14.8435C82.2421 14.8565 82.0304 14.682 81.9006 14.3908C81.8043 14.1747 81.8267 13.9449 81.8267 13.7188C81.8254 11.6111 81.8261 9.50406 81.8254 7.39635V7.39697Z"
        fill="#FF7E42"
      />
      <path
        className="path"
        d="M81.1951 9.11654C80.7734 7.01628 78.8626 5.37867 76.5015 5.45816C73.6418 5.55379 71.9706 7.54041 71.9272 10.0412C71.8756 13.0407 71.9154 16.0414 71.9141 19.0415C71.9141 19.2297 71.9253 19.4135 72.0091 19.5861C72.1514 19.8786 72.3886 20.009 72.7159 19.973C73.0431 19.937 73.2269 19.7644 73.2847 19.4203C73.3052 19.2986 73.3008 19.1732 73.3083 19.049C73.4052 17.4573 73.3313 15.8638 73.3574 14.2715C73.3605 14.0741 73.312 13.8673 73.4294 13.6331C73.5884 13.7549 73.7244 13.8716 73.8728 13.9703C75.2291 14.8739 76.712 15.1248 78.2509 14.6249C80.4275 13.9182 81.6826 11.5441 81.1951 9.11654ZM79.8649 10.2089C79.8463 12.0111 78.3391 13.5015 76.5406 13.4381C74.4839 13.3655 73.3039 11.7242 73.3387 10.0915C73.3778 8.24091 74.7857 6.88835 76.653 6.87841C78.2391 6.86972 79.9581 8.29369 79.8649 10.2089Z"
        fill="var(--primary-color)"
      />
    </svg>
  );
};
