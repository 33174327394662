import { useState } from 'react';
import Product from '../components/Product';
import { Typography, Grid, Box, Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const UserItemListContainer = ({ title, maxItemsToShow, items, index }) => {
  const [limitItems, setLimitItems] = useState(10);

  const showMoreItems = () => {
    if (!(limitItems + 5 > maxItemsToShow)) {
      setLimitItems((st) => st + 5);
    } else {
      setLimitItems(maxItemsToShow);
    }
  };

  return (
    <>
      {items.length === 0 ? (
        <></>
      ) : (
        <Box sx={{ width: { xs: '100%', md: 'auto' }, maxWidth: { xs: '100%', md: '1730px' }, margin: '0 auto', marginTop: '40px' }} className="fadeIn">
          <Typography variant="h4" color="primary" component="div" p={1} mt={0} mb={'30px'} sx={{ fontSize: { xs: '18px', md: '35px' }, color: '#2D85C5', fontWeight: 'bold', padding: { xs: '0px 20px', md: '0px 35px' } }}>
            {/* <Box component="div" fontSize={24} p={1} mt={0}> */}
            {title}
            {/* </Box> */}
          </Typography>
          {/* <Box mb={1}>
        <Divider />
      </Box> */}
          <Box
            className="productoGrid"
            sx={{
              padding: { xs: '0px', md: '0px 40px' },
              display: 'grid',
              margin: { xs: '0px 20px', md: '0' },
              // paddingLeft:'5px',
              // gridTemplateColumns: 'repeat(auto-fit, minmax(50px, 180px))',
              // gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
              // gridTemplateColumns: {xs:'repeat(auto-fit, minmax(50px, 180px))', md:'repeat(auto-fit, minmax(180px, 297px))'},
              justifyContent: 'center',
              gap: { xs: '7px', md: '40px' },
            }}
          >            
            {items
              .slice(0, limitItems)
              // .filter((el) => el.stockAmount !== 0)
              .map((item, index) =>
                item.sponsoredVip === true ? (
                  <div key={item.code}>
                    {item.video.trim() !== '' && (
                      <div className="productoRow">
                        <video src={item.video} width="100%" height="315" controls></video>
                      </div>
                    )}
                    <div className="productoRow">
                      <Product product={item} />
                    </div>
                  </div>
                ) : (                  
                  <Product key={item.code} product={item} />
                ),
              )}
          </Box>
          {limitItems < maxItemsToShow && limitItems < items.length && (
            <Grid container direction="row" justify="center" alignItems="center" alignContent="center" marginBottom="10px" marginTop="10px">
              <Grid item xs={12}>
                <Box width="100%" mt={2} display="flex" justifyContent="center">
                  <button className='buttonMore' 
                          onClick={showMoreItems} 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}>
                            Ver Más
                </button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

export default UserItemListContainer;
